import { transformToPoints } from 'utils';
import { useGlobalSettingsQuery } from 'api';
import { useCurrentUserContext } from 'hooks';

const useDefaultPoints = () => {
  const { company, loading: currentUserLoading } = useCurrentUserContext();

  // Company referral/hire values in $
  const {
    hireValue: hirValue,
    referralValue: refValue,
    pointsConversionValue,
  } = company || {};

  const {
    data: globalSettingsData,
    loading: globalSettingsLoading,
  } = useGlobalSettingsQuery();

  // Min referral/hire values in $
  const { minimumReferralValue, minimumHireValue } =
    globalSettingsData?.globalSettings || {};

  // Company referral/hire values in points
  const defHirePoints = transformToPoints(hirValue, pointsConversionValue);
  const defReferralPoints = transformToPoints(refValue, pointsConversionValue);
  const minHirePoints = transformToPoints(
    minimumHireValue,
    pointsConversionValue
  );
  const minReferralPoints = transformToPoints(
    minimumReferralValue,
    pointsConversionValue
  );

  // loading status
  const loading = currentUserLoading || globalSettingsLoading;

  // Convert point to dollar
  const convertToDollars = (points: number) =>
    points / Number(pointsConversionValue);

  return {
    defHirePoints,
    defReferralPoints,
    minHirePoints,
    minReferralPoints,
    convertToDollars,
    loading,
    pointsConversionValue,
  };
};

export { useDefaultPoints };
