import { ReactNode } from 'react';
import { MyPointsIcon } from 'assets/icons';

import './statsItem.less';

const StatsItem = ({ children }: { children: ReactNode }) => (
  <div className="stats-item">
    <MyPointsIcon className="stats-item__icon" />
    <span className="stats-item__text">{children}</span>
  </div>
);

export default StatsItem;
