import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { lazyLoader, Routes } from 'utils';
import {
  Preloader,
  HomeRedirect,
  ErrorBoundary,
  GoogleAnalytics,
} from 'components';
import MaintenancePage from './pages/Home/MaintenancePage';

import './App.less';

const HomeRoutes = lazyLoader(() => import('pages/Home/HomeRoutes'));
const CompanyRoutes = lazyLoader(() => import('pages/Company/CompanyRoutes'));

function App() {
  const env = process.env.REACT_APP_ENV as string;
  const showGA = ['staging', 'production'].includes(env);

  return (
    <ErrorBoundary>
      {showGA && <GoogleAnalytics />}
      <div className="App">
        <Suspense fallback={<Preloader />}>
          <Switch>
            <Route
              path={Routes.MaintenancePage}
              component={MaintenancePage}
              exact
            />
            <Route path="/company" component={CompanyRoutes} exact={false} />
            {/* path / Should be the last one to not override others */}
            <Route path="/" exact component={HomeRedirect} />
            <Route path="/" component={HomeRoutes} exact={false} />
          </Switch>
        </Suspense>
      </div>
    </ErrorBoundary>
  );
}

export default App;
