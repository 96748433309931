import { useLeverOAuthUrlQuery } from 'api';
import { PrimaryButton } from 'components';

const CreateLeverIntegrationForm = () => {
  const { data, loading } = useLeverOAuthUrlQuery({
    nextFetchPolicy: 'cache-first',
  });

  const handleLoginWithLever = () => {
    const link = data?.leverOAuthUrl;
    if (!link) {
      return;
    }
    window.open(link);
  };

  return (
    <div>
      <PrimaryButton
        onClick={handleLoginWithLever}
        disabled={loading}
        loading={loading}
      >
        Login with Lever
      </PrimaryButton>
    </div>
  );
};

export default CreateLeverIntegrationForm;
