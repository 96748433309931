import React from 'react';
import { Button } from 'antd';
import classNames from 'classnames';

import './modalActions.less';

type PropsType = {
  className?: string;
  onSave?: () => void;
  onCancel?: () => void;
  saveBtnType?: 'submit' | 'button';
  cancelBtnType?: 'submit' | 'button';
  saveBtnText?: string;
  cancelBtnText?: string;
  saveBtnLoading?: boolean;
  cancelBtnLoading?: boolean;
  saveBtnDisabled?: boolean;
  cancelBtnDisabled?: boolean;
};

const ModalActions = ({
  className,
  onSave,
  onCancel,
  saveBtnType = 'submit',
  cancelBtnType = 'button',
  saveBtnText = 'Save',
  cancelBtnText = 'Cancel',
  saveBtnLoading,
  cancelBtnLoading,
  saveBtnDisabled,
  cancelBtnDisabled = saveBtnLoading,
}: PropsType) => (
  <div className={classNames('modal-actions', className)}>
    <Button
      className="modal-actions__btn"
      type="primary"
      onClick={onSave}
      htmlType={saveBtnType}
      disabled={saveBtnDisabled}
      loading={saveBtnLoading}
    >
      {saveBtnText}
    </Button>
    <Button
      className="modal-actions__btn"
      type="primary"
      onClick={onCancel}
      htmlType={cancelBtnType}
      disabled={cancelBtnDisabled}
      loading={cancelBtnLoading}
      ghost
    >
      {cancelBtnText}
    </Button>
  </div>
);

export default ModalActions;
