enum ErrorCodes {
  // Access denied error based on the user permissions
  FORBIDDEN = 'FORBIDDEN',
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  // Email already taken, user with the same email already exists
  EMAIL_TAKEN = 'EMAIL_TAKEN',
  // Occurs on attempt to sign up with email domain which isn't in a company whitelist
  UNACCEPTABLE_EMAIL_DOMAIN = 'UNACCEPTABLE_EMAIL_DOMAIN',
  // Occurs on attempt to sign up with auth service company doesn't support
  UNACCEPTABLE_AUTH_SERVICE = 'UNACCEPTABLE_AUTH_SERVICE',
  // Occurs on attempt to create a company by workspace admin if already created
  // or with the same whitelist email domains
  COMPANY_ALREADY_EXISTS = 'COMPANY_ALREADY_EXISTS',
  // Open role name should be unique per company
  OPEN_ROLE_NAME_TAKEN = 'OPEN_ROLE_NAME_TAKEN',
  // Active department name should be unique per company
  DEPARTMENT_NAME_TAKEN = 'DEPARTMENT_NAME_TAKEN',
  // Occurs on user input validation failed
  BAD_USER_INPUT = 'BAD_USER_INPUT',
  // Throws by DB for unique indexed fields
  UNIQUE_VIOLATION = 'UNIQUE_VIOLATION',
  // Entity not found
  NOT_FOUND = 'NOT_FOUND',
  // Throws for expired tokens/confirmation codes
  ENTITY_EXPIRED = 'ENTITY_EXPIRED',
  // Invalid/unsupported request
  BAD_REQUEST = 'BAD_REQUEST',
  // Fetch google profile error
  GOOGLE_PROFILE_ERROR = 'GOOGLE_PROFILE_ERROR',
  // Google captcha verification error
  GOOGLE_CAPTCHA_ERROR = 'GOOGLE_CAPTCHA_ERROR',
  // Occurs on performing action on entity in invalid/not acceptable status
  ENTITY_INVALID_STATUS = 'ENTITY_INVALID_STATUS',
  // Occurs on invalid referral status transition, ex. try to Reject Hired referral
  REFERRAL_INVALID_STATUS = 'REFERRAL_INVALID_STATUS',
  // Occurs when passed invalid currentPassword on updateUserPassword
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  // Occurs when the user trying to set new password the same as the old password
  PASSWORD_ALREADY_USED = 'PASSWORD_ALREADY_USED',
  // Occurs for operations where user authService is not acceptable
  INVALID_AUTH_SERVICE = 'INVALID_AUTH_SERVICE',
  // Invalid email or password
  INVALID_AUTH_CREDENTIALS = 'INVALID_AUTH_CREDENTIALS',
  // Invalid OTP code
  INVALID_OTP_CODE = 'INVALID_OTP_CODE',
  // Occurs On attempt to buy a prize with price more than user balance
  POINTS_NOT_ENOUGH = 'POINTS_NOT_ENOUGH',
  // Stripe errors
  STRIPE_ERROR = 'STRIPE_ERROR',
  // Stripe invalid payment method type error
  STRIPE_INVALID_PM_TYPE_ERROR = 'STRIPE_INVALID_PM_TYPE_ERROR',
  // Occurs when prepaid (gift) card used
  STRIPE_PREPAID_CARD_USAGE_ERROR = 'STRIPE_PREPAID_CARD_USAGE_ERROR',
  // Occurs on invalid roles status
  ROLE_INVALID_STATUS = 'ROLE_INVALID_STATUS',
  // Occurs when users address is missed
  USER_ADDRESS_REQUIRED = 'USER_ADDRESS_REQUIRED',
  // Occurs when using a token with type which not allowed for this operation
  INVALID_TOKEN_TYPE = 'INVALID_TOKEN_TYPE',
  // When specified giftPriceId is not in gift price list
  INVALID_GIFT_PRICE = 'INVALID_GIFT_PRICE',
  // Virtual incentives API error
  VIRTUAL_INCENTIVES_ERROR = 'VIRTUAL_INCENTIVES_ERROR',
  // Unsupported country
  UNSUPPORTED_COUNTRY = 'UNSUPPORTED_COUNTRY',
  // 2FA method hasn't been configured yet to perform an action
  TWO_FACTOR_METHOD_NOT_CONFIGURED = 'TWO_FACTOR_METHOD_NOT_CONFIGURED',
  // Prevent to remove the very last 2FA method
  REMOVE_LAST_2FA_METHOD = 'REMOVE_LAST_2FA_METHOD',
  // Inactive department
  INACTIVE_DEPARTMENT = 'INACTIVE_DEPARTMENT',
  // Occurs when deactivating department to which roles assigned
  DEACTIVATE_DEPARTMENT_WITH_ROLES = 'DEACTIVATE_DEPARTMENT_WITH_ROLES',
  // CI webhook not found
  CI_WEBHOOK_NOT_FOUND = 'CI_WEBHOOK_NOT_FOUND',
  // CI webhook invalid signature
  CI_WEBHOOK_INVALID_SIGNATURE = 'CI_WEBHOOK_INVALID_SIGNATURE',
  // ATS error
  ATS_ERROR = 'ATS_ERROR',
}

const StripeErrorCodes = {
  customer_tax_location_invalid: 'customer_tax_location_invalid',
};

enum AtsErrorCodes {
  UNAUTHORIZED_ERROR = 'UNAUTHORIZED_ERROR',
  FORBIDDEN = 'FORBIDDEN',
  NOT_FOUND = 'NOT_FOUND',
  REFERRAL_NOT_FOUND = 'REFERRAL_NOT_FOUND',
  ROLE_NOT_FOUND = 'ROLE_NOT_FOUND',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY',
  REOPEN_FILLED_JOB = 'REOPEN_FILLED_JOB',
}

export { ErrorCodes, AtsErrorCodes, StripeErrorCodes };
