import { Link } from 'react-router-dom';
import { Form, Popover, Switch } from 'antd';
import { useUpdateCompanyMutation } from 'api';
import { useCurrentUserContext, useForm } from 'hooks';
import { FormItem } from 'components';
import {
  Routes,
  showErrorNotification,
  showSuccessNotification,
  SuccessMessages,
} from 'utils';

import './company2FAForm.less';

const Company2FAForm = () => {
  const [form] = useForm();
  const { company, user } = useCurrentUserContext();

  const [
    updateCompany,
    { loading: updateCompanyLoading },
  ] = useUpdateCompanyMutation({
    onCompleted() {
      showSuccessNotification({ content: SuccessMessages.ChangesSaved });
    },
    onError() {
      showErrorNotification();
    },
  });
  const contentFor2FAPopover = (
    <p>
      Please enable two-factor authentication for your{' '}
      <Link to={Routes.ProfileSettingsTwoFactorAuth}>account</Link> before
      enabling it for the entire company.
    </p>
  );

  const handleUpdateCompany = (is2FARequired: boolean) =>
    updateCompany({
      variables: {
        input: {
          is2FARequired,
        },
      },
    });

  return (
    <Form
      className="company-2fa-form"
      form={form}
      onFinish={handleUpdateCompany}
      initialValues={{ is2FARequired: company?.is2FARequired }}
    >
      {/* Toggle 2FA */}
      <div>
        <div>Require two-factor authentication for my company</div>
        <FormItem name="is2FARequired" valuePropName="checked" validateFirst>
          {/* Alert 2FA isActive */}
          {!!user && !user.is2FAEnabled ? (
            <Popover content={contentFor2FAPopover} trigger="hover">
              <Switch disabled />
            </Popover>
          ) : (
            <Switch
              disabled={updateCompanyLoading || !user?.is2FAEnabled}
              onChange={handleUpdateCompany}
              loading={updateCompanyLoading}
            />
          )}
        </FormItem>
      </div>
    </Form>
  );
};

export default Company2FAForm;
