import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(enLocale);
const countriesCodeMap = countries.getNames('en');

export const CountryCode: any = Object.keys(countriesCodeMap).reduce(
  (acc, code) => ({ ...acc, [code]: code }),
  {}
);

type CountryStates = {
  value: string;
  states: string[];
};

const supportedCountries = new Set([CountryCode.US]);

const countryOptions: { value: string; label: string }[] = [];
const supportedCountryOptions: { value: string; label: string }[] = [];

Object.entries(countriesCodeMap).forEach(([countryCode, countryName]) => {
  let label = countryName;
  if (countryCode === CountryCode.US) {
    label = 'United States';
  }

  countryOptions.push({ value: countryCode, label });
  if (supportedCountries.has(countryCode)) {
    supportedCountryOptions.push({ value: countryCode, label });
  }
});

const countriesAndStates: CountryStates[] = [
  {
    value: CountryCode.US,
    states: [
      'Alabama',
      'Alaska',
      'American Samoa',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Federated States of Micronesia',
      'Florida',
      'Georgia',
      'Guam',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Marshall Islands',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Northern Mariana Islands',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Palau',
      'Pennsylvania',
      'Puerto Rico',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
      'Virgin Islands',
    ],
  },
  {
    value: CountryCode.CA,
    states: [
      'Alberta',
      'British Columbia',
      'Manitoba',
      'New Brunswick',
      'Newfoundland',
      'Northwest Territories',
      'Nova Scotia',
      'Nunavut',
      'Ontario',
      'Prince Edward Island',
      'Quebec',
      'Saskatchewan',
      'Yukon',
    ],
  },
];

const stateOptionsMap = countriesAndStates.reduce(
  (acc, item) => ({
    ...acc,
    [item.value]: item.states.map((s) => ({ value: s })),
  }),
  {}
);

const getCountryName = (countryCode?: string | null | undefined) => {
  if (!countryCode) {
    return null;
  }
  if (countryCode.toLowerCase() === 'us') {
    return 'United States';
  }

  return countries.getName(countryCode, 'en');
};

export {
  countriesAndStates,
  countryOptions,
  supportedCountryOptions,
  stateOptionsMap,
  supportedCountries,
  getCountryName,
};
