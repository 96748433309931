import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import { UserRole } from 'api';
import { Routes } from 'utils';
import { useCurrentUserContext } from 'hooks';
import {
  RolesIcon,
  UsersIcon,
  CampaignsIcon,
  BillingIcon,
  ReportIcon,
  MyPointsIcon,
  OpenRolesIcon,
  DashboardIcon,
  MyActivityIcon,
  MyReferralsIcon,
  CompanySettingsIcon,
} from 'assets/icons';

import './companyNav.less';

type PropsType = {
  className?: string;
  onClick?: () => void;
};

const userMenuGroups = [
  {
    key: 'g1',
    menuItems: [
      { title: 'Dashboard', route: Routes.Dashboard, icon: DashboardIcon },
    ],
  },
  {
    key: 'g2',
    title: 'company',
    menuItems: [
      {
        title: 'Open Roles',
        route: Routes.CompanyOpenRoles,
        icon: OpenRolesIcon,
      },
    ],
  },
  {
    key: 'g3',
    title: 'you',
    menuItems: [
      { title: 'Redeem Points', route: Routes.MyPoints, icon: MyPointsIcon },
      {
        title: 'My Referrals',
        route: Routes.MyReferrals,
        icon: MyReferralsIcon,
      },
      {
        title: 'My Activity',
        route: Routes.MyActivity,
        icon: MyActivityIcon,
      },
    ],
  },
];

const hrMenuGroups = [
  {
    key: 'g1',
    menuItems: [
      { title: 'Dashboard', route: Routes.Dashboard, icon: DashboardIcon },
    ],
  },
  {
    key: 'g2',
    title: 'company',
    menuItems: [
      { title: 'Roles', route: Routes.CompanyRoles, icon: RolesIcon },
      { title: 'Gifts', route: Routes.CompanyGifts, icon: MyPointsIcon },
      {
        title: 'Referrals',
        route: Routes.CompanyReferrals,
        icon: MyReferralsIcon,
      },
    ],
  },
];

const adminMenuGroups = [
  {
    key: 'g1',
    title: '',
    menuItems: [
      {
        title: 'Dashboard',
        icon: DashboardIcon,
        route: Routes.Dashboard,
      },
    ],
  },
  {
    key: 'g2',
    title: 'Company',
    menuItems: [
      { title: 'Gifts', route: Routes.CompanyGifts, icon: MyPointsIcon },
      {
        title: 'Referrals',
        route: Routes.CompanyReferrals,
        icon: MyReferralsIcon,
      },
    ],
  },
  {
    key: 'g3',
    title: 'Administration',
    menuItems: [
      { title: 'Roles', route: Routes.CompanyRoles, icon: RolesIcon },
      { title: 'Users', route: Routes.CompanyUsers, icon: UsersIcon },
      {
        title: 'Campaigns',
        route: Routes.CompanyCampaigns,
        icon: CampaignsIcon,
      },
      {
        title: 'Settings',
        route: Routes.CompanySettings,
        icon: CompanySettingsIcon,
      },
      { title: 'Billing', route: Routes.CompanyBilling, icon: BillingIcon },
      {
        title: 'Reports',
        route: '/company/admin/reports',
        icon: ReportIcon,
        menuItems: [
          {
            title: 'Tax Report',
            route: Routes.CompanyTaxReport,
          },
        ],
      },
    ],
  },
];

const menu = {
  [UserRole.WorkspaceAdmin]: adminMenuGroups,
  [UserRole.User]: userMenuGroups,
  [UserRole.Hr]: hrMenuGroups,
  [UserRole.SuperAdmin]: [],
};

const CompanyNav = ({ className, onClick }: PropsType) => {
  const { user } = useCurrentUserContext();
  const userRole = user?.role;
  const location = useLocation();

  const navItems: any = userRole ? menu[userRole] : [];

  return (
    <Menu
      defaultSelectedKeys={[location.pathname]}
      mode="inline"
      className={classNames('company-nav', className)}
    >
      {navItems.map((menuGroup: any) => (
        <Menu.ItemGroup
          key={menuGroup.key}
          className="company-nav__group-item"
          title={menuGroup.title}
        >
          {menuGroup.menuItems.map((menuItem: any) => {
            if (menuItem.menuItems) {
              return (
                <Menu.SubMenu
                  key={menuItem.route}
                  className="company-nav__item"
                  icon={
                    menuItem.icon && (
                      <menuItem.icon className="company-nav__icon" />
                    )
                  }
                  title={
                    <span className="company-nav__title">{menuItem.title}</span>
                  }
                >
                  {menuItem.menuItems.map((innerMenuItem: any) => (
                    <Menu.Item
                      key={innerMenuItem.route}
                      className="company-nav__sub-item"
                      icon={
                        innerMenuItem.icon && (
                          <innerMenuItem.icon className="company-nav__icon" />
                        )
                      }
                      onClick={onClick}
                    >
                      <Link
                        className="company-nav__title"
                        to={innerMenuItem.route as string}
                      >
                        {innerMenuItem.title}
                      </Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            }

            return (
              <Menu.Item
                key={menuItem.route}
                className="company-nav__item"
                icon={
                  menuItem.icon && (
                    <menuItem.icon className="company-nav__icon" />
                  )
                }
                onClick={onClick}
              >
                <Link
                  className="company-nav__title"
                  to={menuItem.route as string}
                >
                  {menuItem.title}
                </Link>
              </Menu.Item>
            );
          })}
        </Menu.ItemGroup>
      ))}
    </Menu>
  );
};

export default CompanyNav;
