import { GraphQLError } from 'graphql';
import { isBadUserInputError } from 'utils';

type ValidationError = {
  children: ValidationError[];
  property: string;
  constraints: Record<string, string>;
};

/**
 * Get array of BAD_USER_INPUT validation error
 */
const getValidationErrors = (error: GraphQLError): ValidationError[] => {
  if (!isBadUserInputError(error)) {
    return [];
  }
  return error.extensions?.invalidArgs || [];
};

/**
 * Find ValidationError for property
 */
const findValidationError = (
  property: string,
  validationErrors: ValidationError[]
): ValidationError | undefined =>
  validationErrors.find((ve) => ve?.property === property);

/**
 * Get constrains object for specified property
 */
const getErrorPropConstrains = (
  property: string,
  error: GraphQLError
): Record<string, string> => {
  const validationErrors = getValidationErrors(error);
  const validationError = findValidationError(property, validationErrors);
  return validationError?.constraints || {};
};

/**
 * Check is specified property has constrain
 */
const hasErrorPropConstrain = (
  constrainKey: string,
  property: string,
  error: GraphQLError
): boolean => {
  const constrains = getErrorPropConstrains(property, error);
  return !!constrains[constrainKey];
};

const findBadUserInputProp = (error: GraphQLError, propName: string) =>
  error.extensions?.invalidArgs.find(
    (arg: ValidationError) => arg.property === propName
  );

export {
  getValidationErrors,
  getErrorPropConstrains,
  hasErrorPropConstrain,
  findBadUserInputProp,
};
export type { ValidationError };
