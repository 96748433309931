/* eslint-disable */
import { Typography } from 'antd';
import classNames from 'classnames';
import { CopyIcon } from 'assets/icons';
import { useCurrentUserContext } from 'hooks';

import './shareLink.less';

const { Paragraph } = Typography;

type PropsType = {
  className?: string;
};

const ShareLink = ({ className }: PropsType) => {
  const { company } = useCurrentUserContext();

  const invitationCode = company?.invitationCode;

  const { origin } = window.location;

  return (
    <Paragraph
      className={classNames('link-container', className)}
      copyable={{
        icon: [
          <CopyIcon className="link-container__copy" />,
          <CopyIcon className="link-container__copy--copied" />,
        ],
        text: `${origin}/joinus/${invitationCode}`,
      }}
    >
      <div className="link-container__code">
        {`${origin.toUpperCase()}/JOINUS/${invitationCode}`}
      </div>
    </Paragraph>
  );
};

export default ShareLink;
