import InfiniteScroll from 'react-infinite-scroll-component';
import { useState } from 'react';
import { Spin } from 'antd';
import { defaultDateFormat, transformNumber, UserActivity } from 'utils';
import { ActivityType, useCompanyUsersActivitiesQuery } from 'api';

import './userActivitiesList.less';

type PropTypes = {
  userId: string | number;
  pointsBalance: number;
};

const ActivityDescription = ({ activity }: { activity: UserActivity }) => {
  let roleName;
  let candidateName;
  if ('referral' in activity) {
    roleName = activity.referral?.role?.name;
  }
  if ('referral' in activity) {
    candidateName = activity.referral?.candidateName;
  }

  if (activity.type === ActivityType.ReferralAccepted) {
    return (
      <span>
        <span className="text__gray-2">Referral reward for </span>
        {roleName}
        <span> - </span>
        {candidateName}
      </span>
    );
  }

  if (activity.type === ActivityType.ReferralAcceptedNotHired) {
    return (
      <span>
        <span className="text__gray-2">Referral accepted - not hired for </span>
        {roleName}
        <span> - </span>
        {candidateName}
      </span>
    );
  }

  if (activity.type === ActivityType.ReferralHired) {
    return (
      <span>
        <span className="text__gray-2">Hire reward for </span>
        {roleName}
        <span> - </span>
        {candidateName}
      </span>
    );
  }

  if (activity.type === ActivityType.ReferralHirePending) {
    return (
      <span>
        <span className="text__gray-2">Referral hire pending for </span>
        {roleName}
        <span> - </span>
        {candidateName}
      </span>
    );
  }

  if (activity.type === ActivityType.ReferralHireRejected) {
    return (
      <span>
        <span className="text__gray-2">Referral hire rejected for </span>
        {roleName}
        <span> - </span>
        {candidateName}
      </span>
    );
  }

  if (activity.type === ActivityType.ReferralRejected) {
    return (
      <span>
        <span className="text__gray-2">Referral rejected for </span>
        {roleName}
        <span> - </span>
        {candidateName}
      </span>
    );
  }

  if (activity.type === ActivityType.GiftRedemption) {
    return (
      <span>
        {'pointsRedeemed' in activity && activity.pointsRedeemed?.gift.name}
        <span className="text__gray-2"> gift card redemption</span>
      </span>
    );
  }

  if (activity.type === ActivityType.GiveRewardPoints) {
    return <span className="text__gray-2">User has been rewarded</span>;
  }

  return null;
};

const PointsAmount = ({ activity }: { activity: UserActivity }) => {
  // Give reward points activity
  if (
    'pointsRewarded' in activity &&
    activity.pointsRewarded &&
    activity.type === ActivityType.GiveRewardPoints
  ) {
    return (
      <span>
        {' - '}
        <span className="text__primary text__bold">
          {transformNumber(activity.pointsRewarded.value)}
        </span>{' '}
        points
        <span className="text__gray-2"> by </span> {`${activity.actor.name}`}
      </span>
    );
  }

  // Points rewarded amount
  if ('pointsRewarded' in activity && activity.pointsRewarded) {
    return (
      <span>
        {' - '}
        <span className="text__primary text__bold">
          {transformNumber(activity.pointsRewarded.value)}
        </span>
        <span> points</span>
      </span>
    );
  }

  // Points redeemed amount
  if ('pointsRedeemed' in activity && activity.pointsRedeemed) {
    return (
      <span>
        {' - '}
        <span className="text__error text__bold">
          {transformNumber(activity.pointsRedeemed.value)}
        </span>{' '}
        points
      </span>
    );
  }

  // Empty amount for consistent return
  return null;
};

const UserActivitiesList = ({ userId, pointsBalance = 0 }: PropTypes) => {
  const [pageSize, setPageSize] = useState(10);

  const { loading, data, previousData } = useCompanyUsersActivitiesQuery({
    variables: {
      filter: {
        page: 1,
        pageSize,
        usersIds: [userId as string],
      },
    },
  });

  const count = data?.companyUsersActivities?.count || 0;
  // @ts-ignore
  const userActivities: UserActivity[] =
    (loading
      ? previousData?.companyUsersActivities?.items
      : data?.companyUsersActivities?.items) || [];

  return (
    <div className="user-activities" id="scrollableDiv">
      <div className="user-activities__title">
        Activity
        <br />
        Points balance:{' '}
        <span className="text__primary text__bold">
          {transformNumber(pointsBalance)}
        </span>
      </div>
      <InfiniteScroll
        dataLength={userActivities.length}
        hasMore={count > userActivities.length}
        scrollableTarget="scrollableDiv"
        loader={null}
        next={() => {
          setPageSize((currentPageSize) => currentPageSize + 10);
        }}
      >
        {!loading && !userActivities.length && (
          <span>No activity has been recorded for this user</span>
        )}
        {userActivities.map((activity) => (
          <div key={activity.id} className="user-activities__item">
            <span>{defaultDateFormat(activity.createdAt)}</span>{' '}
            <ActivityDescription activity={activity} />{' '}
            <PointsAmount activity={activity} />
          </div>
        ))}
        {loading && (
          <div className="spin-wrapper">
            <Spin size="small" />
          </div>
        )}
      </InfiniteScroll>
    </div>
  );
};

export default UserActivitiesList;
