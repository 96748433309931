// Activity type names
enum ActivityTypeNames {
  REFERRAL_ACCEPTED = 'Referral reward',
  REFERRAL_HIRED = 'Hire Reward',
  GIFT_REDEMPTION = 'Gift Redemption',
  GIVE_REWARD_POINTS = 'Points reward',
}

enum ReferralStatusHistoryNames {
  REFERRAL_ACCEPTED = 'Referral accepted (rewarded)',
  REFERRAL_REJECTED = 'Referral rejected (no reward)',
  REFERRAL_ACCEPTED_NOT_HIRED = 'Referral accepted - not hired',
  REFERRAL_HIRED = 'Candidate hired (rewarded)',
  REFERRAL_HIRE_PENDING = 'Candidate hired (pending)',
  REFERRAL_HIRE_REJECTED = 'Not eligible for hire reward',
  GIFT_REDEMPTION = '',
  REFERRAL_EARLY_HIRED = 'Candidate hired (rewarded)',
  GIVE_REWARD_POINTS = '',
}

export { ActivityTypeNames, ReferralStatusHistoryNames };
