import { Redirect } from 'react-router-dom';
import { useCurrentUserContext } from 'hooks';
import { Routes } from 'utils';
import { Preloader } from '../../index';

const HomeRedirect = () => {
  const { user, loading, error } = useCurrentUserContext();
  if (loading) {
    return <Preloader show />;
  }

  // Redirect authenticated users to the dashboard page
  let redirectTo: Routes = Routes.Dashboard;

  // Redirect not authenticated users to sign in page
  if (!user || error) {
    redirectTo = Routes.SignIn;
  }

  return <Redirect to={redirectTo} />;
};

export default HomeRedirect;
