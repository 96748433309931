import classNames from 'classnames';

import './editButton.less';

type PropsType = {
  onClick?: () => void;
  children?: any;
  className?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
};

const EditButton = ({
  onClick,
  className,
  htmlType = 'button',
  children = 'Edit',
  disabled = false,
}: PropsType) => (
  <button
    className={classNames('edit-button', className)}
    onClick={onClick}
    type={htmlType}
    disabled={disabled}
  >
    {children}
  </button>
);

export default EditButton;
