import { ApolloCache } from '@apollo/client';
import { UserFragment, CurrentUserDocument } from 'api';
import { setTokensPair } from '../tokensPair';

const updateCurrentUserCacheAndTokens = (
  cache: ApolloCache<any>,
  authResponse: {
    accessToken: string;
    refreshToken: string;
    user: UserFragment | undefined | null;
  } = { accessToken: '', refreshToken: '', user: undefined }
) => {
  // Update token pairs before updating user cache
  // to prevent refetch currentUser by old tokens pair
  setTokensPair({
    accessToken: authResponse.accessToken,
    refreshToken: authResponse.refreshToken,
  });

  cache.writeQuery({
    query: CurrentUserDocument,
    data: { currentUser: authResponse.user },
  });
};

export { updateCurrentUserCacheAndTokens };
