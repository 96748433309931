const transformUrl = (url?: string | null) => {
  if (!url) {
    return url;
  }

  const httpsRegExp = /^(https:\/\/).*/i;

  if (httpsRegExp.test(url)) {
    return url;
  }

  return `https://${url}`;
};

export { transformUrl };
