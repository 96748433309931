import classnames from 'classnames';

import './pairFormGroup.less';

type Props = {
  className?: string;
  children?: any;
};

const PairFormGroup = ({ children, className }: Props) => (
  <div className={classnames(className, 'pair-form-group')}>{children}</div>
);

export default PairFormGroup;
