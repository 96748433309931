import { message } from 'antd';
import { Optional } from 'utility-types';
import { ArgsProps } from 'antd/lib/message';
import { ErrorMessages } from 'utils/constants';

type NotificationArgs = Optional<ArgsProps, 'type' | 'duration'>;

const showNotification = ({
  type,
  ...rest
}: Optional<ArgsProps, 'duration'>) => {
  message[type]({
    duration: 5,
    ...rest,
  });
};

const showErrorNotification = (args?: NotificationArgs) =>
  showNotification(
    args
      ? { ...args, type: 'error' }
      : { type: 'error', content: ErrorMessages.DefaultErrorMessage }
  );

const showSuccessNotification = (args: NotificationArgs) =>
  showNotification({ ...args, type: 'success' });

const showInfoNotification = (args: NotificationArgs) =>
  showNotification({ ...args, type: 'info' });

const showWarningNotification = (args: NotificationArgs) =>
  showNotification({ ...args, type: 'warning' });

export {
  showNotification,
  showInfoNotification,
  showErrorNotification,
  showSuccessNotification,
  showWarningNotification,
};
