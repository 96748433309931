import React from 'react';
import { Editor, EditorProps } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const PromotionMessageEditor = (props: EditorProps) => (
  <Editor
    {...props}
    readOnly
    toolbarHidden
    toolbar={{
      options: ['inline', 'list', 'textAlign', 'link'],
      inline: { options: ['bold', 'italic', 'underline'] },
      list: { options: ['unordered', 'ordered'] },
      textAlign: { options: ['left', 'center', 'right'] },
    }}
  />
);

export default PromotionMessageEditor;
