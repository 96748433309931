import { getAccessToken, TokensPair } from 'utils';

const RefreshUserTokenPairMutation = `
  mutation RefreshUserTokenPair($refreshToken: String!) {
    refreshUserTokenPair(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

const refreshTokenPairRawRequest = async (
  uri: string,
  refreshToken: string
): Promise<TokensPair> => {
  if (!refreshToken) {
    return Promise.reject(new Error('Refresh token not found'));
  }

  const res = await fetch(uri, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: getAccessToken(),
    },
    body: JSON.stringify({
      query: RefreshUserTokenPairMutation,
      variables: { refreshToken },
      operationName: 'RefreshUserTokenPair',
    }),
  });

  const { data, errors } = await res.json();
  if (errors?.length) {
    return Promise.reject(errors[0]);
  }

  return data.refreshUserTokenPair;
};

export { refreshTokenPairRawRequest };
