import { Link } from 'react-router-dom';
import { useCallback, useState } from 'react';
import {
  UserPoints,
  UserCompany,
  UserInfoLeft,
  CompanyNavDrawer,
} from 'components';
import { Routes } from 'utils';
import { Logo } from 'assets/images';
import { useBreakpoint } from 'hooks';
import { HamburgerIcon } from 'assets/icons';

import './companyHeader.less';

const CompanyHeader = () => {
  // Company navigation drawer open state
  const [isOpen, setIsOpen] = useState(false);

  const { lg } = useBreakpoint();

  // Toggle company navigation drawer open
  const toggleOpen = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  return (
    <div className="company-header">
      {/* Logo */}
      <div className="company-header__logo">
        <Link className="company-header__logo-link" to={Routes.Dashboard}>
          <Logo />
        </Link>
      </div>

      {lg ? (
        <>
          {/* User company */}
          <UserCompany />

          {/* User information */}
          <div className="company-header__wrap">
            {/* User points amount */}
            <UserPoints />

            {/* User name/avatar */}
            <UserInfoLeft />
          </div>
        </>
      ) : (
        <>
          {/* User points amount */}
          <UserPoints className="company-header__points" />

          {/* Mobile and tablet hamburger button */}
          <button
            className="company-header__hamburger"
            type="button"
            onClick={toggleOpen}
          >
            <HamburgerIcon />
          </button>
        </>
      )}

      {/* Mobile and tablet navigation drawer */}
      <CompanyNavDrawer isOpen={isOpen} toggleOpen={toggleOpen} />
    </div>
  );
};

export default CompanyHeader;
