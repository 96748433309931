import React from 'react';
import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

const SelectWrapper = (props: SelectProps<any>) => (
  <Select
    virtual={false}
    getPopupContainer={(trigger) => trigger.parentNode as HTMLElement}
    size="large"
    {...props}
  />
);

export default SelectWrapper;
