import { Empty } from 'antd';

import './emptyCode.less';

type PropsType = {
  title: string;
  text: string;
};

const EmptyCode = ({ title, text }: PropsType) => (
  <Empty
    className="invitation-code-not-found"
    description={
      <>
        <span className="invitation-code-not-found__header">{title}</span>
        <br />
        <span>{text}</span>
      </>
    }
  />
);

export default EmptyCode;
