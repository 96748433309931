import queryString from 'query-string';
import { useState, useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const usePagination = (initialPageSize = 10) => {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const [pageSize, setPageSize] = useState(initialPageSize);
  const [currentPage, setCurrentPage] = useState(1);

  // Change page number when pagination change
  const changePage = useCallback(
    (page: number) => {
      const queryParams = queryString.parse(search);
      const newParams = { ...queryParams, page };

      setCurrentPage(page);

      return history.push(`${pathname}?${queryString.stringify(newParams)}`);
    },

    [history, pathname, search]
  );

  // Reset pagination page
  const resetPagination = () => {
    setCurrentPage(1);
    const { page, ...queryParams } = queryString.parse(search);
    return history.push(`${pathname}?${queryString.stringify(queryParams)}`);
  };

  // Change page size
  const changePageSize = (size: number) => {
    resetPagination();
    setPageSize(size);
  };

  // If we have page params in the url set pagination to that page
  useEffect(() => {
    const { page } = queryString.parse(search);

    if (page) {
      changePage(+page);
    }
  }, [changePage, search]);

  return { currentPage, pageSize, changePage, changePageSize, resetPagination };
};

export { usePagination };
