import Blocked from './Blocked';

const UserBlocked = () => (
  <Blocked
    title={
      <>
        <div>This account is inactive.</div>
        <div>
          Please reach out to your admin or contact{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="mailto: support@hireaction.com"
          >
            support@hireaction.com
          </a>
        </div>
      </>
    }
  />
);

export default UserBlocked;
