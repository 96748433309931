import React from 'react';

export const componentLoader = (
  lazyComponent: any,
  attemptsLeft: number
): any =>
  new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error: Error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }

          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, 300);
      });
  });

export const lazyLoader = (importFunc: any, retries = 3) =>
  React.lazy(() => componentLoader(importFunc, retries));
