import { useHistory } from 'react-router-dom';
import { client } from 'api';
import { Routes, setTokensPair } from 'utils';

const useLogout = () => {
  const history = useHistory();

  const logout = () => {
    history.push(Routes.SignIn);
    setTokensPair({ accessToken: '', refreshToken: '' });
    return client.clearStore();
  };

  return logout;
};

export { useLogout };
