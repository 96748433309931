import { Drawer } from 'antd';
import { useEffect } from 'react';
import { useBreakpoint } from 'hooks';
import { CloseIcon } from 'assets/icons';
import { UserInfoRight, UserCompany, CompanyNav } from 'components';

import './companyNavDrawer.less';

type PropsType = {
  isOpen?: boolean;
  toggleOpen: () => void;
};

const CompanyNavDrawer = ({ toggleOpen, isOpen }: PropsType) => {
  const { lg, md } = useBreakpoint();

  // Close side navigation if open and screen lg
  useEffect(() => {
    if (lg && isOpen) {
      toggleOpen();
    }
  }, [isOpen, lg, toggleOpen]);

  return (
    <Drawer
      className="company-nav-drawer"
      title={
        <>
          <UserInfoRight onClick={toggleOpen} />
          <UserCompany className="company-nav-drawer__company" />
        </>
      }
      closeIcon={<CloseIcon />}
      visible={isOpen}
      onClose={toggleOpen}
      width={md ? '50%' : '100%'}
      destroyOnClose
    >
      <CompanyNav
        className="company-nav-drawer__navigation"
        onClick={toggleOpen}
      />
    </Drawer>
  );
};

export default CompanyNavDrawer;
