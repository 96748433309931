import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { UserRole } from 'api';
import { Routes, transformNumber } from 'utils';
import { useCurrentUserContext, useUserPointsContext } from 'hooks';

import './userPoints.less';

type PropsType = {
  className?: string;
  onClick?: () => void;
};

const Points = ({ className, onClick }: PropsType) => {
  const { user } = useCurrentUserContext();
  const { userPoints } = useUserPointsContext();

  const userRole = user?.role;

  return userRole && userRole === UserRole.User ? (
    <Link
      className={classNames('user-points', className)}
      to={Routes.MyPoints}
      onClick={onClick}
    >
      <span className="user-points__text">My points:</span>
      <span className="user-points__amount">{transformNumber(userPoints)}</span>
    </Link>
  ) : null;
};

export default Points;
