import { Form, FormInstance, InputNumber } from 'antd';
import { ErrorMessages } from 'utils';
import { useGlobalSettingsQuery } from 'api';
import { FormItem, PrimaryButton } from 'components';

import './companySettingsForm.less';

type PropsType = {
  loading?: boolean;
  form: FormInstance;
  submitButtonText: string;
  initialValues: {
    hireValue?: number;
    referralValue?: number;
    hireRewardWaitPeriod?: number;
  };
  onSubmit: ({
    hireValue,
    referralValue,
    hireRewardWaitPeriod,
  }: {
    hireValue: number;
    referralValue: number;
    hireRewardWaitPeriod: number;
  }) => void;
};

const CompanySettingsForm = ({
  form,
  loading,
  initialValues,
  submitButtonText,
  onSubmit,
}: PropsType) => {
  const { data: globalSettingsData } = useGlobalSettingsQuery();

  const {
    defaultReferralValue,
    defaultHireValue,
    minimumReferralValue,
    minimumHireValue,
  } = globalSettingsData?.globalSettings || {};

  const formatter = (value?: string | number) =>
    `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const parser = (value: any) => value.replace(/\$\s?|(,*)/g, '');

  return (
    <Form
      className="company-settings-form"
      form={form}
      onFinish={onSubmit}
      initialValues={initialValues}
    >
      <div className="company-settings-form__wrapper">
        {/* Form items */}
        <div className="company-settings-form__form-items">
          {/* Referral reward form item */}
          <div className="company-settings-form__form-item">
            <div className="company-settings-form__label">
              How much do you want to pay out for every qualified referral?
            </div>
            <FormItem
              name="referralValue"
              validateFirst
              rules={[
                {
                  type: 'number',
                  required: true,
                  min: minimumReferralValue,
                  max: 9999999999,
                  message: ErrorMessages.InvalidAmount,
                },
              ]}
            >
              <InputNumber
                className="company-settings-form__input"
                precision={0}
                formatter={formatter}
                parser={parser}
                size="large"
              />
            </FormItem>
            <div className="company-settings-form__hint">
              (Recommended at least {formatter(defaultReferralValue)}, minimum{' '}
              {formatter(minimumReferralValue)})
            </div>
          </div>

          {/* Hire reward form item */}
          <div className="company-settings-form__form-item">
            <div className="company-settings-form__label">
              How much do you want to pay out for every successful hire?
            </div>
            <FormItem
              name="hireValue"
              validateFirst
              rules={[
                {
                  type: 'number',
                  required: true,
                  min: minimumHireValue,
                  max: 9999999999,
                  message: ErrorMessages.InvalidAmount,
                },
              ]}
            >
              <InputNumber
                className="company-settings-form__input"
                precision={0}
                formatter={formatter}
                parser={parser}
                size="large"
              />
            </FormItem>
            <div className="company-settings-form__hint">
              (Recommended {formatter(defaultHireValue)}, minimum{' '}
              {formatter(minimumHireValue)})
            </div>
          </div>

          {/* Hire pending days form item */}
          <div className="company-settings-form__form-item">
            <div className="company-settings-form__label">
              How many days do you want to wait before you pay out for every
              successful hire?
            </div>
            <FormItem
              name="hireRewardWaitPeriod"
              validateFirst
              rules={[
                {
                  required: true,
                  message: ErrorMessages.EmptyRewardWaitPeriod,
                },
                {
                  type: 'number',
                  min: 0,
                  message: ErrorMessages.MinRewardWaitPeriod,
                },
                {
                  type: 'number',
                  max: 365,
                  message: ErrorMessages.MaxRewardWaitPeriod,
                },
              ]}
            >
              <InputNumber
                className="company-settings-form__input"
                precision={0}
                size="large"
              />
            </FormItem>
          </div>
        </div>

        {/* Information */}
        <div className="company-settings-form__info">
          <div className="company-settings-form__text">
            Based on a{' '}
            <FormItem
              className="company-settings-form__percentage"
              initialValue={10}
              name="percentage"
              help={false}
              rules={[
                {
                  type: 'number',
                  min: 1,
                  max: 100,
                },
              ]}
            >
              <InputNumber
                className="company-settings-form__percentage-input"
                precision={0}
                size="small"
              />
            </FormItem>
            % hire rate for your referrals, your effective gross payout for a
            successful hire, is:{' '}
            <FormItem shouldUpdate noStyle>
              {({ getFieldValue, getFieldsError }) => {
                const referralValue = getFieldValue('referralValue');
                const hireValue = getFieldValue('hireValue');
                const percentage = getFieldValue('percentage');

                if (
                  !getFieldsError([
                    'referralValue',
                    'hireValue',
                    'percentage',
                  ]).filter(({ errors }) => errors.length).length &&
                  percentage
                ) {
                  const payoutForSuccessfulHire = Math.round(
                    referralValue / (percentage / 100) + hireValue
                  );

                  return formatter(payoutForSuccessfulHire);
                }
                return '$ 0';
              }}
            </FormItem>
          </div>

          <div className="company-settings-form__text">
            * 10% is the average rate we’ve seen from other companies - but
            please update to match your own experiences.
          </div>

          <div className="company-settings-form__text">
            Your end users will receive their rewards in the form of points
            which they can then redeem for gifts.
          </div>
        </div>
      </div>

      {/* Submit button */}
      <FormItem shouldUpdate noStyle>
        {() => (
          <PrimaryButton
            htmlType="submit"
            disabled={
              !!form
                .getFieldsError([
                  'referralValue',
                  'hireValue',
                  'hireRewardWaitPeriod',
                ])
                .filter(({ errors }) => errors.length).length
            }
            loading={loading}
          >
            {submitButtonText}
          </PrimaryButton>
        )}
      </FormItem>
    </Form>
  );
};

export default CompanySettingsForm;
