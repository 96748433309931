import { Result, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import { Routes } from 'utils';

import './notFound.less';

const NotFound = () => {
  const history = useHistory();

  const handleBack = () => history.push(Routes.Dashboard);

  return (
    <div className="not-found">
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button onClick={handleBack} type="primary">
            Back
          </Button>
        }
      />
    </div>
  );
};

export default NotFound;
