import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { FormItem, ModalActions, SelectAutocomplete } from 'components/index';
import {
  useCompanyUsersQuery,
  useCreateLeverCompanyIntegrationMutation,
  UserCompanyRole,
} from 'api';
import {
  SuccessMessages,
  ErrorMessages,
  showSuccessNotification,
  showErrorNotification,
  uniqSelectOptions,
} from 'utils';
import { useAutocomplete, useCurrentUserContext, useForm } from 'hooks';

type PropTypes = {
  onComplete?: () => void;
  onCancel?: () => void;
};
type FormTypes = {
  responsibleUserId?: string;
};
const LeverOAuthCallback = ({ onComplete, onCancel }: PropTypes) => {
  const [form] = useForm<FormTypes>();
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const authCode = searchParams.get('code') || '';
  const authState = searchParams.get('state') || '';
  const error = searchParams.get('error') || '';

  const [
    upsertLeverCompanyIntegration,
    { loading },
  ] = useCreateLeverCompanyIntegrationMutation({
    onCompleted() {
      showSuccessNotification({
        content: SuccessMessages.CompanyIntegrationConfigured,
      });
      if (onComplete) {
        onComplete();
      }
    },
    onError({ graphQLErrors }) {
      graphQLErrors.forEach(() =>
        showErrorNotification({
          content: ErrorMessages.OAuthCallbackError,
        })
      );
      if (onCancel) {
        setTimeout(onCancel, 500);
      }
    },
  });

  const { user } = useCurrentUserContext();

  // Get autocomplete info and methods
  const responsibleUserAutocomplete = useAutocomplete();

  const {
    data: companyWAData,
    loading: companyWALoading,
  } = useCompanyUsersQuery({
    variables: {
      filter: {
        page: responsibleUserAutocomplete.onePage,
        pageSize: responsibleUserAutocomplete.pageSize,
        search: responsibleUserAutocomplete.searchValue,
        roles: [UserCompanyRole.WorkspaceAdmin, UserCompanyRole.Hr],
      },
    },
    fetchPolicy: 'no-cache',
  });

  const waTotal = companyWAData?.companyUsers?.count;

  const refResponsibleOptions = uniqSelectOptions(
    [user, ...(companyWAData?.companyUsers?.items || [])],
    { value: 'id', label: 'name' }
  );

  useEffect(() => {
    if (error || !authCode || !authState) {
      showErrorNotification({
        content: ErrorMessages.OAuthCallbackError,
      });

      if (onCancel) {
        setTimeout(onCancel, 1000);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitForm = (data: FormTypes) =>
    upsertLeverCompanyIntegration({
      variables: {
        input: {
          authCode,
          authState,
          responsibleUserId: data.responsibleUserId,
        },
      },
    });

  return (
    <Form form={form} onFinish={handleSubmitForm}>
      {/* Responsible user  */}
      <FormItem name="responsibleUserId">
        <SelectAutocomplete
          placeholder={t('EditATSIntegrationForms.selectPlaceholder')}
          items={refResponsibleOptions}
          total={waTotal}
          loading={companyWALoading}
          onClear={responsibleUserAutocomplete.reset}
          onScroll={responsibleUserAutocomplete.onScroll}
          onSearch={responsibleUserAutocomplete.onSearch}
        />
      </FormItem>
      {/* Action buttons */}
      <FormItem noStyle shouldUpdate>
        {({ getFieldsError }) => (
          <ModalActions
            onCancel={onCancel}
            saveBtnText="Save"
            saveBtnLoading={loading}
            saveBtnDisabled={
              !!getFieldsError().filter(({ errors }) => errors.length).length
            }
          />
        )}
      </FormItem>
    </Form>
  );
};

export default LeverOAuthCallback;
