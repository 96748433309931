import { Radio, RadioGroupProps } from 'antd';

import './statusButtons.less';

const { Group: RadioGroup } = Radio;

const StatusButtons = (props: RadioGroupProps) => (
  <RadioGroup className="status-buttons" optionType="button" {...props} />
);

export default StatusButtons;
