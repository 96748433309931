import { ShareLink } from 'components';

import './companyLinks.less';

type PropsType = {
  domains: string[] | undefined;
};

const CompanyLink = ({ domains }: PropsType) => (
  <>
    <div className="company-links__title">Invite Link</div>
    <div className="company-links__text">
      The following link will allow any of your employees to sign up, as long as
      they have the same {domains?.join(', ') || ''} domain name in their email
      address.
    </div>
    <ShareLink />
  </>
);

export default CompanyLink;
