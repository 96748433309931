enum SuccessMessages {
  ChangesSaved = 'Changes have been saved',
  UserDetailsSaved = 'User details have been saved',
  UserPasswordReset = 'User password has been reset',
  PasswordUpdated = 'Your password has been updated successfully',
  CandidateReferred = 'Candidate referred',
  SubscriptionActivated = 'Subscription have been activated',
  ProfileUpdated = 'Profile successfully updated',
  AvatarUpdated = 'Avatar successfully updated',
  PaymentInformationUpdated = 'Payment information successfully updated',
  ResetPasswordInstructionsSent = 'Reset password instructions have been sent to your email',
  BillingAddressUpdate = 'Billing address successfully updated',
  CompanyIntegrationConfigured = 'Company integration successfully configured',
}

export { SuccessMessages };
