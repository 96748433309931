import { useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Modal } from 'components';
import { Routes } from 'utils';
import LeverOAuthCallback from './LeverOAuthCallback';

import './oAuthCallbackModal.less';

enum OAuthService {
  lever = 'lever',
}
type PropTypes = {
  refetchCIs: () => void;
};

const OAuthCallbackModal = ({ refetchCIs }: PropTypes) => {
  const history = useHistory();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const oauthCallback = search.get('oauth_callback');
  const service = Object.values(OAuthService).includes(oauthCallback as any)
    ? oauthCallback
    : null;

  const onComplete = useCallback(() => {
    refetchCIs();
    history.push(Routes.CompanyIntegrations);
  }, [history, refetchCIs]);

  const onCancel = useCallback(() => {
    history.push(Routes.CompanyIntegrations);
  }, [history]);

  // Reset query params on invalid oauth callback service
  useEffect(() => {
    if (oauthCallback && !service) {
      history.push(Routes.CompanyIntegrations);
    }
  }, [service, history, oauthCallback]);

  return (
    <Modal
      visible={!!service}
      className="integration-edit-modal"
      title="Processing company integration"
    >
      {service === OAuthService.lever && (
        <LeverOAuthCallback onComplete={onComplete} onCancel={onCancel} />
      )}
    </Modal>
  );
};

export default OAuthCallbackModal;
