import { CompanyIntegrationFragment, IntegrationService } from 'api';
import {
  CreateGreenhouseIntegrationForm,
  EditGreenhouseIntegrationForm,
  CreateLeverIntegrationForm,
  EditLeverIntegrationForm,
} from './index';

type PropTypes = {
  onCancel: () => any;
  onSubmit: (res?: any) => any;
  service: IntegrationService | null;
  integration?: CompanyIntegrationFragment;
};

const CreateFormsMap = {
  [IntegrationService.Greenhouse]: CreateGreenhouseIntegrationForm,
  [IntegrationService.Lever]: CreateLeverIntegrationForm,
};

const EditFormsMap = {
  [IntegrationService.Greenhouse]: EditGreenhouseIntegrationForm,
  [IntegrationService.Lever]: EditLeverIntegrationForm,
};

const UpsertIntegrationForm = ({
  service,
  onSubmit,
  onCancel,
  integration,
}: PropTypes) => {
  if (!service) {
    return null;
  }

  if (integration) {
    const Form = EditFormsMap[service];
    return (
      <Form
        onCancel={onCancel}
        onSubmit={onSubmit}
        companyIntegration={integration}
      />
    );
  }

  const Form = CreateFormsMap[service];
  return <Form onCancel={onCancel} onSubmit={onSubmit} />;
};

export default UpsertIntegrationForm;
