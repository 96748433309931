import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import { ApolloProvider } from '@apollo/client';
import { Router } from 'react-router-dom';
import { client } from 'api';
import { CurrentUserProvider } from 'contexts';
import { history, initSentry } from 'utils';

import App from './App';
import reportWebVitals from './reportWebVitals';

import 'utils/translation/i18n';

// Init sentry error logger
initSentry();
ReactDOM.render(
  <ApolloProvider client={client}>
    <Router history={history}>
      <ConfigProvider form={{ requiredMark: false }}>
        <CurrentUserProvider>
          <App />
        </CurrentUserProvider>
      </ConfigProvider>
    </Router>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
