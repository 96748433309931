import React from 'react';
import * as Sentry from '@sentry/react';
import { Card, Button, Layout } from 'antd';
import { CloseCircleOutlined, ReloadOutlined } from '@ant-design/icons';

import './errorBoundary.less';

const ErrorBoundary = ({ children }: { children: any }) => (
  <Sentry.ErrorBoundary
    fallback={
      <Layout className="error-boundary">
        <Card
          className="error-boundary__card"
          headStyle={{ background: '#ff003d', textAlign: 'center' }}
          title={<CloseCircleOutlined className="error-boundary__icon" />}
          bordered={false}
          actions={[
            <Button
              key="refresh"
              type="primary"
              icon={<ReloadOutlined />}
              onClick={() => window.location.reload()}
              danger
            >
              Refresh
            </Button>,
          ]}
        >
          <h1 className="error-boundary__title">Warning!</h1>
          <p className="error-boundary__text">
            An error has occurred! <br />
            Please refresh the page and try again.
          </p>
        </Card>
      </Layout>
    }
  >
    {children}
  </Sentry.ErrorBoundary>
);

export default ErrorBoundary;
