import { useCallback } from 'react';
import { Modal } from 'components';
import AddDepartmentForm from '../AddDepartmentForm';

type PropsType = {
  visible?: boolean;
  onCancel: () => void;
  refetch: () => void;
};

const AddDepartmentModal = ({ visible, onCancel, refetch }: PropsType) => {
  const onComplete = useCallback(() => {
    refetch();
    onCancel();
  }, [refetch, onCancel]);

  return (
    <Modal title="New Department" visible={visible} onCancel={onCancel}>
      <AddDepartmentForm onComplete={onComplete} onCancel={onCancel} />
    </Modal>
  );
};

export default AddDepartmentModal;
