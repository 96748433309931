import { DatePicker, DatePickerProps } from 'antd';
import moment, { Moment } from 'moment';
import locale from 'antd/lib/date-picker/locale/en_US';

const disabledDate = (current: Moment) => {
  // Can not select days before today
  const customDate = moment().format('YYYY-MM-DD');
  return current && current < moment(customDate, 'YYYY-MM-DD');
};

const DatePickerItem = (props: DatePickerProps) => (
  <DatePicker
    locale={{
      ...locale,
      lang: {
        ...locale.lang,
        ok: 'Save',
      },
    }}
    format="MMMM DD, YYYY hh:mm A Z"
    disabledDate={disabledDate}
    {...props}
  />
);
export default DatePickerItem;
