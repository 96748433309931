import classNames from 'classnames';
import './tableTitle.less';

type PropTypes = {
  title: string;
  count?: number;
  className?: string;
};

const TableTitle = ({ title, className, ...props }: PropTypes) => (
  <div className={classNames(className, 'table_title')}>
    <span>{title}</span>
    {'count' in props && (
      <span className="table_title__count">
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {Number.isInteger(props.count) ? `(${props.count})` : ''}
      </span>
    )}
  </div>
);

export default TableTitle;
