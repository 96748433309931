import { Address, BillingAddress } from 'api';

const formatAddress = (
  address?: Address | BillingAddress | null
): string | null => {
  if (!address) {
    return null;
  }

  const stateAndZip = [address.state, address.zip].filter((x) => x).join(' ');

  return [address.street, address.city, stateAndZip, address.country]
    .filter((x) => x)
    .join(', ');
};

export { formatAddress };
