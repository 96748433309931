import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const GoogleAnalytics = () => {
  const location = useLocation();
  const currentUrl = location.pathname;

  useEffect(() => {
    const env = process.env.REACT_APP_ENV as string;
    const trackingId = process.env.REACT_APP_GA_TRACKING_ID as string;
    ReactGA.initialize(trackingId, {
      debug: env === 'development',
      testMode: env === 'test',
    });
  }, []);

  useEffect(() => {
    ReactGA.pageview(currentUrl);
  }, [currentUrl]);

  return null;
};

export default GoogleAnalytics;
