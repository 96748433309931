import { Button } from 'antd';
import { useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from 'utils';
import { Logo } from 'assets/images';
import { useBreakpoint } from 'hooks';
import { HamburgerIcon } from 'assets/icons';
import { Container, HomeNavDrawer } from 'components';

import './homeHeader.less';

const HomeHeader = () => {
  const history = useHistory();

  // Home navigation drawer open state
  const [isOpen, setIsOpen] = useState(false);

  const { lg } = useBreakpoint();

  // Toggle home navigation drawer open
  const toggleOpen = useCallback(() => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  }, []);

  const websiteUrl = process.env.REACT_APP_WEBSITE_URL as string;

  return (
    <div className="home-header">
      <Container className="home-header__container">
        {/* Logo */}
        <Link className="home-header__logo" to="">
          <Logo />
        </Link>

        {lg ? (
          <>
            {/* Navigation */}
            <div className="home-header__nav">
              <a href={`${websiteUrl}/solutions`} className="home-header__link">
                Solutions
              </a>
              <a href={`${websiteUrl}/pricing`} className="home-header__link">
                Pricing
              </a>
              <a
                href={`${websiteUrl}/contact-us`}
                className="home-header__link"
              >
                Contact Us
              </a>
            </div>

            {/* SignIn/SignUp buttons */}
            <div className="home-header__actions">
              <Link className="home-header__action-link" to={Routes.SignIn}>
                Login
              </Link>
              <Button
                className="home-header__btn"
                type="primary"
                onClick={() => history.push(Routes.SignUp)}
                ghost
              >
                Start your free trial
              </Button>
            </div>
          </>
        ) : (
          // Mobile and tablet hamburger button
          <button
            className="home-header__hamburger"
            type="button"
            onClick={toggleOpen}
          >
            <HamburgerIcon />
          </button>
        )}
      </Container>

      {/* Mobile and tablet navigation drawer */}
      <HomeNavDrawer isOpen={isOpen} toggleOpen={toggleOpen} />
    </div>
  );
};

export default HomeHeader;
