import classNames from 'classnames';
import { Button, ButtonProps } from 'antd';

import './primaryButton.less';

const PrimaryButton = (props: ButtonProps) => {
  const { className } = props;

  return (
    <Button
      {...props}
      className={classNames('primary-btn', className)}
      type="primary"
      block
    />
  );
};

export default PrimaryButton;
