import { useCallback, useState } from 'react';
import { Drawer, Form, Input, Select, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useAutocomplete, useBreakpoint, useForm } from 'hooks';
import {
  FormItem,
  ModalActions,
  PasswordInput,
  GreenhouseInstructionContent,
  SelectAutocomplete,
} from 'components';
import {
  ErrorMessages,
  isAtsUserNotFoundError,
  isAtsUnauthorizedError,
  isAtsError,
  showErrorNotification,
  showSuccessNotification,
  SuccessMessages,
  uniqSelectOptions,
  isBadUserInputError,
  findBadUserInputProp,
} from 'utils';
import {
  CompanyIntegrationFragment,
  useCompanyUsersQuery,
  UserCompanyRole,
  useUpdateGreenhouseCompanyIntegrationMutation,
} from 'api';

type PropTypes = {
  onCancel: () => any;
  onSubmit: (res?: any) => any;
  companyIntegration: CompanyIntegrationFragment;
};

type FormTypes = {
  secret?: string;
  isActive?: boolean;
  onBehalfOfInput?: string;
  responsibleUserId?: string;
  jobBoards?: string[];
};

const EditGreenhouseIntegrationForm = ({
  onCancel,
  onSubmit,
  companyIntegration,
}: PropTypes) => {
  const [form] = useForm<FormTypes>();
  const [isSecretModified, setIsSecretModified] = useState<boolean>(false);
  const { t } = useTranslation();
  // Get autocomplete info and methods
  const responsibleUserAutocomplete = useAutocomplete();
  const { md } = useBreakpoint();
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = useCallback(() => {
    setVisible(false);
  }, []);

  const {
    data: companyWAData,
    loading: companyWALoading,
  } = useCompanyUsersQuery({
    variables: {
      filter: {
        page: responsibleUserAutocomplete.onePage,
        pageSize: responsibleUserAutocomplete.pageSize,
        search: responsibleUserAutocomplete.searchValue,
        roles: [UserCompanyRole.WorkspaceAdmin, UserCompanyRole.Hr],
      },
    },
    fetchPolicy: 'no-cache',
  });

  const waTotal = companyWAData?.companyUsers?.count;

  const refResponsibleOptions = uniqSelectOptions(
    [
      companyIntegration.responsibleUser,
      ...(companyWAData?.companyUsers?.items || []),
    ],
    { value: 'id', label: 'name' }
  );

  const [
    updateGreenhouseCompanyIntegration,
    { loading },
  ] = useUpdateGreenhouseCompanyIntegrationMutation({
    onCompleted(res) {
      showSuccessNotification({
        content: SuccessMessages.CompanyIntegrationConfigured,
      });

      onSubmit(res.updateGreenhouseCompanyIntegration);
    },
    onError({ graphQLErrors }) {
      graphQLErrors.forEach((error) => {
        let handledErrors = 0;
        if (isAtsUnauthorizedError(error)) {
          return form.setFields([
            {
              name: 'secret',
              errors: [ErrorMessages.GHInvalidSecret],
            },
          ]);
        }

        if (isAtsUserNotFoundError(error)) {
          return form.setFields([
            {
              name: 'onBehalfOfInput',
              errors: [ErrorMessages.GHInvalidOnBehalfOf],
            },
          ]);
        }

        if (isBadUserInputError(error)) {
          const invalidArgJobBoard = findBadUserInputProp(error, 'jobBoards');

          if (invalidArgJobBoard) {
            form.setFields([
              {
                name: 'jobBoards',
                errors: [
                  t('EditATSIntegrationForms.jobBoardTokensNotFound', {
                    jobBoardsList: invalidArgJobBoard.value.join(', '),
                  }),
                ],
              },
            ]);
            handledErrors += 1;
          }

          if (handledErrors < error.extensions?.invalidArgs.length) {
            showErrorNotification({ content: 'Unhandled errors' });
          }
          // eslint-disable-next-line consistent-return
          return;
        }

        if (isAtsError(error)) {
          return showErrorNotification({
            content: ErrorMessages.AtsError,
          });
        }

        return showErrorNotification();
      });
    },
  });

  const handleSubmitForm = async (data: FormTypes): Promise<any> => {
    const input = {
      ...(isSecretModified && 'secret' in data && { secret: data.secret }),
      ...('isActive' in data && { isActive: data.isActive }),
      ...('onBehalfOfInput' in data && {
        onBehalfOfInput: data.onBehalfOfInput,
      }),
      ...(data.responsibleUserId && {
        responsibleUserId: data.responsibleUserId,
      }),
      ...(data.jobBoards && {
        jobBoards: data.jobBoards,
      }),
    };

    return updateGreenhouseCompanyIntegration({
      variables: { input },
    });
  };

  return (
    <Form
      form={form}
      initialValues={{
        isActive:
          'isActive' in companyIntegration ? companyIntegration.isActive : true,
        secret:
          'secretLastSymbols' in companyIntegration
            ? `•••••••••••••••••••${companyIntegration.secretLastSymbols}`
            : '',
        onBehalfOfInput:
          'onBehalfOfInput' in companyIntegration
            ? companyIntegration.onBehalfOfInput
            : '',
        responsibleUserId:
          'responsibleUserId' in companyIntegration
            ? companyIntegration.responsibleUserId
            : null,
        jobBoards:
          'customFields' in companyIntegration
            ? companyIntegration.customFields?.jobBoards
            : null,
      }}
      onFinish={handleSubmitForm}
    >
      <FormItem
        name="secret"
        rules={[
          { whitespace: true },
          {
            max: 500,
            message: ErrorMessages.ValueIsTooLong,
          },
        ]}
      >
        <PasswordInput
          onChange={() =>
            setIsSecretModified((prevIsSecretModified) => {
              // Clear secret input on the first change
              if (!prevIsSecretModified) {
                form.setFieldsValue({ secret: '' });
              }
              return true;
            })
          }
          placeholder="Harvest API key"
          type="password"
        />
      </FormItem>

      <FormItem
        name="onBehalfOfInput"
        validateFirst
        rules={[
          { whitespace: true },
          {
            max: 256,
            message: ErrorMessages.ValueIsTooLong,
          },
        ]}
      >
        <Input placeholder="System user id or email" />
      </FormItem>
      <div className="green-house-form__select-wrapper">
        <FormItem
          name="jobBoards"
          rules={[
            {
              required: true,
              message: ErrorMessages.GHEmptyJBToken,
            },
          ]}
        >
          <Select
            className="green-house-form__board-tokens"
            size="middle"
            mode="tags"
            tokenSeparators={[',']}
            placeholder="List of job board tokens"
            dropdownClassName="green-house-form__dropdown"
          />
        </FormItem>
        <InfoCircleOutlined
          onClick={showDrawer}
          className="green-house-form__info-icon"
        />
      </div>
      <Drawer
        title={t('EditATSIntegrationForms.drawerTitle')}
        placement="right"
        onClose={onClose}
        visible={visible}
        width={md ? 576 : 320}
      >
        <GreenhouseInstructionContent />
      </Drawer>

      {/* Responsible user  */}
      <FormItem name="responsibleUserId">
        <SelectAutocomplete
          placeholder={t('EditATSIntegrationForms.selectPlaceholder')}
          items={refResponsibleOptions}
          total={waTotal}
          loading={companyWALoading}
          onClear={responsibleUserAutocomplete.reset}
          onScroll={responsibleUserAutocomplete.onScroll}
          onSearch={responsibleUserAutocomplete.onSearch}
        />
      </FormItem>

      {/* Is Active toggle */}
      <div className="switch-form-item">
        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const isActive = getFieldValue('isActive');
            const label = isActive ? 'Active' : 'Inactive';
            return <span className="switch-form-item__label">{label}</span>;
          }}
        </FormItem>
        <FormItem name="isActive" valuePropName="checked">
          <Switch />
        </FormItem>
      </div>

      {/* Action buttons */}
      <FormItem noStyle shouldUpdate>
        {({ getFieldsError }) => (
          <ModalActions
            onCancel={onCancel}
            saveBtnText="Save"
            saveBtnLoading={loading}
            saveBtnDisabled={
              !!getFieldsError().filter(({ errors }) => errors.length).length
            }
          />
        )}
      </FormItem>
    </Form>
  );
};

export default EditGreenhouseIntegrationForm;
