import { useEffect } from 'react';
import { Drawer, Button } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { Routes } from 'utils';
import { Logo } from 'assets/images';
import { useBreakpoint } from 'hooks';
import { CloseIcon } from 'assets/icons';

import './homeNavDrawer.less';

type PropsType = {
  isOpen?: boolean;
  toggleOpen: () => void;
};

const HomeNavDrawer = ({ toggleOpen, isOpen }: PropsType) => {
  const history = useHistory();

  const { lg, md } = useBreakpoint();

  // Close side navigation if open and screen lg
  useEffect(() => {
    if (lg && isOpen) {
      toggleOpen();
    }
  }, [isOpen, lg, toggleOpen]);

  const redirectToSignUp = () => {
    toggleOpen();
    history.push(Routes.SignUp);
  };

  const websiteUrl = process.env.REACT_APP_WEBSITE_URL as string;

  return (
    <Drawer
      className="home-nav-drawer"
      title={<Logo className="home-nav-drawer__logo" />}
      closeIcon={<CloseIcon />}
      visible={isOpen}
      onClose={toggleOpen}
      width={md ? '50%' : '100%'}
      destroyOnClose
    >
      {/* Navigation */}
      <div className="home-nav-drawer__nav">
        <a href={`${websiteUrl}/solutions`} className="home-nav-drawer__link">
          Solutions
        </a>
        <a href={`${websiteUrl}/pricing`} className="home-nav-drawer__link">
          Pricing
        </a>
        <a href={`${websiteUrl}/contact-us`} className="home-nav-drawer__link">
          Contact Us
        </a>
      </div>

      {/* SignIn/SignUp buttons */}
      <div className="home-nav-drawer__actions">
        <Link
          className="home-nav-drawer__action-link"
          onClick={toggleOpen}
          to={Routes.SignIn}
        >
          Login
        </Link>
        <Button
          className="home-nav-drawer__btn"
          type="primary"
          onClick={redirectToSignUp}
          ghost
        >
          Start your free trial
        </Button>
      </div>
    </Drawer>
  );
};

export default HomeNavDrawer;
