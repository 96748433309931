import { Form, FormInstance, Input } from 'antd';
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { ErrorMessages } from 'utils';
import {
  FormItem,
  GoogleButton,
  PasswordInput,
  PrimaryButton,
} from 'components';

import './signUpForm.less';

type FormValuesType = {
  name: string;
  email: string;
  password: string;
  captchaToken?: string;
};

type PropsType = {
  form: FormInstance;
  onSubmit: ({ name, email, password, captchaToken }: FormValuesType) => any;
  handleSignUpWithGoogle?: (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => void;
  loading?: boolean;
  googleLoading?: boolean;
  disabled?: boolean;
};

const SignUpForm = ({
  form,
  loading,
  googleLoading,
  disabled,
  onSubmit,
  handleSignUpWithGoogle,
}: PropsType) => (
  <Form className="sign-up-form" form={form} onFinish={onSubmit}>
    <FormItem
      name="name"
      validateFirst
      rules={[
        {
          required: true,
          whitespace: true,
          message: ErrorMessages.EnterYourName,
        },
        {
          max: 255,
          message: ErrorMessages.NameTooLong,
        },
      ]}
    >
      <Input placeholder="Name" size="large" />
    </FormItem>
    <FormItem
      name="email"
      validateFirst
      validateTrigger={['onBlur']}
      rules={[
        {
          type: 'email',
          required: true,
          whitespace: true,
          message: ErrorMessages.InvalidEmail,
        },
      ]}
    >
      <Input
        placeholder="Email"
        size="large"
        onFocus={() => {
          form.setFields([{ name: 'email', errors: [] }]);
        }}
      />
    </FormItem>
    <FormItem
      name="password"
      validateFirst
      validateTrigger={['onBlur']}
      rules={[
        {
          required: true,
          message: ErrorMessages.EmptyPassword,
        },
        { max: 128, message: ErrorMessages.PasswordToLong },
        {
          pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
          message: ErrorMessages.PasswordToWeak,
        },
      ]}
    >
      <PasswordInput
        placeholder="Password"
        size="large"
        onFocus={() => {
          form.setFields([{ name: 'password', errors: [] }]);
        }}
      />
    </FormItem>

    {/* SignUp/SingUpWithGoogle buttons */}
    <div className="sign-up-form__actions">
      <PrimaryButton
        htmlType="submit"
        disabled={googleLoading || disabled}
        loading={loading}
      >
        Sign Up
      </PrimaryButton>
      {!!handleSignUpWithGoogle && (
        <>
          <span className="sign-up-form__btn-divider">Or</span>
          <GoogleButton
            buttonText="Sign Up"
            onSuccess={handleSignUpWithGoogle}
            disabled={loading || disabled}
            loading={googleLoading}
          />
        </>
      )}
    </div>
  </Form>
);

export default SignUpForm;
