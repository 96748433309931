import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';

const { RangePicker } = DatePicker;

const RangeFilter = (props: RangePickerProps) => (
  <RangePicker
    picker="date"
    separator="-"
    format="MMM DD, YYYY"
    inputReadOnly
    {...props}
  />
);

export default RangeFilter;
