import { useBreakpoint } from 'hooks';
import { CompanyHeader, CompanyNav } from 'components';

import './companyLayout.less';

type Props = {
  children: any;
};

const CompanyLayout = ({ children }: Props) => {
  const { lg } = useBreakpoint();

  return (
    <div className="company">
      <div className="company__header">
        <CompanyHeader />
      </div>
      <div className="company__container">
        {lg && <CompanyNav className="company__navigation" />}

        <div className="company__content">{children}</div>
      </div>
    </div>
  );
};

export default CompanyLayout;
