import classNames from 'classnames';
import { ReactNode } from 'react';

import './container.less';

type PropsType = {
  children: ReactNode;
  className?: string;
};

const Container = ({ children, className }: PropsType) => (
  <div className={classNames('container', className)}>{children}</div>
);

export default Container;
