import {
  GoogleLogin,
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import { Button } from 'antd';
import classNames from 'classnames';

import './googleButton.less';

type PropsType = {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  buttonText: 'Sign In' | 'Sign Up';
  onSuccess: (
    response: GoogleLoginResponse | GoogleLoginResponseOffline
  ) => void;
};

const GoogleButton = ({
  className,
  disabled,
  loading,
  buttonText,
  onSuccess,
}: PropsType) => (
  <GoogleLogin
    clientId={process.env.REACT_APP_GOOGLE_OUATH_CLIENT_ID as string}
    onSuccess={onSuccess}
    cookiePolicy="single_host_origin"
    render={({ onClick, disabled: btnDisabled }) => (
      <Button
        className={classNames('google-btn', className)}
        type="primary"
        disabled={disabled || btnDisabled}
        loading={loading}
        onClick={onClick}
        danger
        ghost
        block
      >
        {buttonText} with<span className="google-btn__solid">Google</span>
      </Button>
    )}
  />
);

export default GoogleButton;
