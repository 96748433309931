import { ReactNode } from 'react';
import classNames from 'classnames';
import { Modal, ModalProps } from 'antd';
import { CloseIcon } from 'assets/icons';

import './modal.less';

type PropsType = ModalProps & {
  children: ReactNode;
};

const ModalWrapper = (props: PropsType) => {
  const { children, className, ...modalProps } = props;

  return (
    <Modal
      className={classNames('modal', className)}
      closeIcon={<CloseIcon width={18} height={18} />}
      footer={null}
      width={640}
      centered
      destroyOnClose
      {...modalProps}
    >
      {children}
    </Modal>
  );
};

export default ModalWrapper;
