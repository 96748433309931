import { Form, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useAutocomplete, useForm } from 'hooks';
import {
  FormItem,
  ModalActions,
  EditButton,
  SelectAutocomplete,
} from 'components';
import {
  showErrorNotification,
  showSuccessNotification,
  SuccessMessages,
  uniqSelectOptions,
} from 'utils';
import {
  CompanyIntegrationFragment,
  useCompanyUsersQuery,
  useLeverOAuthUrlQuery,
  UserCompanyRole,
  useUpdateLeverCompanyIntegrationMutation,
} from 'api';

import './editLeverIntegrationForm.less';

type FormTypes = {
  isActive?: boolean;
  responsibleUserId?: string;
};

type PropTypes = {
  onCancel: () => any;
  onSubmit: (res?: any) => any;
  companyIntegration: CompanyIntegrationFragment;
};

const EditLeverIntegrationForm = ({
  onCancel,
  onSubmit,
  companyIntegration,
}: PropTypes) => {
  const [form] = useForm<FormTypes>();
  // Get autocomplete info and methods
  const responsibleUserAutocomplete = useAutocomplete();
  const { t } = useTranslation();
  const {
    data: companyWAData,
    loading: companyWALoading,
  } = useCompanyUsersQuery({
    variables: {
      filter: {
        page: responsibleUserAutocomplete.onePage,
        pageSize: responsibleUserAutocomplete.pageSize,
        search: responsibleUserAutocomplete.searchValue,
        roles: [UserCompanyRole.WorkspaceAdmin, UserCompanyRole.Hr],
      },
    },
    fetchPolicy: 'no-cache',
  });

  const waTotal = companyWAData?.companyUsers?.count;

  const refResponsibleOptions = uniqSelectOptions(
    [
      companyIntegration.responsibleUser,
      ...(companyWAData?.companyUsers?.items || []),
    ],
    { value: 'id', label: 'name' }
  );

  const [
    updateLeverCompanyIntegration,
    { loading },
  ] = useUpdateLeverCompanyIntegrationMutation({
    onCompleted(res) {
      showSuccessNotification({
        content: SuccessMessages.CompanyIntegrationConfigured,
      });

      onSubmit(res.updateLeverCompanyIntegration);
    },
    onError({ graphQLErrors }) {
      graphQLErrors.forEach(() => {
        showErrorNotification();
      });
    },
  });

  const {
    data: oAuthLinkData,
    loading: oAuthLinkLoading,
  } = useLeverOAuthUrlQuery({
    nextFetchPolicy: 'cache-first',
  });

  const handleSubmitForm = async (data: FormTypes): Promise<any> => {
    const input = {
      ...('isActive' in data && { isActive: data.isActive }),
      ...(data.responsibleUserId && {
        responsibleUserId: data.responsibleUserId,
      }),
    };

    return updateLeverCompanyIntegration({ variables: { input } });
  };

  const handleLoginWithLever = () => {
    const link = oAuthLinkData?.leverOAuthUrl;
    if (!link) {
      return;
    }
    window.open(link);
  };

  return (
    <Form
      form={form}
      initialValues={{
        isActive: companyIntegration.isActive,
        responsibleUserId:
          'responsibleUserId' in companyIntegration
            ? companyIntegration.responsibleUserId
            : null,
      }}
      onFinish={handleSubmitForm}
    >
      {/* Is Active toggle */}
      <div className="switch-form-item">
        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const isActive = getFieldValue('isActive');
            const label = isActive ? 'Active' : 'Inactive';
            return <span className="switch-form-item__label">{label}</span>;
          }}
        </FormItem>
        <FormItem name="isActive" valuePropName="checked">
          <Switch />
        </FormItem>
      </div>

      {/* Responsible user  */}
      <FormItem name="responsibleUserId">
        <SelectAutocomplete
          placeholder={t('EditATSIntegrationForms.selectPlaceholder')}
          items={refResponsibleOptions}
          total={waTotal}
          loading={companyWALoading}
          onClear={responsibleUserAutocomplete.reset}
          onScroll={responsibleUserAutocomplete.onScroll}
          onSearch={responsibleUserAutocomplete.onSearch}
        />
      </FormItem>

      <div className="reinstall-form-item">
        <EditButton
          htmlType="button"
          onClick={handleLoginWithLever}
          disabled={oAuthLinkLoading}
        >
          Reinstall Lever
        </EditButton>
      </div>

      {/* Action buttons */}
      <FormItem noStyle shouldUpdate>
        {({ getFieldsError }) => (
          <ModalActions
            onCancel={onCancel}
            saveBtnText="Save"
            saveBtnLoading={loading}
            saveBtnDisabled={
              !!getFieldsError().filter(({ errors }) => errors.length).length
            }
          />
        )}
      </FormItem>
    </Form>
  );
};

export default EditLeverIntegrationForm;
