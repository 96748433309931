import jwtDecode from 'jwt-decode';

const jwtDecodeSafely = (accessToken: string) => {
  try {
    return jwtDecode(accessToken);
  } catch {
    return null;
  }
};

const isAccessTokenExpired = (
  accessToken: string,
  tolerance = 5000
): boolean => {
  if (!accessToken) {
    return true;
  }
  try {
    const decodedJwt: { exp: number } = jwtDecode(accessToken);
    return decodedJwt.exp * 1000 <= Date.now() + tolerance;
  } catch {
    return false;
  }
};

export { isAccessTokenExpired, jwtDecodeSafely };
