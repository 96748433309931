enum ReferralReasonNames {
  FRIEND = 'Friend',
  PAST_CO_WORKER = 'Past co-worker',
  NO_PERSONAL_RELATIONSHIP = 'No personal relationship',
  OTHER = 'Other',
}

const ReferralReasonOptions = Object.entries(
  ReferralReasonNames
).map(([key, value]) => ({ label: value, value: key }));

export { ReferralReasonNames, ReferralReasonOptions };
