import React from 'react';
import { useCurrentUserContext } from 'hooks';
import { UserRole, useUserPointsBalanceQuery } from 'api';

const UserPointsContext = React.createContext({
  userPoints: 0,
});

type Props = {
  children: any;
};

const UserPointsProvider = ({ children }: Props) => {
  const { user } = useCurrentUserContext();

  const userRole = user?.role;

  const { data: userPointsBalanceData } = useUserPointsBalanceQuery({
    pollInterval: 30000,
    skip: userRole !== UserRole.User,
    /*
     * Fix bug with unintentional query trigger
     * To reproduce:
     * Login as a user
     * Reload the page
     * Logout
     * Login as a WorkspaceAdmin or HR
     * */
    fetchPolicy: 'no-cache',
  });

  const userPoints = userPointsBalanceData?.userPointsBalance || 0;

  return (
    <UserPointsContext.Provider value={{ userPoints }}>
      {children}
    </UserPointsContext.Provider>
  );
};

export { UserPointsContext, UserPointsProvider };
