import React from 'react';
import { NetworkStatus } from '@apollo/client/core';
import { UserFragment, useCurrentUserQuery, CurrentUserQueryResult } from 'api';

export type CurrentUserType = Omit<CurrentUserQueryResult, 'data'> & {
  user?: UserFragment;
  company?: UserFragment['company'];
};

const CurrentUserContext = React.createContext<
  Pick<
    CurrentUserType,
    'user' | 'company' | 'loading' | 'networkStatus' | 'error' | 'refetch'
  >
>({
  user: undefined,
  company: undefined,
  loading: false,
  networkStatus: NetworkStatus.loading,
  error: undefined,
  refetch(): any {},
});

type Props = {
  children: any;
};

const CurrentUserProvider = ({ children }: Props) => {
  const { data, ...rest } = useCurrentUserQuery();

  const value: CurrentUserType = {
    ...rest,
    user: undefined,
    company: undefined,
  };

  if (data?.currentUser) {
    const { company, ...user } = data.currentUser;
    value.user = user;
    value.company = company;
  }

  return (
    <CurrentUserContext.Provider value={value}>
      {children}
    </CurrentUserContext.Provider>
  );
};

export { CurrentUserContext, CurrentUserProvider };
