import { useCallback } from 'react';
import { Modal } from 'components';
import { DepartmentFragment } from 'api';
import EditDepartmentForm from '../EditDepartmentForm';

type PropsType = {
  editDepartmentInfo: DepartmentFragment | undefined | null;
  visible?: boolean;
  onCancel: () => void;
  refetch: () => void;
};

const EditDepartmentModal = ({
  editDepartmentInfo,
  visible,
  onCancel,
  refetch,
}: PropsType) => {
  const onComplete = useCallback(() => {
    refetch();
    onCancel();
  }, [refetch, onCancel]);

  return (
    <Modal title="Edit Department" visible={visible} onCancel={onCancel}>
      {!!editDepartmentInfo && (
        <EditDepartmentForm
          editDepartmentInfo={editDepartmentInfo}
          onComplete={onComplete}
          onCancel={onCancel}
        />
      )}
    </Modal>
  );
};

export default EditDepartmentModal;
