import { Avatar, Dropdown } from 'antd';
import { UserMenu } from 'components';
import { DownIcon } from 'assets/icons';
import { useCurrentUserContext } from 'hooks';
import { RoleNames, userInitials } from 'utils';

import './userInfoLeft.less';

const UserInfoLeft = () => {
  const { user } = useCurrentUserContext();

  const userName = user?.name;
  const userRole = user?.role;
  const userAvatar = user?.avatarLink;

  return (
    <Dropdown overlay={<UserMenu />} trigger={['click']}>
      <div className="user-info-left">
        <div className="user-info-left__text">
          <span className="user-info-left__name">{userName}</span>
          <span className="user-info-left__role">
            {userRole && RoleNames[userRole]}
          </span>
        </div>
        <Avatar className="user-info-left__avatar" src={userAvatar} size={48}>
          {userInitials(userName)}
        </Avatar>
        <DownIcon className="user-info-left__down" />
      </div>
    </Dropdown>
  );
};

export default UserInfoLeft;
