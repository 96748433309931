import classNames from 'classnames';
import { useState } from 'react';
import { DeleteFilled } from '@ant-design/icons';
import { Form, Input, Upload, Spin } from 'antd';
import { useAutocomplete, useForm } from 'hooks';
import { FormItem, SelectAutocomplete, ModalActions, Select } from 'components';
import {
  RoleFragment,
  useCreateReferralMutation,
  useCompanyActiveRolesQuery,
  ReferralReasonOption,
} from 'api';
import {
  isNotFound,
  ErrorMessages,
  SuccessMessages,
  showErrorNotification,
  showSuccessNotification,
  uniqSelectOptions,
  ReferralReasonOptions,
} from 'utils';

import './referCandidateForm.less';

const { Dragger } = Upload;

type PropsType = {
  onClose: () => void;
  refetch?: () => void;
  initialRole?: RoleFragment;
};

type FormValuesType = {
  candidateName: string;
  candidateEmail: string;
  candidateLinkedin: string;
  roleId: string;
  reason?: string;
  reasonOption?: ReferralReasonOption;
};

const ReferCandidateForm = ({ onClose, refetch, initialRole }: PropsType) => {
  // Candidate CV file
  const [candidateCV, setCandidateCV] = useState<File | null>(null);
  const [candidateCVError, setCandidateCVError] = useState<string | null>(null);
  const [
    selectedReasonOption,
    setReasonOption,
  ] = useState<ReferralReasonOption | null>(null);

  const [form] = useForm();

  const handleRefetch = () => (refetch ? refetch() : null);

  // Select autocomplete info and methods
  const {
    onePage,
    pageSize,
    searchValue,
    reset,
    onScroll,
    onSearch,
  } = useAutocomplete({ initialSearch: initialRole?.name });

  // Company active roles
  const {
    data: companyActiveRolesData,
    loading: companyActiveRolesLoading,
  } = useCompanyActiveRolesQuery({
    variables: {
      filter: {
        page: onePage,
        pageSize,
        search: searchValue,
      },
    },
    fetchPolicy: 'no-cache',
    onCompleted({ companyActiveRoles }) {
      if (!initialRole) {
        return;
      }

      const isTouched = form.isFieldTouched('roleId');

      const roles = companyActiveRoles?.items;

      // If roles is empty close modal, refetch and show error
      if (roles?.length === 0) {
        handleRefetch();
        onClose();
        showErrorNotification({
          content: ErrorMessages.RoleClosed,
        });
      }

      // Set initial role id if we have initial role
      // field was not touched(initial load) and after we get active roles
      // To prevent showing role id instead of role name in the select
      if (!isTouched) {
        form.setFieldsValue({ roleId: initialRole.id });
      }
    },
  });

  const activeRoles = companyActiveRolesData?.companyActiveRoles?.items;
  const activeRolesTotal = companyActiveRolesData?.companyActiveRoles?.count;

  const [
    createReferral,
    { loading: createReferralLoading },
  ] = useCreateReferralMutation({
    onCompleted() {
      showSuccessNotification({ content: SuccessMessages.CandidateReferred });
      onClose();

      // Refetch if we have refetch function
      handleRefetch();
    },
    onError({ graphQLErrors }) {
      graphQLErrors.forEach((error) => {
        onClose();

        // Show role closed when try create referral on with closed role
        if (isNotFound(error)) {
          // Refetch if we have refetch function
          handleRefetch();

          return showErrorNotification({
            content: ErrorMessages.RoleClosed,
          });
        }

        return showErrorNotification();
      });
    },
  });

  // Get file from upload but do not upload it
  const handleBeforeUpload = (file: any) => {
    // Acceptable file types
    const fileTypes = ['pdf', 'doc', 'docx'];

    // File type
    const fileType = file.name.split('.').pop();

    // If file type not from the acceptable file types list show error message
    if (!fileTypes.includes(fileType)) {
      showErrorNotification({ content: ErrorMessages.InvalidFileType });
      return false;
    }

    const fileSizeLimitM = 20;
    const fileSizeLimitB = fileSizeLimitM * 1024 * 1024;

    const isLimit = file.size < fileSizeLimitB;

    if (!isLimit) {
      showErrorNotification({ content: ErrorMessages.FileTooLarge });
      return false;
    }

    // Set candidate CV file
    setCandidateCV(file);
    setCandidateCVError(null);

    // Prevent file uploading
    return false;
  };

  // Remove file
  const handleFileRemove = () => setCandidateCV(null);

  const handleCreateReferral = ({
    roleId,
    candidateName,
    candidateEmail,
    candidateLinkedin,
    reason,
    reasonOption,
  }: FormValuesType) => {
    // Do not allow to submit referral without LinkedIn URL or CV file
    if (!candidateLinkedin && !candidateCV) {
      setCandidateCVError(ErrorMessages.CVRequired);
      return false;
    }

    return createReferral({
      variables: {
        input: {
          roleId,
          reason,
          reasonOption,
          candidateName,
          candidateEmail,
          candidateLinkedin,
          cvUpload: candidateCV,
        },
      },
    });
  };

  return (
    <Form
      className="refer-candidate"
      form={form}
      preserve={false}
      onFinish={handleCreateReferral}
    >
      {/* Row */}
      <div className="refer-candidate__row">
        {/* Role */}
        <FormItem
          name="roleId"
          className="refer-candidate__col"
          validateFirst
          rules={[
            {
              required: true,
              whitespace: true,
              message: ErrorMessages.EmptyRole,
            },
          ]}
        >
          <SelectAutocomplete
            placeholder="Role"
            items={uniqSelectOptions(activeRoles, {
              value: 'id',
              label: 'name',
            })}
            total={activeRolesTotal}
            loading={companyActiveRolesLoading}
            onClear={reset}
            onScroll={onScroll}
            onSearch={onSearch}
          />
        </FormItem>

        {/* Candidate name */}
        <FormItem
          name="candidateName"
          className="refer-candidate__col"
          validateFirst
          rules={[
            {
              required: true,
              whitespace: true,
              message: ErrorMessages.EmptyCandidateName,
            },
          ]}
        >
          <Input placeholder="Full Name" />
        </FormItem>
      </div>

      <div className="refer-candidate__row">
        {/* Candidate email */}
        <FormItem
          name="candidateEmail"
          className="refer-candidate__col"
          validateFirst
          rules={[
            {
              type: 'email',
              required: true,
              whitespace: true,
              message: ErrorMessages.InvalidEmail,
            },
          ]}
        >
          <Input placeholder="Email" />
        </FormItem>

        {/* Candidate LinkedIn */}
        <FormItem
          name="candidateLinkedin"
          className="refer-candidate__col"
          validateFirst
          rules={[
            {
              whitespace: true,
              message: ErrorMessages.InvalidCandidateLinkedIn,
            },
            {
              pattern: /^(https:\/\/)?(www\.)?linkedin\.com\/in\/[A-z0-9_-]+\/?/,
              message: ErrorMessages.InvalidCandidateLinkedIn,
            },
          ]}
        >
          <Input
            onChange={() => setCandidateCVError(null)}
            placeholder="LinkedIn Profile"
          />
        </FormItem>
      </div>

      {/* Candidate CV */}
      <div className={classNames({ 'upload-error': !!candidateCVError })}>
        <Dragger
          className="refer-candidate__upload"
          showUploadList={false}
          beforeUpload={handleBeforeUpload}
          iconRender={() => <Spin />}
        >
          {/* eslint-disable */}
          {candidateCV ? (
            <div
              className="refer-candidate__file"
              onClick={(e) => e.stopPropagation()}
            >
              <span className="refer-candidate__file-text">
                {candidateCV.name}
              </span>
              <button
                className="refer-candidate__file-delete"
                onClick={handleFileRemove}
              >
                <DeleteFilled />
              </button>
            </div>
          ) : (
            <div className="refer-candidate__upload-btn">
              Upload the candidate’s CV
            </div>
          )}
          {/* eslint-enable */}
        </Dragger>
        <div className="ant-form-item-explain ant-form-item-explain-error">
          <div role="alert">{candidateCVError}</div>
        </div>
      </div>

      <div className="refer-candidate__reason-row">
        <FormItem name="reasonOption">
          <Select
            placeholder="Relationship to candidate"
            options={ReferralReasonOptions}
            onChange={setReasonOption}
            allowClear
          />
        </FormItem>

        {selectedReasonOption === ReferralReasonOption.Other && (
          <FormItem
            name="reason"
            validateFirst
            rules={[
              {
                required: true,
                whitespace: true,
                message: ErrorMessages.EmptyReferralReasonDetails,
              },
            ]}
          >
            <Input placeholder="Relationship to candidate details" />
          </FormItem>
        )}
      </div>

      {/* Action buttons */}
      <FormItem noStyle shouldUpdate>
        {({ getFieldsError }) => (
          <ModalActions
            onCancel={onClose}
            saveBtnText="Submit"
            saveBtnDisabled={
              !!getFieldsError().filter(({ errors }) => errors.length).length
            }
            saveBtnLoading={createReferralLoading}
          />
        )}
      </FormItem>
    </Form>
  );
};

export default ReferCandidateForm;
