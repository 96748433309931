enum ForbiddenErrorCodes {
  USER_INVALID_ROLE = 'USER_INVALID_ROLE',
  USER_BLOCKED = 'USER_BLOCKED',
  USER_UNVERIFIED_EMAIL = 'USER_UNVERIFIED_EMAIL',
  USER_RESET_PWD_REQUIRED = 'USER_RESET_PWD_REQUIRED',
  COMPANY_NOT_EXISTS = 'COMPANY_NOT_EXISTS',
  COMPANY_BLOCKED = 'COMPANY_BLOCKED',
  COMPANY_SUB_INACTIVE = 'COMPANY_SUB_INACTIVE',
  SECOND_AUTH_FACTOR_IS_REQUIRED = 'SECOND_AUTH_FACTOR_IS_REQUIRED',
}

export { ForbiddenErrorCodes };
