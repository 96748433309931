const blobToUrl = (blob: Blob): Promise<string | null> =>
  new Promise((res, rej) => {
    try {
      res(URL.createObjectURL(blob));
    } catch (error) {
      rej(error);
    }
  });

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url;
  });

const canvasToBlob = (canvas: HTMLCanvasElement): Promise<Blob | null> =>
  new Promise((resolve) => {
    canvas.toBlob(resolve, 'image/jpeg');
  });

type CropOptions = {
  rotation?: number;
  maxWidth?: number;
  maxHeight?: number;
};

const cropImage = async (
  imgUrl: string,
  pixelCrop: {
    width: number;
    height: number;
    x: number;
    y: number;
  },
  { maxWidth = 400, maxHeight = 400 }: CropOptions = {}
): Promise<{ file: Blob | null; url: string }> => {
  const image = await createImage(imgUrl);
  const canvas = document.createElement('canvas');

  const cWidth = pixelCrop.width > maxWidth ? maxWidth : pixelCrop.width;
  const cHeight = pixelCrop.height > maxHeight ? maxHeight : pixelCrop.height;

  canvas.width = cWidth;
  canvas.height = cHeight;

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Invalid canvas context');
  }

  ctx.fillRect(0, 0, canvas.width, canvas.height);
  ctx.drawImage(
    image,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    cWidth,
    cHeight
  );

  const url = canvas.toDataURL('image/jpeg');
  const file = await canvasToBlob(canvas);
  return { file, url };
};

export { blobToUrl, cropImage };
