import React from 'react';
import {
  Redirect,
  Route,
  useLocation,
  RouteComponentProps,
} from 'react-router-dom';
import { useCurrentUserContext } from 'hooks';
import { getForbiddenRedirectUrlByUser, ProtectedOptions, Routes } from 'utils';
import { Preloader } from '../../index';

type Props = {
  path: string;
  exact?: boolean;
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  options?: ProtectedOptions;
};

const ProtectedRoute = ({ path, exact, component, options = {} }: Props) => {
  const defaultOptions: ProtectedOptions = {
    isActive: true,
    isEmailVerified: true,
    isCompanyExists: true,
    isCompanyActive: true,
    isCompanySubActive: true,
    isResetPasswordRequired: true,
    is2FARequired: true,
  };

  const protectOptions = {
    ...defaultOptions,
    ...options,
  };

  const location = useLocation();
  const { user, company, loading, error } = useCurrentUserContext();

  let redirectTo: Routes | null;
  if (loading && !user) {
    return <Preloader show />;
  }

  if (error || !user) {
    redirectTo = Routes.SignIn;
  } else {
    redirectTo = getForbiddenRedirectUrlByUser(protectOptions, user, company);
  }

  const Component = component;
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) =>
        redirectTo ? (
          <Redirect
            to={{
              pathname: redirectTo,
              state: { form: location },
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default ProtectedRoute;
