import React, { useState } from 'react';
import { Form, FormInstance, Input } from 'antd';
import postalCodes from 'postal-codes-js';
import { BillingAddress } from 'api';
import { FormItem, PrimaryButton, Select } from 'components';
import {
  CountryCode,
  supportedCountryOptions,
  ErrorMessages,
  stateOptionsMap,
} from 'utils';

import './billingAddress.less';

type PropTypes = {
  loading?: boolean;
  form: FormInstance;
  onSubmit: (address: any) => any;
  billingAddress?: BillingAddress | null;
  formActions?: React.ReactNode;
};

const BillingAddressForm = ({
  loading,
  form,
  onSubmit,
  billingAddress,
  formActions,
}: PropTypes) => {
  const initialCountry = billingAddress?.country || CountryCode.US;
  const [country, setCountry] = useState<string | null | undefined>(
    initialCountry
  );

  const stateOptions = country ? (stateOptionsMap as any)[country] : null;
  const onCountryChanged = (value: any) => {
    setCountry(value);
    form.setFieldsValue({ state: undefined, zip: undefined });
  };

  return (
    <Form form={form} onFinish={onSubmit} className="billing-address">
      <div className="billing-address__country-row">
        <FormItem
          name="country"
          validateFirst
          initialValue={initialCountry}
          rules={[
            {
              required: true,
              message: ErrorMessages.EmptyCountry,
            },
          ]}
        >
          <Select
            allowClear
            placeholder="Select country"
            options={supportedCountryOptions}
            onChange={onCountryChanged}
          />
        </FormItem>
      </div>

      <div>
        <FormItem
          name="street"
          validateFirst
          initialValue={billingAddress?.street}
        >
          <Input placeholder="Address" />
        </FormItem>
      </div>

      <div className="billing-address__triple-row">
        <FormItem
          name="city"
          className="city-field"
          validateFirst
          initialValue={billingAddress?.city}
        >
          <Input placeholder="City" />
        </FormItem>
        <FormItem
          name="state"
          validateFirst
          initialValue={billingAddress?.state}
          rules={[{ required: true, message: ErrorMessages.EmptyState }]}
        >
          <Select
            allowClear
            showSearch
            placeholder="State / Province"
            options={stateOptions}
            disabled={!stateOptions?.length}
            filterOption={(input, opts) =>
              opts?.value?.toLowerCase()?.startsWith(input.toLowerCase())
            }
          />
        </FormItem>
        <FormItem
          name="zip"
          initialValue={billingAddress?.zip}
          validateFirst
          className="zip-code-input"
          rules={[
            { required: true, message: ErrorMessages.EmptyZipCode },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                // Validate country zip code
                const selectedCountry = getFieldValue('country');
                const isValidZip = postalCodes.validate(selectedCountry, value);

                if (isValidZip !== true) {
                  return Promise.reject(
                    new Error(ErrorMessages.InvalidZipCode)
                  );
                }

                return Promise.resolve();
              },
            }),
            {
              max: 18,
              message: ErrorMessages.InvalidZipCode,
            },
          ]}
        >
          <Input placeholder="Zip / Postal code" disabled={!country} />
        </FormItem>
      </div>
      {formActions || (
        <PrimaryButton htmlType="submit" loading={loading}>
          Update
        </PrimaryButton>
      )}
    </Form>
  );
};

export default BillingAddressForm;
