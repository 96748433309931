import { Menu, MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { Routes } from 'utils';
import { useLogout } from 'hooks';

const { Item: MenuItem } = Menu;

const UserMenu = (props: MenuProps) => {
  const logout = useLogout();

  return (
    <Menu {...props}>
      <MenuItem key="0">
        <Link to={Routes.ProfileSettings}>Profile</Link>
      </MenuItem>
      <MenuItem key="1" onClick={logout}>
        Logout
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;
