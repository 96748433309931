import { Form, Input, Switch } from 'antd';
import { useForm } from 'hooks';
import { FormItem, ModalActions } from 'components';
import { DepartmentsStatus, useCreateDepartmentMutation } from 'api';
import {
  ErrorMessages,
  showErrorNotification,
  isDepartmentNameTakenError,
} from 'utils';

import './addDepartmentForm.less';

type PropsType = {
  onComplete?: () => void;
  onCancel?: () => void;
};

const AddDepartmentForm = ({ onComplete, onCancel }: PropsType) => {
  const [form] = useForm();

  const [createDepartment, { loading }] = useCreateDepartmentMutation({
    onCompleted() {
      if (onComplete) {
        onComplete();
      }
    },
    onError({ graphQLErrors }) {
      graphQLErrors.forEach((error) => {
        if (isDepartmentNameTakenError(error)) {
          const name = form.getFieldValue('name');
          return form.setFields([
            {
              name: 'name',
              errors: [`${name} already exists. Please update the name.`],
            },
          ]);
        }

        return showErrorNotification();
      });
    },
  });

  const handleCreateRole = ({
    name,
    isActive = true,
  }: {
    name: string;
    isActive?: boolean;
  }) =>
    createDepartment({
      variables: {
        input: {
          name: name.trim(),
          status: isActive
            ? DepartmentsStatus.Active
            : DepartmentsStatus.Closed,
        },
      },
    });

  return (
    <Form
      className="departments-form"
      form={form}
      initialValues={{ isActive: true }}
      onFinish={handleCreateRole}
    >
      <div className="departments-form__status">
        <FormItem
          noStyle
          shouldUpdate={(prev, curr) => prev.isActive !== curr.isActive}
        >
          {({ getFieldValue }) => {
            const isActive = getFieldValue('isActive');

            return isActive ? 'Active' : 'Inactive';
          }}
        </FormItem>
        <FormItem
          name="isActive"
          valuePropName="checked"
          className="departments-form__status-switch"
        >
          <Switch defaultChecked />
        </FormItem>
      </div>

      <FormItem
        name="name"
        validateFirst
        rules={[
          {
            required: true,
            whitespace: true,
            message: ErrorMessages.EmptyDepartmentName,
          },
          {
            pattern: /^[a-z\d\s()\-_,.:;'/+*&]+$/i,
            message: ErrorMessages.InvalidDepartmentName,
          },
          {
            max: 120,
            message: ErrorMessages.InvalidDepartmentName,
          },
        ]}
      >
        <Input placeholder="Department name" />
      </FormItem>

      {/* Action buttons */}
      <FormItem noStyle shouldUpdate>
        {({ getFieldsError }) => (
          <ModalActions
            onCancel={onCancel}
            saveBtnText="Save Changes"
            saveBtnDisabled={
              !!getFieldsError().filter(({ errors }) => errors.length).length
            }
            saveBtnLoading={loading}
          />
        )}
      </FormItem>
    </Form>
  );
};

export default AddDepartmentForm;
