import { ReactNode } from 'react';
import classNames from 'classnames';

import './homeTitle.less';

type PropsType = {
  children: ReactNode;
  className?: string;
};

const HomeTitle = ({ children, className }: PropsType) => (
  <h1 className={classNames('home-title', className)}>{children}</h1>
);

export default HomeTitle;
