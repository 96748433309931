enum ErrorMessages {
  AccessDenied = 'Access Denied',
  UnexpectedError = 'Unexpected Error. Please try later',
  EmailInUse = 'That email address is already in use',
  EnterWorkEmail = 'Please enter your work email address',
  UseWorkEmail = 'Please use your work email address',
  UnexpectedSignInError = 'Failed to sign in. Please try later',
  UnexpectedSignUpError = 'Failed to sign up. Please try later',
  EmptyCompanyName = 'Please enter company name',
  CompanyNameTooLong = 'Company Name cannot be longer than 100 characters',
  InvalidAmount = 'Please enter a valid amount',
  InvalidConfirmationCode = 'Invalid code. Please re-enter a valid code',
  InvalidEmail = 'Please enter a valid email',
  InvalidPassword = 'Please enter a valid password',
  WrongPassword = 'The old password you’ve provided is incorrect',
  InvalidEmailOrPassword = 'Invalid email or password',
  InvalidMobilePhone = 'Invalid mobile phone format',
  PasswordToShort = 'Password must be at least 8 characters',
  PasswordToLong = 'Password cannot be longer than 128 characters',
  PasswordToWeak = 'Password is too weak. It should contain at least one lowercase, one uppercase, one number and must be at least 8 characters',
  EnterYourName = 'Please enter your name',
  NameTooLong = 'Name cannot be longer than 255 characters',
  EmptyPassword = 'Please enter a password',
  EmptyOldPassword = 'Please enter your current password',
  EmptyConfirmPassword = 'Confirm password is required',
  ConfirmPasswordMismatch = 'Passwords don’t match',
  DefaultErrorMessage = 'An error has occurred! Please refresh the page and try again',
  EmailNotFount = 'Email not found',
  CompanyAlreadyExists = 'An account for DOMAIN already exists. Please contact support@hireaction.com',
  ShippingAddressToLong = 'Shipping address cannot be longer than 1000 characters',
  EmptyRoleName = 'Please enter role name',
  EmptyDepartmentName = 'Please enter department name',
  EmptyPointsPerReferral = 'Please enter points per referral',
  EmptyReferralReasonDetails = 'Please enter a relationship to candidate details',
  EmptyPointsForHire = 'Please enter points for hire',
  EmptyJobDescription = 'Please enter job description link',
  InvalidAvatar = 'Invalid avatar',
  AvatarToLarge = 'Avatar is too large. Please upload a photo under 2MB',
  EmptyCandidateName = 'Please enter candidate name',
  InvalidCandidateLinkedIn = 'Please provide a valid URL',
  EmptyRole = 'Please choose role',
  RoleClosed = 'The role was closed',
  RoleNameTaken = 'There is an open role with the same name',
  ChooseStatus = 'Please choose status',
  StripeCreditCardError = 'Failed to updated payment information. Please try later',
  StripePrepaidCardUsageError = 'Prepaid cards are not allowed, please enter a valid credit or debit card',
  InvalidCardNumber = 'Invalid card number',
  InvalidCardExpiry = 'Invalid expiration date',
  InvalidCardCVC = 'Invalid CVC',
  InvalidFileType = 'Please upload pdf or doc/docx file',
  InvalidRoleName = 'Please enter valid role name',
  InvalidDepartmentName = 'Please enter valid department name',
  RoleNameTooLong = 'Role name cannot be longer than 120 characters',
  JobDescriptionLinkTooLong = 'Job description link cannot be longer than 2048 characters',
  PointsNotEnough = 'You do not have enough points',
  EmptyUserName = "Please enter user's full name",
  EmptyCountry = 'Country is required',
  EmptyState = 'State is required',
  EmptyCity = 'City is required',
  CityNameTooLong = 'City name cannot be longer than 200 characters',
  EmptyStreet = 'Street address is required',
  StreetAddressTooLong = 'Street address cannot be longer than 200 characters',
  ApartmentsTooLong = 'Apartments cannot be longer than 6 characters',
  EmptyZipCode = 'Zip code is required',
  InvalidZipCode = 'Invalid zip code',
  InvalidCountry = 'Invalid country',
  StripeBillingError = 'Billing error. Please contact your administrator or send us a note to support@hireaction.com',
  ActivateSubscription = 'Please add a valid credit card before activating your subscription',
  PasswordAlreadyUsed = 'The new password cannot be the same as the old password',
  InvalidToken = 'An error has occurred! Please try again',
  InvitationTokenNotFound = 'Invitation code not found! Please check your email or contact support@hireaction.com',
  EmptyRewardWaitPeriod = 'Please enter the number of days',
  MinRewardWaitPeriod = 'Min number of days can’t be less than 0 days',
  MaxRewardWaitPeriod = 'Max number of days can’t be more than 365 days',
  EmptyRejectReason = 'Please enter rejection reason',
  RejectReasonTooLong = 'Reject reason cannot be longer than 255 characters',
  EmptyGiftPrice = 'Please choose the amount',
  FileTooLarge = 'The file you’re uploading must not be larger than 20 MB',
  EmptyHiringRep = 'Please choose hiring rep',
  CaptchaVerificationError = 'Captcha verification failed. Please, try again',
  CVRequired = "Please upload the candidate's CV or enter their LinkedIn profile url",
  InvalidOtpCode = 'Invalid code',
  InvalidVerificationCode = 'Invalid verification code',
  VerificationCodeIsRequired = 'Verification code is required',
  AtsError = 'Your applicant tracking system failed to process the operation. Please retry',
  AtsUnprocessableRole = 'This role appears to be inactive in your applicant tracking system so we’re unable to complete the operation',
  AtsUnprocessableReferral = 'This referral appears to be inactive in your applicant tracking system so we’re unable to complete the operation',
  GHEmptySecret = 'Harvest API key is required',
  GHEmptyJBToken = 'Job board token is required',
  GHInvalidSecret = 'Invalid Harvest API key',
  GHInvalidOnBehalfOf = 'Invalid Greenhouse user id or email',
  GHEmptyOnBehalfOf = 'Greenhouse user id or email is required',
  ValueIsTooLong = 'Value is too long.',
  IntegrationInstalled = 'Integration already installed',
  InvalidTaxLocation = 'Invalid tax location. Please provide a valid billing address',
  RemoveLast2FAMethod = `Removing the last 2FA method isn't allowed`,
  InvalidOAuthService = 'Invalid OAuth service',
  FailedToSetupCompanyIntegration = 'Failed to setup company integration',
  OAuthCallbackError = 'Authorization error. Please contact the support',
}

export { ErrorMessages };
