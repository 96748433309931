export type TokensPair = {
  accessToken: string;
  refreshToken: string;
};

const getAccessToken = (): TokensPair['accessToken'] =>
  localStorage.getItem('accessToken') || '';

const setAccessToken = (accessToken: TokensPair['accessToken']): void => {
  localStorage.setItem('accessToken', accessToken || '');
};

const getRefreshToken = (): TokensPair['refreshToken'] =>
  localStorage.getItem('refreshToken') || '';

const setRefreshToken = (refreshToken: TokensPair['refreshToken']): void => {
  localStorage.setItem('refreshToken', refreshToken || '');
};

const setTokensPair = (tokensPair: TokensPair): void => {
  setAccessToken(tokensPair.accessToken);
  setRefreshToken(tokensPair.refreshToken);
};

const getTokensPair = (): TokensPair => ({
  accessToken: getAccessToken(),
  refreshToken: getRefreshToken(),
});

export {
  getAccessToken,
  setAccessToken,
  getRefreshToken,
  setRefreshToken,
  setTokensPair,
  getTokensPair,
};
