import { Space } from 'antd';
import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { useBreakpoint } from 'hooks';

import './actionHeader.less';

type PropsType = {
  title?: string | ReactNode;
  left?: ReactNode;
  right?: ReactNode;
  className?: string;
  menu?: ReactNode;
  children?: ReactNode;
};

const ActionHeader = ({
  left,
  right,
  title,
  menu,
  className,
  children,
}: PropsType) => {
  const { md } = useBreakpoint();

  return (
    <div
      className={classNames('action-header-wrapper', className, {
        'action-header-wrapper-with-menu': !!menu,
      })}
    >
      <div className="action-header">
        {/* Title */}
        {title && <div className="action-header__title">{title}</div>}

        {/* Action section */}
        {(left || right) && (
          <div className="action-header__actions">
            {/* Left section */}
            {left && (
              <Space
                className="action-header__left"
                direction={md ? 'horizontal' : 'vertical'}
                wrap={md}
              >
                {left}
              </Space>
            )}

            {/* Right section */}
            {right && <Space className="action-header__right">{right}</Space>}
          </div>
        )}
      </div>
      {!!menu && <div className="action-header-wrapper__menu">{menu}</div>}
      {!!children && children}
    </div>
  );
};

export default ActionHeader;
