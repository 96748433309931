import moment, { Moment } from 'moment';

const defaultDateFormat = (date: Date) => moment(date).format('MMMM DD, YYYY');

const transformDay = (date: Moment, dayPeriod: 'start' | 'end') => {
  if (dayPeriod === 'start') {
    return date.clone().utc().set({ hour: 0, minute: 0, second: 0 });
  }

  return date.clone().utc().set({ hour: 23, minute: 59, second: 59 });
};

const durationInDaysBetweenDates = (startDate: Moment, endDate: Moment) =>
  Math.ceil(endDate.diff(startDate, 'hours') / 24);

export { defaultDateFormat, transformDay, durationInDaysBetweenDates };
