import { Form } from 'antd';
import { Link } from 'react-router-dom';
import {
  GiftType,
  UserRole,
  GiftFragment,
  UserPointsBalanceDocument,
  useExchangePointsForGiftMutation,
} from 'api';
import {
  Routes,
  ErrorMessages,
  SuccessMessages,
  transformNumber,
  isPointsNotEnough,
  transformToCurrency,
  showErrorNotification,
  showSuccessNotification,
} from 'utils';
import { FormItem, Modal, ModalActions, Select } from 'components';
import { useCurrentUserContext, useUserPointsContext } from 'hooks';

import './redeemGiftModal.less';

type PropsType = {
  isVisible?: boolean;
  gift?: GiftFragment;
  toggleVisible: () => void;
};

enum GiftsTypeText {
  VIRTUAL = 'Virtual',
  PHYSICAL = 'Physical',
}

const RedeemGiftModal = ({ isVisible, gift, toggleVisible }: PropsType) => {
  const { user } = useCurrentUserContext();
  const { userPoints } = useUserPointsContext();

  const { address, name: userName, role: userRole } = user || {};

  const {
    type,
    prices,
    imageUrl,
    description,
    name: giftName,
    id: giftId = '',
  } = gift || {};

  const priceOptions = prices?.map(({ valueInPoints, id, value }) => ({
    label: `${transformNumber(valueInPoints)} points ($${transformToCurrency(
      value
    )})`,
    value: id,
  }));

  const [
    redeemGift,
    { loading: redeemPrizeLoading },
  ] = useExchangePointsForGiftMutation({
    onCompleted() {
      toggleVisible();
      showSuccessNotification({ content: SuccessMessages.ChangesSaved });
    },
    onError({ graphQLErrors }) {
      graphQLErrors.forEach((error) => {
        toggleVisible();

        if (isPointsNotEnough(error)) {
          return showErrorNotification({
            content: ErrorMessages.PointsNotEnough,
          });
        }

        return showErrorNotification();
      });
    },
    refetchQueries: [{ query: UserPointsBalanceDocument }],
  });

  const btnDisabled = type === GiftType.Physical && !address;

  const handleRedeemPrize = ({ giftPriceId }: { giftPriceId: string }) =>
    redeemGift({ variables: { giftId, giftPriceId } });

  return (
    <Modal
      style={{ top: 80 }}
      className="redeem-gift"
      title={userRole === UserRole.User ? 'Redeem Gift' : 'Gift information'}
      visible={isVisible}
      onCancel={toggleVisible}
      centered={false}
    >
      <Form
        className="redeem-gift__content"
        preserve={false}
        onFinish={handleRedeemPrize}
      >
        <div className="redeem-gift__row">
          {/* Gift image */}
          <div className="redeem-gift__image">
            <img src={imageUrl} alt={giftName} className="redeem-gift__img" />
          </div>

          {/* Gift name and description */}
          <div className="redeem-gift__text">
            <div className="redeem-gift__name">{giftName}</div>
            <div className="redeem-gift__description">{description}</div>
          </div>
        </div>

        {/* Gift info with select and action buttons for user */}
        {userRole === UserRole.User && (
          <>
            {/* Gift info */}
            <div className="redeem-gift__info">
              {/* Gift value in points and dollars */}
              <FormItem
                name="giftPriceId"
                validateFirst
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: ErrorMessages.EmptyGiftPrice,
                  },
                  {
                    validator: (_, value) => {
                      const selectedPrice = prices?.find(
                        ({ id }) => id === value
                      );

                      return selectedPrice &&
                        userPoints >= selectedPrice.valueInPoints
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error(ErrorMessages.PointsNotEnough)
                          );
                    },
                  },
                ]}
              >
                <Select
                  placeholder="Choose an Amount..."
                  options={priceOptions}
                  allowClear
                />
              </FormItem>

              {/* Gift type */}
              <div className="redeem-gift__info-text">
                Gift Type: {type ? GiftsTypeText[type] : '-'}
              </div>

              {/* Gift shipping address */}
              {type === GiftType.Physical && (
                <div className="redeem-gift__info-text">
                  Shipping address:{' '}
                  {address ? (
                    `${userName} ${address.street}, ${address.city}, ${address.state}, ${address.zip}`
                  ) : (
                    <span>
                      Please update your shipping address in{' '}
                      <Link to={Routes.ProfileSettings}>your profile</Link>{' '}
                      prior to redeeming a physical gift.
                    </span>
                  )}
                </div>
              )}
            </div>

            {/* Action buttons */}
            <FormItem noStyle shouldUpdate>
              {({ getFieldError }) => (
                <ModalActions
                  onCancel={toggleVisible}
                  saveBtnText="Confirm"
                  saveBtnDisabled={
                    btnDisabled || !!getFieldError('giftPriceId').length
                  }
                  saveBtnLoading={redeemPrizeLoading}
                />
              )}
            </FormItem>

            {/* Gift hint text */}
            {type === GiftType.Physical ? (
              <div className="redeem-gift__hint">
                We will send you an email confirmation with the shipping and
                tracking information once we ship your gift.
              </div>
            ) : (
              <div className="redeem-gift__hint">
                We will send you an email confirmation with information on how
                to access your gift.
              </div>
            )}
          </>
        )}

        {/* Gift info with prices for HR/WA */}
        {userRole !== UserRole.User && (
          <div className="redeem-gift__info">
            {/* Gift value in points and dollars */}
            <div>Gift Prices:</div>
            <ul className="redeem-gift__info-text">
              {priceOptions?.map(({ value, label }) => (
                <li key={value}>{label}</li>
              ))}
            </ul>

            {/* Gift type */}
            <div className="redeem-gift__info-text">
              Gift Type: {type ? GiftsTypeText[type] : '-'}
            </div>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default RedeemGiftModal;
