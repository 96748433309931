import { useBreakpoint } from 'hooks';
import { Container } from 'components';

import './upperHeader.less';

const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL as string;

const UpperHeader = () => {
  const { lg } = useBreakpoint();

  return lg ? (
    <div className="upper-header">
      <Container className="upper-header__container">
        <a href={`${WEBSITE_URL}/contact-us`} className="upper-header__link">
          Support
        </a>
      </Container>
    </div>
  ) : null;
};

export default UpperHeader;
