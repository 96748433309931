import { useEffect, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Input, Space } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';

import './tableFilterDropdown.less';

type PropTypes = FilterDropdownProps & {
  onFilter: (data: any) => any;
  placeholder?: string;
};

const TableFilterDropdown = ({
  confirm,
  visible,
  onFilter,
  placeholder = 'Search...',
}: PropTypes) => {
  const formRef = useRef<FormInstance | null>(null);
  const filterRef = useRef<Input | null>(null);

  useEffect(() => {
    const id = setTimeout(() => {
      // Focus on the search input after the filter is opened
      if (visible && filterRef.current) {
        filterRef.current.focus();
      }
    }, 100);
    return () => clearInterval(id);
  }, [visible]);

  const handleSubmit = (data: any) => {
    confirm({ closeDropdown: true });
    onFilter(data.filter);
  };

  const handleReset = () => {
    formRef.current?.resetFields(['filter']);
    formRef.current?.submit();
  };

  return (
    <div className="table-filter-dropdown">
      <Form ref={formRef} onFinish={handleSubmit}>
        <Form.Item name="filter">
          <Input placeholder={placeholder} ref={filterRef} />
        </Form.Item>

        <Space className="table-filter-dropdown__footer">
          <Button type="primary" htmlType="submit" icon={<SearchOutlined />}>
            Search
          </Button>
          <Button onClick={handleReset}>Reset</Button>
        </Space>
      </Form>
    </div>
  );
};

export default TableFilterDropdown;
