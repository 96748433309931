import './termsAndConditions.less';

const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL as string;

const TermsAndConditions = () => (
  <p className="terms__description">
    By clicking the &quot;Sign Up&quot; button, you are creating a HireAction
    account, and you agree to HireAction&apos;s{' '}
    <a className="terms__link" href={`${WEBSITE_URL}/terms-of-service/`}>
      Terms of Use
    </a>
    <> and </>
    <a className="terms__link" href={`${WEBSITE_URL}/privacy-policy/`}>
      Privacy Policy
    </a>
    .
  </p>
);

export default TermsAndConditions;
