import { GraphQLError } from 'graphql';
import { AtsErrorCodes, ErrorCodes, StripeErrorCodes } from 'utils';

/**
 * Check if GraphQLError with specified code
 */
const isGqlErrorCode = (error: GraphQLError, code: string): boolean =>
  error?.extensions?.code === code;

const isGqlExceptionErrorCode = (error: GraphQLError, code: string): boolean =>
  error?.extensions?.exception?.exceptionCode === code;

const isEmailTakenError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.EMAIL_TAKEN);

const isBadUserInputError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.BAD_USER_INPUT);

const isUnacceptableEmailDomain = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.UNACCEPTABLE_EMAIL_DOMAIN);

const isNotFound = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.NOT_FOUND);

const isCompanyAlreadyExists = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.COMPANY_ALREADY_EXISTS);

const isOpenRoleNameTaken = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.OPEN_ROLE_NAME_TAKEN);

const isPointsNotEnough = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.POINTS_NOT_ENOUGH);

const isStripeError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.STRIPE_ERROR);

const isStripePrepaidCardUsageError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.STRIPE_PREPAID_CARD_USAGE_ERROR);

const isStripeTaxLocationError = (error: GraphQLError): boolean => {
  if (isStripeError(error)) {
    const stripeErrorCode = error.extensions?.exception?.code;
    if (stripeErrorCode === StripeErrorCodes.customer_tax_location_invalid) {
      return true;
    }
  }
  return false;
};

const isPasswordAlreadyUsed = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.PASSWORD_ALREADY_USED);

const isForbiddenError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.FORBIDDEN);

const isCaptchaError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.GOOGLE_CAPTCHA_ERROR);

const isInvalidOtpCode = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.INVALID_OTP_CODE);

const isAtsError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.ATS_ERROR);

const isAtsUnauthorizedError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.ATS_ERROR) &&
  isGqlExceptionErrorCode(error, AtsErrorCodes.UNAUTHORIZED_ERROR);

const isAtsUserNotFoundError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.ATS_ERROR) &&
  isGqlExceptionErrorCode(error, AtsErrorCodes.USER_NOT_FOUND);

const isAtsReferralNotFoundError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.ATS_ERROR) &&
  isGqlExceptionErrorCode(error, AtsErrorCodes.REFERRAL_NOT_FOUND);

const isAtsRoleNotFoundError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.ATS_ERROR) &&
  isGqlExceptionErrorCode(error, AtsErrorCodes.ROLE_NOT_FOUND);

const isAtsUnprocessableEntityError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.ATS_ERROR) &&
  isGqlExceptionErrorCode(error, AtsErrorCodes.UNPROCESSABLE_ENTITY);

const isUniqueViolationError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.UNIQUE_VIOLATION);

const isDepartmentNameTakenError = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.DEPARTMENT_NAME_TAKEN);

const isDeactivatingDepartmentsWithRolesError = (
  error: GraphQLError
): boolean =>
  isGqlErrorCode(error, ErrorCodes.DEACTIVATE_DEPARTMENT_WITH_ROLES);

const isInactiveDepartment = (error: GraphQLError): boolean =>
  isGqlErrorCode(error, ErrorCodes.INACTIVE_DEPARTMENT);

export {
  isNotFound,
  isStripeError,
  isStripePrepaidCardUsageError,
  isGqlErrorCode,
  isEmailTakenError,
  isPointsNotEnough,
  isOpenRoleNameTaken,
  isBadUserInputError,
  isPasswordAlreadyUsed,
  isCompanyAlreadyExists,
  isUnacceptableEmailDomain,
  isForbiddenError,
  isCaptchaError,
  isInvalidOtpCode,
  isUniqueViolationError,
  isStripeTaxLocationError,
  isDepartmentNameTakenError,
  isDeactivatingDepartmentsWithRolesError,
  isInactiveDepartment,
  // ATS
  isAtsError,
  isAtsUnauthorizedError,
  isAtsUserNotFoundError,
  isAtsReferralNotFoundError,
  isAtsRoleNotFoundError,
  isAtsUnprocessableEntityError,
};
