import { UserFragment } from 'api';

const getUserConfigured2FAMethod = (user?: UserFragment) => {
  const methods = user?.user2FAMethodItems || [];
  return methods.filter((m) => m.isConfigured);
};

const getUserPreferred2FAMethod = (user?: UserFragment) =>
  user?.user2FAMethodItems?.find((m) => m.isPreferred && m.isConfigured);

export { getUserConfigured2FAMethod, getUserPreferred2FAMethod };
