import moment from 'moment';
import { useMemo } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { useCurrentUserContext } from 'hooks';
import { durationInDaysBetweenDates, isSubscriptionTrial, Routes } from 'utils';
import { useCompanyStripeCustomerQuery, UserRole } from 'api';

import './trialBanner.less';

type PropsType = {
  className?: string;
};

const TrialBanner = ({ className }: PropsType) => {
  const { company, user } = useCurrentUserContext();

  const {
    data: stripeCustomerData,
    loading: stripeCustomerLoading,
  } = useCompanyStripeCustomerQuery({
    skip: user?.role !== UserRole.WorkspaceAdmin,
  });

  const customerCreditCard =
    stripeCustomerData?.companyStripeCustomer?.creditCard;

  const trialDaysLeft = durationInDaysBetweenDates(
    moment(), // today
    moment(company?.trialExpirationDate)
  );

  const trialText = useMemo(() => {
    if (trialDaysLeft <= 0) {
      return 'Your trial period is over';
    }

    if (trialDaysLeft === 1) {
      return 'Your free trial ends today';
    }

    return `Your free trial ends in ${trialDaysLeft} days`;
  }, [trialDaysLeft]);

  return user?.role !== UserRole.User && isSubscriptionTrial(company) ? (
    <div className={classNames('trial-banner', className)}>
      {trialText}

      {/* Link to billing */}
      {user?.role === UserRole.WorkspaceAdmin &&
        !stripeCustomerLoading &&
        !customerCreditCard && (
          <div className="trial-banner__link">
            <Link to={Routes.CompanyBilling}>
              Add payment information to enable rewards!
            </Link>
          </div>
        )}
    </div>
  ) : null;
};

export default TrialBanner;
