import { CompanyFragment } from 'api';
import { getSubscriptionStatus, SubscriptionStatusNames } from 'utils';

// Check if company subscription is equal to passed subscription status name
const isSubscriptionStatus = (
  statusName: SubscriptionStatusNames,
  company?: CompanyFragment | null
): boolean => {
  if (!company) {
    return false;
  }

  return getSubscriptionStatus(company) === statusName;
};

const isSubscriptionTrial = (company?: CompanyFragment | null): boolean =>
  isSubscriptionStatus(SubscriptionStatusNames.Trial, company);

const isSubscriptionActive = (company?: CompanyFragment | null): boolean =>
  isSubscriptionStatus(SubscriptionStatusNames.Active, company);

const isSubscriptionInactive = (company?: CompanyFragment | null): boolean =>
  isSubscriptionStatus(SubscriptionStatusNames.Inactive, company);

export {
  isSubscriptionTrial,
  isSubscriptionStatus,
  isSubscriptionActive,
  isSubscriptionInactive,
};
