import { Spin } from 'antd';

import './preloader.less';

type PropsType = {
  show?: boolean;
};

const Preloader = ({ show }: PropsType) =>
  show ? (
    <Spin className="preloader" size="large" data-testid="preloader" />
  ) : null;

export default Preloader;
