import React from 'react';
import { Button, Result } from 'antd';
import { useLogout } from 'hooks';

import './blocked.less';

type PropsTypes = {
  title: string | React.ReactNode;
};

const Blocked = ({ title }: PropsTypes) => {
  const logout = useLogout();

  return (
    <div className="blocked">
      <Result
        status="403"
        title={title}
        extra={
          <Button onClick={logout} type="primary">
            Back
          </Button>
        }
      />
    </div>
  );
};

export default Blocked;
