import { GraphQLError } from 'graphql';
import { UserFragment, CompanyFragment, UserRole } from 'api';
import { ForbiddenErrorCodes, Routes } from '../constants';
import { jwtDecodeSafely, getAccessToken } from '../tokensPair';

export type ProtectedOptions = {
  roles?: UserFragment['role'][];
  isActive?: boolean;
  isEmailVerified?: boolean;
  isCompanyExists?: boolean;
  isCompanyActive?: boolean;
  isCompanySubActive?: boolean;
  isResetPasswordRequired?: boolean;
  is2FARequired?: boolean;
};

const getForbiddenRedirectUrlByUser = (
  protectOptions: ProtectedOptions,
  user?: UserFragment | null,
  company?: CompanyFragment | null
) => {
  if (!user) {
    return Routes.SignIn;
  }

  const tokenPayload: any = jwtDecodeSafely(getAccessToken());

  // Redirect to email verification page
  if (protectOptions.isEmailVerified && !user.isEmailVerified) {
    return Routes.EmailVerification;
  }

  // If 2FA is required
  if (protectOptions.is2FARequired) {
    // If 2FA is required by a company and user hasn't configured it yet - redirect to setup 2FA page
    if (company?.is2FARequired && !user.is2FAConfigured) {
      return Routes.Setup2FA;
    }

    // If user configured 2FA and hasn't verify second factor yet - redirect to 2FA verify page
    if (tokenPayload?.is2FARequired) {
      return Routes.TwoFactorAuthentication;
    }
  }

  // Redirect to sign in on invalid roles
  if (
    protectOptions.roles?.length &&
    !protectOptions.roles.includes(user.role)
  ) {
    return Routes.SignIn;
  }

  // Redirect to sign in on invalid roles
  if (protectOptions.isResetPasswordRequired && user.isResetPasswordRequired) {
    return Routes.SignIn;
  }

  // Redirect to user blocked page
  if (protectOptions.isActive && !user.isActive) {
    return Routes.UserBlocked;
  }

  // Redirect if company doesn't exists
  if (protectOptions.isCompanyExists) {
    if (!company) {
      // Redirect WA to company creation page
      if (user.role === UserRole.WorkspaceAdmin) {
        return Routes.CreateCompany;
      }
      // Redirect to company blocked page
      return Routes.CompanyBlocked;
    }

    // Redirect if company is not active/blocked
    if (protectOptions.isCompanyActive && !company?.isActive) {
      return Routes.CompanyBlocked;
    }

    // Redirect if company sub status is not active
    if (protectOptions.isCompanySubActive && !company.isSubActive) {
      // Redirect WA to company billing page
      if (user.role === UserRole.WorkspaceAdmin) {
        return Routes.CompanyBilling;
      }
      // Redirect to company blocked page
      return Routes.CompanyBlocked;
    }
  }

  return null;
};

const getForbiddenRedirectUrlByForbiddenCode = (
  forbiddenError: GraphQLError,
  user?: Pick<UserFragment, 'role'> | null
) => {
  if (!user) {
    return Routes.SignIn;
  }

  const forbiddenCode = forbiddenError?.extensions?.forbiddenCode;

  // Redirect to user blocked page
  if (forbiddenCode === ForbiddenErrorCodes.USER_BLOCKED) {
    return Routes.UserBlocked;
  }

  // Redirect to user 2FA verification
  if (forbiddenCode === ForbiddenErrorCodes.SECOND_AUTH_FACTOR_IS_REQUIRED) {
    return Routes.TwoFactorAuthentication;
  }

  // Redirect to email verification page
  if (forbiddenCode === ForbiddenErrorCodes.USER_UNVERIFIED_EMAIL) {
    return Routes.EmailVerification;
  }

  // Redirect if company doesn't exists
  if (forbiddenCode === ForbiddenErrorCodes.COMPANY_NOT_EXISTS) {
    if (user.role === UserRole.WorkspaceAdmin) {
      return Routes.CreateCompany;
    }
    return Routes.CompanyBlocked;
  }

  // Redirect if company is not active/blocked
  if (forbiddenCode === ForbiddenErrorCodes.COMPANY_BLOCKED) {
    return Routes.CompanyBlocked;
  }

  // Redirect if company sub status is not active
  if (forbiddenCode === ForbiddenErrorCodes.COMPANY_SUB_INACTIVE) {
    if (user.role === UserRole.WorkspaceAdmin) {
      return Routes.CompanyBilling;
    }
    return Routes.CompanyBlocked;
  }

  return Routes.SignIn;
};

export {
  getForbiddenRedirectUrlByUser,
  getForbiddenRedirectUrlByForbiddenCode,
};
