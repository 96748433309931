import classNames from 'classnames';
import { useCurrentUserContext } from 'hooks';

import './userCompany.less';

type PropsType = {
  className?: string;
};

const UserCompany = ({ className }: PropsType) => {
  const { company } = useCurrentUserContext();

  const companyName = company?.name;

  return (
    <div className={classNames('user-company', className)}>
      <span className="user-company__company">{companyName}</span>
    </div>
  );
};

export default UserCompany;
