import React from 'react';
import classNames from 'classnames';
import { SelectProps, Spin } from 'antd';
import { usePrev } from 'hooks';
import { Select, Option } from 'components';

import './selectAutocomplete.less';

type onScrollArgsT = {
  event: { target: any };
  loading?: boolean;
  isEnd: boolean;
};

type PropsType = SelectProps<any> & {
  total?: number;
  loading?: boolean;
  items?: { value: string; label?: string | null }[];
  onScroll: ({ event, loading, isEnd }: onScrollArgsT) => void;
};

const SelectAutocomplete = ({
  total,
  items,
  loading,
  onScroll,
  className,
  ...props
}: PropsType) => {
  const prevInfo = usePrev(items);
  const itemsOptions = items || prevInfo || [];
  const isEnd = itemsOptions.length === total;

  const handleScroll = (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    onScroll({
      event,
      isEnd,
      loading,
    });
  };

  return (
    <Select
      filterOption={false}
      onPopupScroll={handleScroll}
      allowClear
      showSearch
      loading={loading}
      className={classNames('select-autocomplete', className)}
      {...props}
    >
      {itemsOptions.map(({ value, label }) => (
        <Option key={value} value={value}>
          {label}
        </Option>
      ))}
      {loading && (
        <Option
          className="select-autocomplete__loading"
          key="loading"
          value="loading"
          disabled
        >
          <Spin />
        </Option>
      )}
    </Select>
  );
};

export default SelectAutocomplete;
