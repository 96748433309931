import { useState } from 'react';

const useSort = () => {
  const [sortBy, setSortBy] = useState<{ field: any; sort: any }>();

  // Transform sort order from table to backend type
  const handleOrder = (order?: 'ascend' | 'descend') => {
    if (order === 'ascend') {
      return 1;
    }

    if (order === 'descend') {
      return -1;
    }

    return null;
  };

  // Set sort to backend required format or undefined
  const setSort = (pagination: any, filters: any, sorter: any) => {
    const { order, field } = sorter;
    const ordered = handleOrder(order);

    if (ordered) {
      return setSortBy({ field, sort: ordered });
    }

    return setSortBy(undefined);
  };

  return { sortBy, setSort };
};

export { useSort };
