import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** Sort direction 1 | -1 */
  SortDirection: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
}

export interface ActivitiesSortBy {
  field: ActivitySortableField;
  sort: Scalars['SortDirection'];
}

export interface Activity extends BaseActivity {
  __typename?: 'Activity';
  actor: UserPublicProfile;
  actorId: Scalars['ID'];
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: ActivityType;
  userId: Scalars['ID'];
}

export interface ActivityPagination {
  __typename?: 'ActivityPagination';
  count: Scalars['Int'];
  items: Array<BaseActivity>;
}

export enum ActivitySortableField {
  CreatedAt = 'createdAt',
}

export enum ActivityType {
  GiftRedemption = 'GIFT_REDEMPTION',
  GiveRewardPoints = 'GIVE_REWARD_POINTS',
  ReferralAccepted = 'REFERRAL_ACCEPTED',
  ReferralAcceptedNotHired = 'REFERRAL_ACCEPTED_NOT_HIRED',
  ReferralHirePending = 'REFERRAL_HIRE_PENDING',
  ReferralHireRejected = 'REFERRAL_HIRE_REJECTED',
  ReferralHired = 'REFERRAL_HIRED',
  ReferralRejected = 'REFERRAL_REJECTED',
}

export interface Address {
  __typename?: 'Address';
  apartment?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
}

export interface AddressInput {
  apartment?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
}

export enum AtsService {
  Greenhouse = 'greenhouse',
  Lever = 'lever',
}

export interface AuthResponse {
  __typename?: 'AuthResponse';
  accessToken: Scalars['String'];
  is2FARequired: Scalars['Boolean'];
  refreshToken: Scalars['String'];
  user: User;
}

export interface BaseActivity {
  actor: UserPublicProfile;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  type: ActivityType;
  userId: Scalars['ID'];
}

export interface BaseGiftCategory {
  id: Scalars['ID'];
  name: Scalars['String'];
}

export interface BasePayment {
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  description?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  id: Scalars['ID'];
  status: PaymentStatus;
  stripeHostedUrl?: Maybe<Scalars['String']>;
  type: PaymentType;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Int'];
}

export interface BillingAddress {
  __typename?: 'BillingAddress';
  apartment?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  state: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  stripeAutomaticTax?: Maybe<StripeAutomaticTax>;
  zip: Scalars['String'];
}

export interface BillingAddressInput {
  apartment?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  state: Scalars['String'];
  street?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
}

export interface Campaign {
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: UserPublicProfile;
  createdById: Scalars['ID'];
  endAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  promotionMsgLastSentAt?: Maybe<Scalars['DateTime']>;
  promotionTemplate?: Maybe<Scalars['JSONObject']>;
  roles?: Maybe<Array<Role>>;
  sendPromotionMsgAt?: Maybe<Scalars['DateTime']>;
  sendPromotionMsgTo?: Maybe<SendPromotionMsgTo>;
  startAt: Scalars['DateTime'];
  status: CampaignStatus;
  type: CampaignType;
  updatedAt: Scalars['DateTime'];
}

export interface CampaignDateRange {
  end: Scalars['DateTime'];
  start: Scalars['DateTime'];
}

export interface CampaignPagination {
  __typename?: 'CampaignPagination';
  count: Scalars['Int'];
  items: Array<Campaign>;
}

export interface CampaignRewardBoost extends Campaign {
  __typename?: 'CampaignRewardBoost';
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: UserPublicProfile;
  createdById: Scalars['ID'];
  endAt: Scalars['DateTime'];
  hireValue: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
  promotionMsgLastSentAt?: Maybe<Scalars['DateTime']>;
  promotionTemplate?: Maybe<Scalars['JSONObject']>;
  referralValue: Scalars['Int'];
  roles?: Maybe<Array<Role>>;
  sendPromotionMsgAt?: Maybe<Scalars['DateTime']>;
  sendPromotionMsgTo?: Maybe<SendPromotionMsgTo>;
  startAt: Scalars['DateTime'];
  status: CampaignStatus;
  type: CampaignType;
  updatedAt: Scalars['DateTime'];
}

export interface CampaignsFilter {
  excludeIds?: Maybe<Array<Scalars['ID']>>;
  inclusiveDateRange?: Maybe<CampaignDateRange>;
  page: Scalars['Int'];
  pageSize?: Maybe<Scalars['Int']>;
  searchByCreatedByName?: Maybe<Scalars['String']>;
  searchByName?: Maybe<Scalars['String']>;
  sortBy?: Maybe<CampaignSortBy>;
  statuses?: Maybe<Array<CampaignStatus>>;
  types?: Maybe<Array<CampaignType>>;
}

export enum CampaignSortableField {
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  EndAt = 'endAt',
  Name = 'name',
  StartAt = 'startAt',
  Status = 'status',
  Type = 'type',
}

export interface CampaignSortBy {
  field: CampaignSortableField;
  sort: Scalars['SortDirection'];
}

export enum CampaignStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum CampaignType {
  RewardBoost = 'REWARD_BOOST',
}

export interface Company {
  __typename?: 'Company';
  address?: Maybe<Scalars['String']>;
  authService: UserAuthService;
  billingAddress?: Maybe<BillingAddress>;
  billingPeriodStart?: Maybe<Scalars['DateTime']>;
  costPerUserPerMonth: Scalars['Float'];
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  hireRewardReminderGap: Scalars['Int'];
  hireRewardWaitPeriod: Scalars['Int'];
  hireValue: Scalars['Int'];
  id: Scalars['ID'];
  invitationCode: Scalars['String'];
  is2FARequired: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isSubActive: Scalars['Boolean'];
  name: Scalars['String'];
  pointsConversionValue: Scalars['Float'];
  referralValue: Scalars['Int'];
  revenueShare: Scalars['Float'];
  stripeCustomerId?: Maybe<Scalars['String']>;
  subCurrentPeriodEndAt?: Maybe<Scalars['DateTime']>;
  subId?: Maybe<Scalars['String']>;
  subPlanId?: Maybe<Scalars['String']>;
  subQuantity?: Maybe<Scalars['Int']>;
  subStatus?: Maybe<SubscriptionStatus>;
  trialExpirationDate?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  whitelistedDomains: Array<Scalars['String']>;
}

export interface CompanyActiveRolesFilter {
  createdByIds?: Maybe<Array<Scalars['ID']>>;
  page: Scalars['Int'];
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<RolesSortBy>;
}

export interface CompanyIntegration {
  __typename?: 'CompanyIntegration';
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  customFields?: Maybe<CompanyIntegrationCustomFieldsUnion>;
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  onBehalfOfInput?: Maybe<Scalars['String']>;
  responsibleUser: UserPublicProfile;
  responsibleUserId: Scalars['ID'];
  secretLastSymbols: Scalars['String'];
  service: IntegrationService;
  updatedAt: Scalars['DateTime'];
}

export type CompanyIntegrationCustomFieldsUnion = GreenHouseCompanyIntegrationCustomFields;

export interface CompanyPaymentsFilter {
  createdFrom?: Maybe<Scalars['DateTime']>;
  createdTo?: Maybe<Scalars['DateTime']>;
  page: Scalars['Int'];
  pageSize?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<PaymentsSortBy>;
  types?: Maybe<Array<PaymentType>>;
}

export interface CompanyPublicDetails {
  __typename?: 'CompanyPublicDetails';
  authService: UserAuthService;
  id: Scalars['ID'];
  invitationCode: Scalars['String'];
  name: Scalars['String'];
  whitelistedDomains: Array<Scalars['String']>;
}

export interface CompanyReferralsFilter {
  createdByIds?: Maybe<Array<Scalars['ID']>>;
  createdFrom?: Maybe<Scalars['DateTime']>;
  createdTo?: Maybe<Scalars['DateTime']>;
  page: Scalars['Int'];
  pageSize?: Maybe<Scalars['Int']>;
  referralStatuses?: Maybe<Array<ReferralStatus>>;
  searchByCandidateName?: Maybe<Scalars['String']>;
  searchByReferredByName?: Maybe<Scalars['String']>;
  searchByRoleName?: Maybe<Scalars['String']>;
  sortBy?: Maybe<ReferralSortBy>;
}

export interface CompanyRolesFilter {
  createdByIds?: Maybe<Array<Scalars['ID']>>;
  page: Scalars['Int'];
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<RolesSortBy>;
  statuses?: Maybe<Array<RoleStatus>>;
}

export interface CompanyStripeCustomer {
  __typename?: 'CompanyStripeCustomer';
  creditCard?: Maybe<CreditCard>;
  id: Scalars['ID'];
}

export interface CompanyUsersActivitiesFilter {
  createdFrom?: Maybe<Scalars['DateTime']>;
  createdTo?: Maybe<Scalars['DateTime']>;
  page: Scalars['Int'];
  pageSize?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<ActivitiesSortBy>;
  types?: Maybe<Array<ActivityType>>;
  usersIds?: Maybe<Array<Scalars['ID']>>;
}

export interface CreateCampaignInput {
  endAt: Scalars['DateTime'];
  hireValue?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  promotionTemplate?: Maybe<Scalars['JSONObject']>;
  referralValue?: Maybe<Scalars['Int']>;
  rolesIds?: Maybe<Array<Scalars['ID']>>;
  sendPromotionMsgAt?: Maybe<Scalars['DateTime']>;
  sendPromotionMsgTo?: Maybe<SendPromotionMsgToInput>;
  startAt: Scalars['DateTime'];
  status?: Maybe<CampaignStatus>;
  type?: Maybe<CampaignType>;
}

export interface CreateCompanyInput {
  address?: Maybe<Scalars['String']>;
  billingAddress: BillingAddressInput;
  hireRewardWaitPeriod?: Maybe<Scalars['Int']>;
  hireValue?: Maybe<Scalars['Int']>;
  is2FARequired?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  referralValue?: Maybe<Scalars['Int']>;
}

export interface CreateCompanyUnsupportedCountryInput {
  country: Scalars['String'];
  name: Scalars['String'];
}

export interface CreateDepartmentInput {
  name: Scalars['String'];
  status?: Maybe<DepartmentsStatus>;
}

export interface CreateGreenhouseCompanyIntegrationInput {
  isActive?: Maybe<Scalars['Boolean']>;
  /** Job boards token to sync */
  jobBoards: Array<Scalars['String']>;
  /** Id or Email of the Greenhouse user */
  onBehalfOfInput: Scalars['String'];
  /** Id of the HireAction user */
  responsibleUserId?: Maybe<Scalars['ID']>;
  secret: Scalars['String'];
}

export interface CreateLeverCompanyIntegrationInput {
  authCode: Scalars['String'];
  authState: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  /** Id of the HireAction user */
  responsibleUserId?: Maybe<Scalars['ID']>;
}

export interface CreateReferralInput {
  candidateEmail: Scalars['String'];
  candidateLinkedin?: Maybe<Scalars['String']>;
  candidateName: Scalars['String'];
  cvUpload?: Maybe<Scalars['Upload']>;
  reason?: Maybe<Scalars['String']>;
  reasonOption?: Maybe<ReferralReasonOption>;
  roleId: Scalars['ID'];
}

export interface CreateRoleInput {
  departmentId?: Maybe<Scalars['ID']>;
  hireValue?: Maybe<Scalars['Int']>;
  hiringRepId?: Maybe<Scalars['ID']>;
  isHireValueOverridden?: Maybe<Scalars['Boolean']>;
  isReferralValueOverridden?: Maybe<Scalars['Boolean']>;
  jobDescriptionLink: Scalars['String'];
  name: Scalars['String'];
  referralValue?: Maybe<Scalars['Int']>;
}

export interface CreateUserByWorkspaceAdminInput {
  email: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  role: UserSetCompanyRole;
  title?: Maybe<Scalars['String']>;
}

export interface CreditCard {
  __typename?: 'CreditCard';
  brand: Scalars['String'];
  id: Scalars['ID'];
  last4?: Maybe<Scalars['String']>;
}

export enum Currency {
  Cad = 'CAD',
  Usd = 'USD',
}

export interface Department {
  __typename?: 'Department';
  ats?: Maybe<AtsService>;
  atsDepartmentId?: Maybe<Scalars['ID']>;
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdById: Scalars['ID'];
  id: Scalars['ID'];
  name: Scalars['String'];
  status: DepartmentsStatus;
  updatedAt: Scalars['DateTime'];
}

export interface DepartmentPagination {
  __typename?: 'DepartmentPagination';
  count: Scalars['Int'];
  items: Array<Department>;
}

export interface DepartmentsFilter {
  page: Scalars['Int'];
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<DepartmentSortBy>;
  statuses?: Maybe<Array<DepartmentsStatus>>;
}

export enum DepartmentSortableField {
  CreatedAt = 'createdAt',
  Name = 'name',
}

export interface DepartmentSortBy {
  field: DepartmentSortableField;
  sort: Scalars['SortDirection'];
}

export enum DepartmentsStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
}

export interface Gift {
  __typename?: 'Gift';
  categories: Array<GiftCategory>;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  externalId?: Maybe<Scalars['ID']>;
  featuredLvl: Scalars['Int'];
  id: Scalars['ID'];
  imageUrl: Scalars['String'];
  isActive: Scalars['Boolean'];
  isGlobal: Scalars['Boolean'];
  name: Scalars['String'];
  prices: Array<GiftPrice>;
  type: GiftType;
  updatedAt: Scalars['DateTime'];
  vendor: Vendor;
  vendorId: Scalars['ID'];
}

export interface GiftCategory extends BaseGiftCategory {
  __typename?: 'GiftCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
}

export interface GiftCategoryWithCount extends BaseGiftCategory {
  __typename?: 'GiftCategoryWithCount';
  giftsCount: Scalars['Int'];
  id: Scalars['ID'];
  name: Scalars['String'];
}

export interface GiftPagination {
  __typename?: 'GiftPagination';
  count: Scalars['Int'];
  items: Array<Gift>;
}

export interface GiftPrice {
  __typename?: 'GiftPrice';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  value: Scalars['Int'];
  valueInPoints: Scalars['Int'];
}

export interface GiftsFilter {
  categoryIds?: Maybe<Array<Scalars['ID']>>;
  page: Scalars['Int'];
  pageSize?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  sortBy?: Maybe<GiftsSortBy>;
}

export enum GiftSortableField {
  CreatedAt = 'createdAt',
  FeaturedLvl = 'featuredLvl',
  Name = 'name',
}

export interface GiftsSortBy {
  field: GiftSortableField;
  sort: Scalars['SortDirection'];
}

export enum GiftType {
  Physical = 'PHYSICAL',
  Virtual = 'VIRTUAL',
}

export interface GiveRewardActivity extends BaseActivity {
  __typename?: 'GiveRewardActivity';
  actor: UserPublicProfile;
  balanceSnapshot: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  pointsRewarded: PointsRewarded;
  pointsRewardedId: Scalars['ID'];
  type: ActivityType;
  userId: Scalars['ID'];
}

export interface GiveRewardPointsInput {
  message?: Maybe<Scalars['String']>;
  pointsToReward?: Maybe<Scalars['Float']>;
}

export interface GlobalSettings {
  __typename?: 'GlobalSettings';
  defaultCostPerUserPerMonth: Scalars['Int'];
  defaultHireValue: Scalars['Int'];
  defaultReferralValue: Scalars['Int'];
  defaultRevenueShare: Scalars['Float'];
  defaultTrialPeriod: Scalars['Int'];
  hireRewardReminderGap: Scalars['Int'];
  hireRewardWaitPeriod: Scalars['Int'];
  id: Scalars['Float'];
  minimumHireValue: Scalars['Int'];
  minimumReferralValue: Scalars['Int'];
}

export interface GreenHouseCompanyIntegrationCustomFields {
  __typename?: 'GreenHouseCompanyIntegrationCustomFields';
  jobBoards: Array<Scalars['String']>;
}

export enum IntegrationService {
  Greenhouse = 'greenhouse',
  Lever = 'lever',
}

export interface Mutation {
  __typename?: 'Mutation';
  acceptInviteByToken: AuthResponse;
  acceptReferral: Referral;
  companyLeaderboardOnRedemptionsCSVContent: Scalars['String'];
  /** Config a 2FA email method if any 2FA method already configured */
  config2FAEmailMethod: AuthResponse;
  confirmEmailByCode: User;
  createCampaign: Campaign;
  createCompany: Company;
  createCompanySubscription: Company;
  createCompanyUnsupportedCountry: Scalars['Boolean'];
  createDepartment: Department;
  createGreenhouseCompanyIntegration: CompanyIntegration;
  createLeverCompanyIntegration: CompanyIntegration;
  createReferral: Referral;
  createRole: Role;
  createUserByWorkspaceAdmin: User;
  exchangePointsForGift: PointsRedeemed;
  forgotPassword: Scalars['Boolean'];
  generateOTP2FA: OtpResponse;
  giveRewardPointsByWorkspaceAdmin: Payment;
  initialSet2FAEmailAddress: Scalars['Boolean'];
  /** Initial setup of the 2FA email method */
  initialSetup2FAEmailMethod: AuthResponse;
  /** Initial setup of the 2FA OTP method */
  initialSetupOTP2FA: AuthResponse;
  login: AuthResponse;
  loginWithGoogle: AuthResponse;
  logout: Scalars['Boolean'];
  markReferralAsAcceptedNotHired: Referral;
  markReferralAsHired: Referral;
  markReferralAsHirePending: Referral;
  markReferralAsHireRejected: Referral;
  refreshUserTokenPair: AuthResponse;
  rejectReferral: Referral;
  remove2FAMethod: User;
  resendEmailByJWTToken: Scalars['Boolean'];
  resendEmailConfirmationCode: Scalars['Boolean'];
  resetPasswordByToken: Scalars['Boolean'];
  send2FAEmailCode: Scalars['Boolean'];
  set2FAEmailAddress: Scalars['Boolean'];
  setPasswordResetByWorkspaceAdmin: Scalars['Boolean'];
  setPreferred2FAMethod: User;
  setUserAvatar: User;
  signUpUserWithGoogle: AuthResponse;
  signUpUserWithPassword: AuthResponse;
  signUpWorkspaceAdminWithGoogle: AuthResponse;
  signUpWorkspaceAdminWithPassword: AuthResponse;
  updateCampaign: Campaign;
  updateCompany: Company;
  updateCompanyBillingAddress: Company;
  updateCompanyPaymentMethod: CompanyStripeCustomer;
  updateDepartment: Department;
  updateGreenhouseCompanyIntegration: CompanyIntegration;
  updateLeverCompanyIntegration: CompanyIntegration;
  /** Config a 2FA OTP method if any 2FA method already configured */
  updateOTP2FA: AuthResponse;
  updateRole: Role;
  updateUser: User;
  updateUserByWorkspaceAdmin: User;
  updateUserPassword: User;
  verifyEmail2FACode: AuthResponse;
  verifyOTP2FA: AuthResponse;
}

export interface MutationAcceptInviteByTokenArgs {
  acceptInviteToken: Scalars['String'];
  captchaToken?: Maybe<Scalars['String']>;
  password: Scalars['String'];
}

export interface MutationAcceptReferralArgs {
  referralId: Scalars['ID'];
}

export interface MutationCompanyLeaderboardOnRedemptionsCsvContentArgs {
  input: UsersLeaderboardCsvInput;
}

export interface MutationConfig2FaEmailMethodArgs {
  token: Scalars['String'];
}

export interface MutationConfirmEmailByCodeArgs {
  code: Scalars['String'];
}

export interface MutationCreateCampaignArgs {
  input: CreateCampaignInput;
}

export interface MutationCreateCompanyArgs {
  input: CreateCompanyInput;
}

export interface MutationCreateCompanyUnsupportedCountryArgs {
  input: CreateCompanyUnsupportedCountryInput;
}

export interface MutationCreateDepartmentArgs {
  input: CreateDepartmentInput;
}

export interface MutationCreateGreenhouseCompanyIntegrationArgs {
  input: CreateGreenhouseCompanyIntegrationInput;
}

export interface MutationCreateLeverCompanyIntegrationArgs {
  input: CreateLeverCompanyIntegrationInput;
}

export interface MutationCreateReferralArgs {
  input: CreateReferralInput;
}

export interface MutationCreateRoleArgs {
  input: CreateRoleInput;
}

export interface MutationCreateUserByWorkspaceAdminArgs {
  input: CreateUserByWorkspaceAdminInput;
}

export interface MutationExchangePointsForGiftArgs {
  giftId: Scalars['ID'];
  giftPriceId: Scalars['ID'];
}

export interface MutationForgotPasswordArgs {
  email: Scalars['String'];
}

export interface MutationGiveRewardPointsByWorkspaceAdminArgs {
  input: GiveRewardPointsInput;
  userId: Scalars['ID'];
}

export interface MutationInitialSet2FaEmailAddressArgs {
  email: Scalars['String'];
}

export interface MutationInitialSetup2FaEmailMethodArgs {
  token: Scalars['String'];
}

export interface MutationInitialSetupOtp2FaArgs {
  token: Scalars['String'];
}

export interface MutationLoginArgs {
  captchaToken?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
}

export interface MutationLoginWithGoogleArgs {
  accessToken: Scalars['String'];
}

export interface MutationMarkReferralAsAcceptedNotHiredArgs {
  referralId: Scalars['ID'];
}

export interface MutationMarkReferralAsHiredArgs {
  referralId: Scalars['ID'];
}

export interface MutationMarkReferralAsHirePendingArgs {
  referralId: Scalars['ID'];
}

export interface MutationMarkReferralAsHireRejectedArgs {
  referralId: Scalars['ID'];
  rejectReason?: Maybe<Scalars['String']>;
}

export interface MutationRefreshUserTokenPairArgs {
  refreshToken: Scalars['String'];
}

export interface MutationRejectReferralArgs {
  referralId: Scalars['ID'];
  rejectReason?: Maybe<Scalars['String']>;
}

export interface MutationRemove2FaMethodArgs {
  method: User2FaMethod;
}

export interface MutationResendEmailByJwtTokenArgs {
  token: Scalars['String'];
}

export interface MutationResetPasswordByTokenArgs {
  password: Scalars['String'];
  resetPwdToken: Scalars['String'];
}

export interface MutationSet2FaEmailAddressArgs {
  email: Scalars['String'];
}

export interface MutationSetPasswordResetByWorkspaceAdminArgs {
  userId: Scalars['ID'];
}

export interface MutationSetPreferred2FaMethodArgs {
  preferred2FAMethod: User2FaMethod;
}

export interface MutationSetUserAvatarArgs {
  avatarUpload?: Maybe<Scalars['Upload']>;
}

export interface MutationSignUpUserWithGoogleArgs {
  accessToken: Scalars['String'];
  invitationCode: Scalars['String'];
}

export interface MutationSignUpUserWithPasswordArgs {
  input: SignUpWithPasswordInput;
  invitationCode: Scalars['String'];
}

export interface MutationSignUpWorkspaceAdminWithGoogleArgs {
  accessToken: Scalars['String'];
}

export interface MutationSignUpWorkspaceAdminWithPasswordArgs {
  input: SignUpWithPasswordInput;
}

export interface MutationUpdateCampaignArgs {
  campaignId: Scalars['ID'];
  input: UpdateCampaignInput;
}

export interface MutationUpdateCompanyArgs {
  input: UpdateCompanyInput;
}

export interface MutationUpdateCompanyBillingAddressArgs {
  input: BillingAddressInput;
}

export interface MutationUpdateCompanyPaymentMethodArgs {
  paymentMethodId: Scalars['String'];
}

export interface MutationUpdateDepartmentArgs {
  departmentId: Scalars['ID'];
  input: UpdateDepartmentInput;
}

export interface MutationUpdateGreenhouseCompanyIntegrationArgs {
  input: UpdateGreenhouseCompanyIntegrationInput;
}

export interface MutationUpdateLeverCompanyIntegrationArgs {
  input: UpdateLeverCompanyIntegrationInput;
}

export interface MutationUpdateOtp2FaArgs {
  token: Scalars['String'];
}

export interface MutationUpdateRoleArgs {
  input: UpdateRoleInput;
  roleId: Scalars['ID'];
}

export interface MutationUpdateUserArgs {
  input: UpdateUserInput;
}

export interface MutationUpdateUserByWorkspaceAdminArgs {
  input: UpdateUserByWorkspaceAdminInput;
  userId: Scalars['ID'];
}

export interface MutationUpdateUserPasswordArgs {
  input: SetUserPasswordInput;
}

export interface MutationVerifyEmail2FaCodeArgs {
  token: Scalars['String'];
}

export interface MutationVerifyOtp2FaArgs {
  token: Scalars['String'];
}

export interface OtpResponse {
  __typename?: 'OtpResponse';
  qrCodeDataUrl: Scalars['String'];
  secret: Scalars['String'];
}

export interface Payment extends BasePayment {
  __typename?: 'Payment';
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  description?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  id: Scalars['ID'];
  status: PaymentStatus;
  stripeHostedUrl?: Maybe<Scalars['String']>;
  type: PaymentType;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Int'];
}

export interface PaymentPagination {
  __typename?: 'PaymentPagination';
  count: Scalars['Int'];
  items: Array<BasePayment>;
}

export enum PaymentsSortableField {
  CreatedAt = 'createdAt',
  Value = 'value',
}

export interface PaymentsSortBy {
  field: PaymentsSortableField;
  sort: Scalars['SortDirection'];
}

export enum PaymentStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export enum PaymentType {
  GiveRewardPoints = 'GIVE_REWARD_POINTS',
  ReferralAccepted = 'REFERRAL_ACCEPTED',
  ReferralHired = 'REFERRAL_HIRED',
  SubscriptionCycle = 'SUBSCRIPTION_CYCLE',
  SubscriptionProration = 'SUBSCRIPTION_PRORATION',
}

export interface PointsRedeemed {
  __typename?: 'PointsRedeemed';
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  externalId?: Maybe<Scalars['ID']>;
  gift: Gift;
  giftId: Scalars['ID'];
  giftPrice: GiftPrice;
  giftPriceId: Scalars['ID'];
  id: Scalars['ID'];
  status: PointsRedeemedStatus;
  updatedAt: Scalars['DateTime'];
  user: UserPublicProfile;
  userId: Scalars['ID'];
  value: Scalars['Int'];
}

export interface PointsRedeemedFilter {
  createdFrom?: Maybe<Scalars['DateTime']>;
  createdTo?: Maybe<Scalars['DateTime']>;
  page: Scalars['Int'];
  pageSize?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<PointsRedeemedSortBy>;
  statuses?: Maybe<Array<PointsRedeemedStatus>>;
}

export interface PointsRedeemedPagination {
  __typename?: 'PointsRedeemedPagination';
  count: Scalars['Int'];
  items: Array<PointsRedeemed>;
}

export enum PointsRedeemedSortableField {
  CreatedAt = 'createdAt',
  Type = 'type',
  Value = 'value',
}

export interface PointsRedeemedSortBy {
  field: PointsRedeemedSortableField;
  sort: Scalars['SortDirection'];
}

export enum PointsRedeemedStatus {
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export interface PointsRewarded {
  __typename?: 'PointsRewarded';
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  referral: Referral;
  referralId?: Maybe<Scalars['ID']>;
  type: PointsRewardedType;
  updatedAt: Scalars['DateTime'];
  user: UserPublicProfile;
  userId: Scalars['ID'];
  value: Scalars['Int'];
}

export interface PointsRewardedFilter {
  createdFrom?: Maybe<Scalars['DateTime']>;
  createdTo?: Maybe<Scalars['DateTime']>;
  page: Scalars['Int'];
  pageSize?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<PointsRewardedSortBy>;
  types?: Maybe<Array<PointsRewardedType>>;
}

export interface PointsRewardedPagination {
  __typename?: 'PointsRewardedPagination';
  count: Scalars['Int'];
  items: Array<PointsRewarded>;
}

export enum PointsRewardedSortableField {
  CreatedAt = 'createdAt',
  ReferralId = 'referralId',
  Type = 'type',
  Value = 'value',
}

export interface PointsRewardedSortBy {
  field: PointsRewardedSortableField;
  sort: Scalars['SortDirection'];
}

export enum PointsRewardedType {
  GiveRewardPoints = 'GIVE_REWARD_POINTS',
  Hire = 'HIRE',
  Referral = 'REFERRAL',
}

export interface Query {
  __typename?: 'Query';
  allGiftCategories: Array<BaseGiftCategory>;
  allGiftCategoriesWithCount: Array<BaseGiftCategory>;
  companyActiveGifts: GiftPagination;
  companyActiveRoles: RolesPagination;
  companyActiveRolesCount: Scalars['Int'];
  companyCampaign: Campaign;
  companyCampaigns: CampaignPagination;
  companyDepartments: DepartmentPagination;
  companyIntegrations: Array<CompanyIntegration>;
  companyLeaderboardOnHires: Array<UserLeaderboard>;
  companyLeaderboardOnRedemptions: UsersLeaderboardPagination;
  companyLeaderboardOnReferrals: Array<UserLeaderboard>;
  companyPayments: PaymentPagination;
  companyPublicDetails: CompanyPublicDetails;
  companyReferralPointsStats: ReferralsPointsStats;
  companyReferrals: ReferralPagination;
  companyRoles: RolesPagination;
  companyRunningCampaigns: Array<Campaign>;
  companyStripeCustomer?: Maybe<CompanyStripeCustomer>;
  companyUsers: UserPublicProfilesPagination;
  companyUsersActivities: ActivityPagination;
  currentUser: User;
  currentUserCompany?: Maybe<Company>;
  globalSettings: GlobalSettings;
  leverOAuthUrl: Scalars['String'];
  referralUsersActivities: Array<Activity>;
  userActivities: ActivityPagination;
  userPointsBalance: Scalars['Int'];
  userPointsRedeemed: PointsRedeemedPagination;
  userPointsRewarded: PointsRewardedPagination;
  userReferralPointsStats: ReferralsPointsStats;
  userReferrals: ReferralPagination;
  version: Scalars['String'];
}

export interface QueryCompanyActiveGiftsArgs {
  filter: GiftsFilter;
}

export interface QueryCompanyActiveRolesArgs {
  filter: CompanyActiveRolesFilter;
}

export interface QueryCompanyCampaignArgs {
  campaignId: Scalars['ID'];
}

export interface QueryCompanyCampaignsArgs {
  filter: CampaignsFilter;
}

export interface QueryCompanyDepartmentsArgs {
  filter: DepartmentsFilter;
}

export interface QueryCompanyLeaderboardOnRedemptionsArgs {
  filter: UsersLeaderboardFilter;
}

export interface QueryCompanyPaymentsArgs {
  filter: CompanyPaymentsFilter;
}

export interface QueryCompanyPublicDetailsArgs {
  invitationCode: Scalars['String'];
}

export interface QueryCompanyReferralsArgs {
  filter: CompanyReferralsFilter;
}

export interface QueryCompanyRolesArgs {
  filter: CompanyRolesFilter;
}

export interface QueryCompanyUsersArgs {
  filter: UsersFilter;
}

export interface QueryCompanyUsersActivitiesArgs {
  filter: CompanyUsersActivitiesFilter;
}

export interface QueryReferralUsersActivitiesArgs {
  referralId: Scalars['ID'];
}

export interface QueryUserActivitiesArgs {
  filter: UserActivitiesFilter;
}

export interface QueryUserPointsRedeemedArgs {
  filter: PointsRedeemedFilter;
}

export interface QueryUserPointsRewardedArgs {
  filter: PointsRewardedFilter;
}

export interface QueryUserReferralsArgs {
  filter: UserReferralsFilter;
}

export interface RedeemActivity extends BaseActivity {
  __typename?: 'RedeemActivity';
  actor: UserPublicProfile;
  balanceSnapshot: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  pointsRedeemed: PointsRedeemed;
  pointsRedeemedId: Scalars['ID'];
  type: ActivityType;
  userId: Scalars['ID'];
}

export interface Referral {
  __typename?: 'Referral';
  acceptedAt?: Maybe<Scalars['DateTime']>;
  ats?: Maybe<AtsService>;
  atsCandidateId?: Maybe<Scalars['ID']>;
  atsReferralId?: Maybe<Scalars['ID']>;
  candidateEmail: Scalars['String'];
  candidateLinkedin?: Maybe<Scalars['String']>;
  candidateName: Scalars['String'];
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: UserPublicProfile;
  createdById: Scalars['ID'];
  cvUrl?: Maybe<Scalars['String']>;
  hiredAt?: Maybe<Scalars['DateTime']>;
  hirePendingEndAt?: Maybe<Scalars['DateTime']>;
  hireRewardReminderEmailSent?: Maybe<Scalars['Boolean']>;
  hireValue: Scalars['Int'];
  id: Scalars['ID'];
  reason?: Maybe<Scalars['String']>;
  reasonOption?: Maybe<ReferralReasonOption>;
  referralStatus: ReferralStatus;
  referralValue: Scalars['Int'];
  rejectedBy?: Maybe<UserPublicProfile>;
  rejectedById?: Maybe<Scalars['ID']>;
  rejectReason?: Maybe<Scalars['String']>;
  rewardedForHireValue: Scalars['Int'];
  rewardedForReferralValue: Scalars['Int'];
  role: Role;
  roleId: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
}

export interface ReferralActivity extends BaseActivity {
  __typename?: 'ReferralActivity';
  actor: UserPublicProfile;
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  referral: Referral;
  referralId: Scalars['ID'];
  type: ActivityType;
  userId: Scalars['ID'];
}

export interface ReferralPagination {
  __typename?: 'ReferralPagination';
  count: Scalars['Int'];
  items: Array<Referral>;
}

export interface ReferralPayment extends BasePayment {
  __typename?: 'ReferralPayment';
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  description?: Maybe<Scalars['String']>;
  fee: Scalars['Int'];
  id: Scalars['ID'];
  referral: Referral;
  status: PaymentStatus;
  stripeHostedUrl?: Maybe<Scalars['String']>;
  type: PaymentType;
  updatedAt: Scalars['DateTime'];
  value: Scalars['Int'];
}

export enum ReferralReasonOption {
  Friend = 'FRIEND',
  NoPersonalRelationship = 'NO_PERSONAL_RELATIONSHIP',
  Other = 'OTHER',
  PastCoWorker = 'PAST_CO_WORKER',
}

export enum ReferralSortableField {
  CandidateEmail = 'candidateEmail',
  CandidateName = 'candidateName',
  CreatedAt = 'createdAt',
  CreatedByName = 'createdByName',
  HireValue = 'hireValue',
  ReferralStatus = 'referralStatus',
  ReferralValue = 'referralValue',
  RoleName = 'roleName',
}

export interface ReferralSortBy {
  field: ReferralSortableField;
  sort: Scalars['SortDirection'];
}

export interface ReferralsPointsStats {
  __typename?: 'ReferralsPointsStats';
  balance: Scalars['Int'];
  pointsRewarded: Scalars['Int'];
  referralsCount: Scalars['Int'];
}

export enum ReferralStatus {
  Accepted = 'ACCEPTED',
  AcceptedNotHired = 'ACCEPTED_NOT_HIRED',
  HirePending = 'HIRE_PENDING',
  HireRejected = 'HIRE_REJECTED',
  Hired = 'HIRED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
}

export interface RewardActivity extends BaseActivity {
  __typename?: 'RewardActivity';
  actor: UserPublicProfile;
  balanceSnapshot: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  pointsRewarded: PointsRewarded;
  pointsRewardedId: Scalars['ID'];
  referral: Referral;
  referralId?: Maybe<Scalars['ID']>;
  type: ActivityType;
  userId: Scalars['ID'];
}

export interface Role {
  __typename?: 'Role';
  ats?: Maybe<AtsService>;
  atsRoleId?: Maybe<Scalars['ID']>;
  campaigns?: Maybe<Array<Campaign>>;
  companyId: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  createdBy: UserPublicProfile;
  createdById: Scalars['ID'];
  department?: Maybe<Department>;
  departmentId?: Maybe<Scalars['ID']>;
  hireValue: Scalars['Int'];
  hiringRep?: Maybe<UserPublicProfile>;
  hiringRepId: Scalars['ID'];
  id: Scalars['ID'];
  isHireValueOverridden: Scalars['Boolean'];
  isReferralValueOverridden: Scalars['Boolean'];
  jobDescriptionLink: Scalars['String'];
  name: Scalars['String'];
  referralResponsible?: Maybe<UserPublicProfile>;
  referralResponsibleId?: Maybe<Scalars['ID']>;
  referralsCount: Scalars['Int'];
  referralValue: Scalars['Int'];
  status: RoleStatus;
  updatedAt: Scalars['DateTime'];
}

export enum RoleSortableField {
  CreatedAt = 'createdAt',
  DepartmentName = 'departmentName',
  HireValue = 'hireValue',
  JobDescriptionLink = 'jobDescriptionLink',
  Name = 'name',
  ReferralsCount = 'referralsCount',
  ReferralValue = 'referralValue',
  Status = 'status',
}

export interface RolesPagination {
  __typename?: 'RolesPagination';
  count: Scalars['Int'];
  items: Array<Role>;
}

export interface RolesSortBy {
  field: RoleSortableField;
  sort: Scalars['SortDirection'];
}

export enum RoleStatus {
  Filled = 'FILLED',
  NotFilled = 'NOT_FILLED',
  Open = 'OPEN',
}

export interface SendPromotionMsgTo {
  __typename?: 'SendPromotionMsgTo';
  email: Scalars['Boolean'];
  slack: Scalars['Boolean'];
}

export interface SendPromotionMsgToInput {
  email?: Maybe<Scalars['Boolean']>;
  slack?: Maybe<Scalars['Boolean']>;
}

export interface SetUserPasswordInput {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}

export interface SignUpWithPasswordInput {
  captchaToken?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
}

export enum StripeAutomaticTax {
  Failed = 'failed',
  NotCollecting = 'not_collecting',
  Supported = 'supported',
  UnrecognizedLocation = 'unrecognized_location',
}

export enum SubscriptionStatus {
  Active = 'active',
  Canceled = 'canceled',
  Incomplete = 'incomplete',
  IncompleteExpired = 'incomplete_expired',
  PastDue = 'past_due',
  Trialing = 'trialing',
  Unpaid = 'unpaid',
}

export interface UpdateCampaignInput {
  endAt?: Maybe<Scalars['DateTime']>;
  hireValue?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  promotionTemplate?: Maybe<Scalars['JSONObject']>;
  referralValue?: Maybe<Scalars['Int']>;
  rolesIds?: Maybe<Array<Scalars['ID']>>;
  sendPromotionMsgAt?: Maybe<Scalars['DateTime']>;
  sendPromotionMsgTo?: Maybe<SendPromotionMsgToInput>;
  startAt?: Maybe<Scalars['DateTime']>;
  status?: Maybe<CampaignStatus>;
}

export interface UpdateCompanyInput {
  address?: Maybe<Scalars['String']>;
  hireRewardWaitPeriod?: Maybe<Scalars['Int']>;
  hireValue?: Maybe<Scalars['Int']>;
  is2FARequired?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  referralValue?: Maybe<Scalars['Int']>;
}

export interface UpdateDepartmentInput {
  name?: Maybe<Scalars['String']>;
  status?: Maybe<DepartmentsStatus>;
}

export interface UpdateGreenhouseCompanyIntegrationInput {
  isActive?: Maybe<Scalars['Boolean']>;
  /** Job boards token to sync */
  jobBoards?: Maybe<Array<Scalars['String']>>;
  /** Id or Email of the Greenhouse user */
  onBehalfOfInput?: Maybe<Scalars['String']>;
  /** Id of the HireAction user */
  responsibleUserId?: Maybe<Scalars['ID']>;
  secret?: Maybe<Scalars['String']>;
}

export interface UpdateLeverCompanyIntegrationInput {
  isActive?: Maybe<Scalars['Boolean']>;
  /** Id of the HireAction user */
  responsibleUserId?: Maybe<Scalars['ID']>;
}

export interface UpdateRoleInput {
  departmentId?: Maybe<Scalars['ID']>;
  hireValue?: Maybe<Scalars['Int']>;
  hiringRepId?: Maybe<Scalars['ID']>;
  isHireValueOverridden?: Maybe<Scalars['Boolean']>;
  isReferralValueOverridden?: Maybe<Scalars['Boolean']>;
  jobDescriptionLink?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  referralResponsibleId?: Maybe<Scalars['ID']>;
  referralValue?: Maybe<Scalars['Int']>;
  status?: Maybe<RoleStatus>;
}

export interface UpdateUserByWorkspaceAdminInput {
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<UserSetCompanyRole>;
  title?: Maybe<Scalars['String']>;
}

export interface UpdateUserInput {
  address?: Maybe<AddressInput>;
  is2FAEnabled?: Maybe<Scalars['Boolean']>;
  mobilePhone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  shippingAddress?: Maybe<Scalars['String']>;
}

export interface User {
  __typename?: 'User';
  address?: Maybe<Address>;
  authService: UserAuthService;
  avatarLink?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  email2FA?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is2FAConfigured: Scalars['Boolean'];
  is2FAEnabled: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  isEmailVerified: Scalars['Boolean'];
  isResetPasswordRequired: Scalars['Boolean'];
  mobilePhone?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role: UserRole;
  shippingAddress?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  totalPointsRedeemed: Scalars['Int'];
  totalPointsRewarded: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user2FAMethodItems: Array<User2FaMethodItem>;
}

export enum User2FaMethod {
  Authenticator = 'AUTHENTICATOR',
  Email = 'EMAIL',
}

export interface User2FaMethodItem {
  __typename?: 'User2FAMethodItem';
  isConfigured: Scalars['Boolean'];
  isPreferred: Scalars['Boolean'];
  method: User2FaMethod;
}

export interface UserActivitiesFilter {
  createdFrom?: Maybe<Scalars['DateTime']>;
  createdTo?: Maybe<Scalars['DateTime']>;
  page: Scalars['Int'];
  pageSize?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<ActivitiesSortBy>;
  types?: Maybe<Array<ActivityType>>;
  usersIds?: Maybe<Array<Scalars['ID']>>;
}

export enum UserAuthService {
  EmailPassword = 'EMAIL_PASSWORD',
  Google = 'GOOGLE',
}

export enum UserCompanyRole {
  Hr = 'HR',
  User = 'USER',
  WorkspaceAdmin = 'WORKSPACE_ADMIN',
}

export interface UserLeaderboard {
  __typename?: 'UserLeaderboard';
  avatarLink?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  value: Scalars['Float'];
}

export interface UserPublicProfile {
  __typename?: 'UserPublicProfile';
  authService: UserAuthService;
  avatarLink?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['ID']>;
  email: Scalars['String'];
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  role: UserRole;
  totalPointsRedeemed: Scalars['Int'];
  totalPointsRewarded: Scalars['Int'];
}

export interface UserPublicProfilesPagination {
  __typename?: 'UserPublicProfilesPagination';
  count: Scalars['Int'];
  items: Array<UserPublicProfile>;
}

export interface UserReferralsFilter {
  createdFrom?: Maybe<Scalars['DateTime']>;
  createdTo?: Maybe<Scalars['DateTime']>;
  page: Scalars['Int'];
  pageSize?: Maybe<Scalars['Int']>;
  referralStatuses?: Maybe<Array<ReferralStatus>>;
  searchByCandidateName?: Maybe<Scalars['String']>;
  searchByReferredByName?: Maybe<Scalars['String']>;
  searchByRoleName?: Maybe<Scalars['String']>;
  sortBy?: Maybe<ReferralSortBy>;
}

export enum UserRole {
  Hr = 'HR',
  SuperAdmin = 'SUPER_ADMIN',
  User = 'USER',
  WorkspaceAdmin = 'WORKSPACE_ADMIN',
}

export enum UserSetCompanyRole {
  Hr = 'HR',
  User = 'USER',
  WorkspaceAdmin = 'WORKSPACE_ADMIN',
}

export interface UsersFilter {
  createdFrom?: Maybe<Scalars['DateTime']>;
  createdTo?: Maybe<Scalars['DateTime']>;
  excludeCurrentUser?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  page: Scalars['Int'];
  pageSize?: Maybe<Scalars['Int']>;
  roles?: Maybe<Array<UserCompanyRole>>;
  /** Searching by name & email. Overrides searchByName */
  search?: Maybe<Scalars['String']>;
  searchByName?: Maybe<Scalars['String']>;
  sortBy?: Maybe<UserSortBy>;
}

export interface UsersLeaderboardCsvInput {
  createdFrom: Scalars['DateTime'];
  createdTo?: Maybe<Scalars['DateTime']>;
  searchByName?: Maybe<Scalars['String']>;
  sortBy?: Maybe<UsersLeaderboardSortBy>;
}

export interface UsersLeaderboardFilter {
  createdFrom: Scalars['DateTime'];
  createdTo?: Maybe<Scalars['DateTime']>;
  page: Scalars['Int'];
  pageSize?: Maybe<Scalars['Int']>;
  searchByName?: Maybe<Scalars['String']>;
  sortBy?: Maybe<UsersLeaderboardSortBy>;
}

export interface UsersLeaderboardPagination {
  __typename?: 'UsersLeaderboardPagination';
  count: Scalars['Int'];
  items: Array<UserLeaderboard>;
}

export enum UsersLeaderboardSortableFields {
  Email = 'email',
  Name = 'name',
  Value = 'value',
}

export interface UsersLeaderboardSortBy {
  field: UsersLeaderboardSortableFields;
  sort: Scalars['SortDirection'];
}

export interface UserSortBy {
  field: UsersSortableField;
  sort: Scalars['SortDirection'];
}

export enum UsersSortableField {
  CreatedAt = 'createdAt',
  Email = 'email',
  IsActive = 'isActive',
  Name = 'name',
  Role = 'role',
  TotalPointsRedeemed = 'totalPointsRedeemed',
  TotalPointsRewarded = 'totalPointsRewarded',
  UpdatedAt = 'updatedAt',
}

export interface Vendor {
  __typename?: 'Vendor';
  address?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
}

export type ReferralsPointsStatsFragment = {
  __typename?: 'ReferralsPointsStats';
} & Pick<ReferralsPointsStats, 'referralsCount' | 'pointsRewarded' | 'balance'>;

export type UserActivitiesQueryVariables = Exact<{
  filter: UserActivitiesFilter;
}>;

export type UserActivitiesQuery = { __typename?: 'Query' } & {
  userActivities: { __typename?: 'ActivityPagination' } & Pick<
    ActivityPagination,
    'count'
  > & {
      items: Array<
        | ({ __typename?: 'Activity' } & Pick<
            Activity,
            'id' | 'createdAt' | 'type'
          >)
        | ({ __typename?: 'GiveRewardActivity' } & Pick<
            GiveRewardActivity,
            'balanceSnapshot' | 'id' | 'createdAt' | 'type'
          > & {
              pointsRewarded: { __typename?: 'PointsRewarded' } & Pick<
                PointsRewarded,
                'value'
              >;
              actor: { __typename?: 'UserPublicProfile' } & Pick<
                UserPublicProfile,
                'name'
              >;
            })
        | ({ __typename?: 'RedeemActivity' } & Pick<
            RedeemActivity,
            'balanceSnapshot' | 'id' | 'createdAt' | 'type'
          > & {
              pointsRedeemed: { __typename?: 'PointsRedeemed' } & Pick<
                PointsRedeemed,
                'value'
              > & {
                  gift: { __typename?: 'Gift' } & GiftFragment;
                  giftPrice: { __typename?: 'GiftPrice' } & Pick<
                    GiftPrice,
                    'value' | 'valueInPoints'
                  >;
                };
            })
        | ({ __typename?: 'ReferralActivity' } & Pick<
            ReferralActivity,
            'id' | 'createdAt' | 'type'
          > & {
              referral: { __typename?: 'Referral' } & Pick<
                Referral,
                | 'candidateName'
                | 'rejectReason'
                | 'hireValue'
                | 'hirePendingEndAt'
              > & {
                  rejectedBy?: Maybe<
                    { __typename?: 'UserPublicProfile' } & Pick<
                      UserPublicProfile,
                      'name'
                    >
                  >;
                  role: { __typename?: 'Role' } & Pick<Role, 'name'>;
                };
            })
        | ({ __typename?: 'RewardActivity' } & Pick<
            RewardActivity,
            'balanceSnapshot' | 'id' | 'createdAt' | 'type'
          > & {
              referral: { __typename?: 'Referral' } & Pick<
                Referral,
                'candidateName' | 'hireValue'
              > & { role: { __typename?: 'Role' } & Pick<Role, 'name'> };
              pointsRewarded: { __typename?: 'PointsRewarded' } & Pick<
                PointsRewarded,
                'value'
              >;
            })
      >;
    };
};

export type CompanyUsersActivitiesQueryVariables = Exact<{
  filter: CompanyUsersActivitiesFilter;
}>;

export type CompanyUsersActivitiesQuery = { __typename?: 'Query' } & {
  companyUsersActivities: { __typename?: 'ActivityPagination' } & Pick<
    ActivityPagination,
    'count'
  > & {
      items: Array<
        | ({ __typename?: 'Activity' } & Pick<
            Activity,
            'id' | 'createdAt' | 'type'
          >)
        | ({ __typename?: 'GiveRewardActivity' } & Pick<
            GiveRewardActivity,
            'id' | 'createdAt' | 'type'
          > & {
              pointsRewarded: { __typename?: 'PointsRewarded' } & Pick<
                PointsRewarded,
                'value'
              >;
              actor: { __typename?: 'UserPublicProfile' } & Pick<
                UserPublicProfile,
                'name'
              >;
            })
        | ({ __typename?: 'RedeemActivity' } & Pick<
            RedeemActivity,
            'balanceSnapshot' | 'id' | 'createdAt' | 'type'
          > & {
              pointsRedeemed: { __typename?: 'PointsRedeemed' } & Pick<
                PointsRedeemed,
                'value'
              > & {
                  gift: { __typename?: 'Gift' } & GiftFragment;
                  giftPrice: { __typename?: 'GiftPrice' } & Pick<
                    GiftPrice,
                    'value' | 'valueInPoints'
                  >;
                };
            })
        | ({ __typename?: 'ReferralActivity' } & Pick<
            ReferralActivity,
            'id' | 'createdAt' | 'type'
          > & {
              referral: { __typename?: 'Referral' } & Pick<
                Referral,
                'candidateName' | 'hireValue' | 'hirePendingEndAt'
              > & {
                  createdBy: { __typename?: 'UserPublicProfile' } & Pick<
                    UserPublicProfile,
                    'name'
                  >;
                  role: { __typename?: 'Role' } & Pick<Role, 'name'>;
                };
            })
        | ({ __typename?: 'RewardActivity' } & Pick<
            RewardActivity,
            'id' | 'createdAt' | 'type'
          > & {
              referral: { __typename?: 'Referral' } & Pick<
                Referral,
                'candidateName' | 'hireValue'
              > & {
                  createdBy: { __typename?: 'UserPublicProfile' } & Pick<
                    UserPublicProfile,
                    'name'
                  >;
                  role: { __typename?: 'Role' } & Pick<Role, 'name'>;
                };
              pointsRewarded: { __typename?: 'PointsRewarded' } & Pick<
                PointsRewarded,
                'value'
              >;
            })
      >;
    };
};

export type UserReferralPointsStatsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type UserReferralPointsStatsQuery = { __typename?: 'Query' } & {
  userReferralPointsStats: {
    __typename?: 'ReferralsPointsStats';
  } & ReferralsPointsStatsFragment;
};

export type CompanyReferralPointsStatsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyReferralPointsStatsQuery = { __typename?: 'Query' } & {
  companyReferralPointsStats: {
    __typename?: 'ReferralsPointsStats';
  } & ReferralsPointsStatsFragment;
};

export type AuthResponseFragment = { __typename?: 'AuthResponse' } & Pick<
  AuthResponse,
  'accessToken' | 'refreshToken' | 'is2FARequired'
> & { user: { __typename?: 'User' } & UserFragment };

export type InitialSetupOtp2FaMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type InitialSetupOtp2FaMutation = { __typename?: 'Mutation' } & {
  initialSetupOTP2FA: { __typename?: 'AuthResponse' } & AuthResponseFragment;
};

export type UpdateOtp2FaMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type UpdateOtp2FaMutation = { __typename?: 'Mutation' } & {
  updateOTP2FA: { __typename?: 'AuthResponse' } & AuthResponseFragment;
};

export type VerifyOtp2FaMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type VerifyOtp2FaMutation = { __typename?: 'Mutation' } & {
  verifyOTP2FA: { __typename?: 'AuthResponse' } & AuthResponseFragment;
};

export type VerifyEmail2FaCodeMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type VerifyEmail2FaCodeMutation = { __typename?: 'Mutation' } & {
  verifyEmail2FACode: { __typename?: 'AuthResponse' } & AuthResponseFragment;
};

export type GenerateOtp2FaMutationVariables = Exact<{ [key: string]: never }>;

export type GenerateOtp2FaMutation = { __typename?: 'Mutation' } & {
  generateOTP2FA: { __typename?: 'OtpResponse' } & Pick<
    OtpResponse,
    'secret' | 'qrCodeDataUrl'
  >;
};

export type InitialSet2FaEmailAddressMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type InitialSet2FaEmailAddressMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'initialSet2FAEmailAddress'>;

export type Set2FaEmailAddressMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type Set2FaEmailAddressMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'set2FAEmailAddress'
>;

export type Send2FaEmailCodeMutationVariables = Exact<{ [key: string]: never }>;

export type Send2FaEmailCodeMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'send2FAEmailCode'
>;

export type InitialSetup2FaEmailMethodMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type InitialSetup2FaEmailMethodMutation = { __typename?: 'Mutation' } & {
  initialSetup2FAEmailMethod: {
    __typename?: 'AuthResponse';
  } & AuthResponseFragment;
};

export type Config2FaEmailMethodMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type Config2FaEmailMethodMutation = { __typename?: 'Mutation' } & {
  config2FAEmailMethod: { __typename?: 'AuthResponse' } & AuthResponseFragment;
};

export type SendPromotionMsgToFragment = {
  __typename?: 'SendPromotionMsgTo';
} & Pick<SendPromotionMsgTo, 'email' | 'slack'>;

export type CampaignFragment = { __typename?: 'CampaignRewardBoost' } & Pick<
  CampaignRewardBoost,
  | 'referralValue'
  | 'hireValue'
  | 'id'
  | 'name'
  | 'status'
  | 'promotionTemplate'
  | 'sendPromotionMsgAt'
  | 'promotionMsgLastSentAt'
  | 'startAt'
  | 'type'
  | 'companyId'
  | 'createdById'
  | 'createdAt'
  | 'updatedAt'
  | 'endAt'
> & {
    sendPromotionMsgTo?: Maybe<
      { __typename?: 'SendPromotionMsgTo' } & SendPromotionMsgToFragment
    >;
    createdBy: { __typename?: 'UserPublicProfile' } & UserPublicProfileFragment;
    roles?: Maybe<Array<{ __typename?: 'Role' } & Pick<Role, 'id' | 'name'>>>;
  };

export type CreateCampaignMutationVariables = Exact<{
  input: CreateCampaignInput;
}>;

export type CreateCampaignMutation = { __typename?: 'Mutation' } & {
  createCampaign: { __typename?: 'CampaignRewardBoost' } & CampaignFragment;
};

export type UpdateCampaignMutationVariables = Exact<{
  campaignId: Scalars['ID'];
  input: UpdateCampaignInput;
}>;

export type UpdateCampaignMutation = { __typename?: 'Mutation' } & {
  updateCampaign: { __typename?: 'CampaignRewardBoost' } & CampaignFragment;
};

export type CompanyCampaignsQueryVariables = Exact<{
  filter: CampaignsFilter;
}>;

export type CompanyCampaignsQuery = { __typename?: 'Query' } & {
  companyCampaigns: { __typename?: 'CampaignPagination' } & Pick<
    CampaignPagination,
    'count'
  > & {
      items: Array<{ __typename?: 'CampaignRewardBoost' } & CampaignFragment>;
    };
};

export type CompanyRunningCampaignsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyRunningCampaignsQuery = { __typename?: 'Query' } & {
  companyRunningCampaigns: Array<
    { __typename?: 'CampaignRewardBoost' } & CampaignFragment
  >;
};

export type CompanyCampaignQueryVariables = Exact<{
  campaignId: Scalars['ID'];
}>;

export type CompanyCampaignQuery = { __typename?: 'Query' } & {
  companyCampaign: { __typename?: 'CampaignRewardBoost' } & CampaignFragment;
};

export type CompanyPublicDetailsFragment = {
  __typename?: 'CompanyPublicDetails';
} & Pick<
  CompanyPublicDetails,
  'id' | 'name' | 'invitationCode' | 'whitelistedDomains' | 'authService'
>;

export type BillingAddressFragment = { __typename?: 'BillingAddress' } & Pick<
  BillingAddress,
  'country' | 'state' | 'city' | 'street' | 'apartment' | 'zip'
>;

export type CompanyFragment = { __typename?: 'Company' } & Pick<
  Company,
  | 'id'
  | 'name'
  | 'country'
  | 'invitationCode'
  | 'whitelistedDomains'
  | 'address'
  | 'authService'
  | 'isActive'
  | 'is2FARequired'
  | 'referralValue'
  | 'hireValue'
  | 'subStatus'
  | 'isSubActive'
  | 'stripeCustomerId'
  | 'billingPeriodStart'
  | 'costPerUserPerMonth'
  | 'subCurrentPeriodEndAt'
  | 'trialExpirationDate'
  | 'pointsConversionValue'
  | 'hireRewardWaitPeriod'
  | 'createdAt'
  | 'updatedAt'
> & {
    billingAddress?: Maybe<
      { __typename?: 'BillingAddress' } & BillingAddressFragment
    >;
  };

export type CompanyStripeCustomerFragment = {
  __typename?: 'CompanyStripeCustomer';
} & Pick<CompanyStripeCustomer, 'id'> & {
    creditCard?: Maybe<
      { __typename?: 'CreditCard' } & Pick<CreditCard, 'id' | 'brand' | 'last4'>
    >;
  };

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;

export type CreateCompanyMutation = { __typename?: 'Mutation' } & {
  createCompany: { __typename?: 'Company' } & CompanyFragment;
};

export type CreateCompanyUnsupportedCountryMutationVariables = Exact<{
  input: CreateCompanyUnsupportedCountryInput;
}>;

export type CreateCompanyUnsupportedCountryMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'createCompanyUnsupportedCountry'>;

export type UpdateCompanyMutationVariables = Exact<{
  input: UpdateCompanyInput;
}>;

export type UpdateCompanyMutation = { __typename?: 'Mutation' } & {
  updateCompany: { __typename?: 'Company' } & CompanyFragment;
};

export type UpdateCompanyBillingAddressMutationVariables = Exact<{
  input: BillingAddressInput;
}>;

export type UpdateCompanyBillingAddressMutation = {
  __typename?: 'Mutation';
} & {
  updateCompanyBillingAddress: { __typename?: 'Company' } & CompanyFragment;
};

export type UpdateCompanyPaymentMethodMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
}>;

export type UpdateCompanyPaymentMethodMutation = { __typename?: 'Mutation' } & {
  updateCompanyPaymentMethod: {
    __typename?: 'CompanyStripeCustomer';
  } & CompanyStripeCustomerFragment;
};

export type CreateCompanySubscriptionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type CreateCompanySubscriptionMutation = { __typename?: 'Mutation' } & {
  createCompanySubscription: { __typename?: 'Company' } & CompanyFragment;
};

export type CurrentUserCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserCompanyQuery = { __typename?: 'Query' } & {
  currentUserCompany?: Maybe<{ __typename?: 'Company' } & CompanyFragment>;
};

export type CompanyPublicDetailsQueryVariables = Exact<{
  invitationCode: Scalars['String'];
}>;

export type CompanyPublicDetailsQuery = { __typename?: 'Query' } & {
  companyPublicDetails: {
    __typename?: 'CompanyPublicDetails';
  } & CompanyPublicDetailsFragment;
};

export type CompanyStripeCustomerQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyStripeCustomerQuery = { __typename?: 'Query' } & {
  companyStripeCustomer?: Maybe<
    { __typename?: 'CompanyStripeCustomer' } & CompanyStripeCustomerFragment
  >;
};

export type CompanyPaymentsQueryVariables = Exact<{
  filter: CompanyPaymentsFilter;
}>;

export type CompanyPaymentsQuery = { __typename?: 'Query' } & {
  companyPayments: { __typename?: 'PaymentPagination' } & Pick<
    PaymentPagination,
    'count'
  > & {
      items: Array<
        | ({ __typename?: 'Payment' } & Pick<
            Payment,
            | 'id'
            | 'value'
            | 'fee'
            | 'currency'
            | 'type'
            | 'status'
            | 'description'
            | 'stripeHostedUrl'
            | 'createdAt'
            | 'updatedAt'
            | 'companyId'
          >)
        | ({ __typename?: 'ReferralPayment' } & Pick<
            ReferralPayment,
            | 'id'
            | 'value'
            | 'fee'
            | 'currency'
            | 'type'
            | 'status'
            | 'description'
            | 'stripeHostedUrl'
            | 'createdAt'
            | 'updatedAt'
            | 'companyId'
          > & {
              referral: { __typename?: 'Referral' } & Pick<
                Referral,
                'candidateName'
              > & {
                  createdBy: { __typename?: 'UserPublicProfile' } & Pick<
                    UserPublicProfile,
                    'name'
                  >;
                  role: { __typename?: 'Role' } & Pick<Role, 'name'>;
                };
            })
      >;
    };
};

export type CompanyIntegrationFragment = {
  __typename?: 'CompanyIntegration';
} & Pick<
  CompanyIntegration,
  | 'id'
  | 'service'
  | 'isActive'
  | 'companyId'
  | 'secretLastSymbols'
  | 'onBehalfOfInput'
  | 'responsibleUserId'
> & {
    responsibleUser: {
      __typename?: 'UserPublicProfile';
    } & UserPublicProfileFragment;
    customFields?: Maybe<
      { __typename?: 'GreenHouseCompanyIntegrationCustomFields' } & Pick<
        GreenHouseCompanyIntegrationCustomFields,
        'jobBoards'
      >
    >;
  };

export type CreateGreenhouseCompanyIntegrationMutationVariables = Exact<{
  input: CreateGreenhouseCompanyIntegrationInput;
}>;

export type CreateGreenhouseCompanyIntegrationMutation = {
  __typename?: 'Mutation';
} & {
  createGreenhouseCompanyIntegration: {
    __typename?: 'CompanyIntegration';
  } & CompanyIntegrationFragment;
};

export type UpdateGreenhouseCompanyIntegrationMutationVariables = Exact<{
  input: UpdateGreenhouseCompanyIntegrationInput;
}>;

export type UpdateGreenhouseCompanyIntegrationMutation = {
  __typename?: 'Mutation';
} & {
  updateGreenhouseCompanyIntegration: {
    __typename?: 'CompanyIntegration';
  } & CompanyIntegrationFragment;
};

export type CreateLeverCompanyIntegrationMutationVariables = Exact<{
  input: CreateLeverCompanyIntegrationInput;
}>;

export type CreateLeverCompanyIntegrationMutation = {
  __typename?: 'Mutation';
} & {
  createLeverCompanyIntegration: {
    __typename?: 'CompanyIntegration';
  } & CompanyIntegrationFragment;
};

export type UpdateLeverCompanyIntegrationMutationVariables = Exact<{
  input: UpdateLeverCompanyIntegrationInput;
}>;

export type UpdateLeverCompanyIntegrationMutation = {
  __typename?: 'Mutation';
} & {
  updateLeverCompanyIntegration: {
    __typename?: 'CompanyIntegration';
  } & CompanyIntegrationFragment;
};

export type CompanyIntegrationsQueryVariables = Exact<{ [key: string]: never }>;

export type CompanyIntegrationsQuery = { __typename?: 'Query' } & {
  companyIntegrations: Array<
    { __typename?: 'CompanyIntegration' } & CompanyIntegrationFragment
  >;
};

export type LeverOAuthUrlQueryVariables = Exact<{ [key: string]: never }>;

export type LeverOAuthUrlQuery = { __typename?: 'Query' } & Pick<
  Query,
  'leverOAuthUrl'
>;

export type DepartmentFragment = { __typename?: 'Department' } & Pick<
  Department,
  'id' | 'name' | 'status' | 'ats'
>;

export type CreateDepartmentMutationVariables = Exact<{
  input: CreateDepartmentInput;
}>;

export type CreateDepartmentMutation = { __typename?: 'Mutation' } & {
  createDepartment: { __typename?: 'Department' } & DepartmentFragment;
};

export type UpdateDepartmentMutationVariables = Exact<{
  departmentId: Scalars['ID'];
  input: UpdateDepartmentInput;
}>;

export type UpdateDepartmentMutation = { __typename?: 'Mutation' } & {
  updateDepartment: { __typename?: 'Department' } & DepartmentFragment;
};

export type CompanyDepartmentsQueryVariables = Exact<{
  filter: DepartmentsFilter;
}>;

export type CompanyDepartmentsQuery = { __typename?: 'Query' } & {
  companyDepartments: { __typename?: 'DepartmentPagination' } & Pick<
    DepartmentPagination,
    'count'
  > & { items: Array<{ __typename?: 'Department' } & DepartmentFragment> };
};

export type GiftFragment = { __typename?: 'Gift' } & Pick<
  Gift,
  | 'id'
  | 'name'
  | 'type'
  | 'imageUrl'
  | 'description'
  | 'createdAt'
  | 'updatedAt'
> & {
    prices: Array<
      { __typename?: 'GiftPrice' } & Pick<
        GiftPrice,
        'id' | 'value' | 'createdAt' | 'updatedAt' | 'valueInPoints'
      >
    >;
    categories: Array<
      { __typename?: 'GiftCategory' } & Pick<GiftCategory, 'id' | 'name'>
    >;
  };

export type ExchangePointsForGiftMutationVariables = Exact<{
  giftPriceId: Scalars['ID'];
  giftId: Scalars['ID'];
}>;

export type ExchangePointsForGiftMutation = { __typename?: 'Mutation' } & {
  exchangePointsForGift: { __typename?: 'PointsRedeemed' } & Pick<
    PointsRedeemed,
    'id' | 'status'
  >;
};

export type CompanyActiveGiftsQueryVariables = Exact<{
  filter: GiftsFilter;
}>;

export type CompanyActiveGiftsQuery = { __typename?: 'Query' } & {
  companyActiveGifts: { __typename?: 'GiftPagination' } & Pick<
    GiftPagination,
    'count'
  > & { items: Array<{ __typename?: 'Gift' } & GiftFragment> };
};

export type AllGiftCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type AllGiftCategoriesQuery = { __typename?: 'Query' } & {
  allGiftCategories: Array<
    | ({ __typename?: 'GiftCategory' } & Pick<GiftCategory, 'id' | 'name'>)
    | { __typename?: 'GiftCategoryWithCount' }
  >;
};

export type AllGiftCategoriesWithCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type AllGiftCategoriesWithCountQuery = { __typename?: 'Query' } & {
  allGiftCategoriesWithCount: Array<
    | { __typename?: 'GiftCategory' }
    | ({ __typename?: 'GiftCategoryWithCount' } & Pick<
        GiftCategoryWithCount,
        'id' | 'name' | 'giftsCount'
      >)
  >;
};

export type GlobalSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GlobalSettingsQuery = { __typename?: 'Query' } & {
  globalSettings: { __typename?: 'GlobalSettings' } & Pick<
    GlobalSettings,
    | 'id'
    | 'defaultReferralValue'
    | 'defaultHireValue'
    | 'minimumReferralValue'
    | 'minimumHireValue'
  >;
};

export type UserLeaderboardFragment = { __typename?: 'UserLeaderboard' } & Pick<
  UserLeaderboard,
  'id' | 'email' | 'name' | 'avatarLink' | 'value'
>;

export type CompanyLeaderboardOnRedemptionsCsvContentMutationVariables = Exact<{
  input: UsersLeaderboardCsvInput;
}>;

export type CompanyLeaderboardOnRedemptionsCsvContentMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'companyLeaderboardOnRedemptionsCSVContent'>;

export type CompanyLeaderboardOnReferralsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyLeaderboardOnReferralsQuery = { __typename?: 'Query' } & {
  companyLeaderboardOnReferrals: Array<
    { __typename?: 'UserLeaderboard' } & UserLeaderboardFragment
  >;
};

export type CompanyLeaderboardOnRedemptionsQueryVariables = Exact<{
  filter: UsersLeaderboardFilter;
}>;

export type CompanyLeaderboardOnRedemptionsQuery = { __typename?: 'Query' } & {
  companyLeaderboardOnRedemptions: {
    __typename?: 'UsersLeaderboardPagination';
  } & Pick<UsersLeaderboardPagination, 'count'> & {
      items: Array<
        { __typename?: 'UserLeaderboard' } & UserLeaderboardFragment
      >;
    };
};

export type PaymentFragment = { __typename?: 'Payment' } & Pick<
  Payment,
  'id' | 'value'
>;

export type GiveRewardPointsByWorkspaceAdminMutationVariables = Exact<{
  userId: Scalars['ID'];
  input: GiveRewardPointsInput;
}>;

export type GiveRewardPointsByWorkspaceAdminMutation = {
  __typename?: 'Mutation';
} & {
  giveRewardPointsByWorkspaceAdmin: {
    __typename?: 'Payment';
  } & PaymentFragment;
};

export type UserPointsBalanceQueryVariables = Exact<{ [key: string]: never }>;

export type UserPointsBalanceQuery = { __typename?: 'Query' } & Pick<
  Query,
  'userPointsBalance'
>;

export type ReferralFragment = { __typename?: 'Referral' } & Pick<
  Referral,
  | 'id'
  | 'candidateName'
  | 'candidateEmail'
  | 'candidateLinkedin'
  | 'referralStatus'
  | 'reason'
  | 'reasonOption'
  | 'rejectReason'
  | 'referralValue'
  | 'hireValue'
  | 'rewardedForReferralValue'
  | 'rewardedForHireValue'
  | 'cvUrl'
  | 'hiredAt'
  | 'hirePendingEndAt'
  | 'createdAt'
  | 'updatedAt'
  | 'createdById'
  | 'rejectedById'
  | 'companyId'
  | 'roleId'
> & {
    createdBy: { __typename?: 'UserPublicProfile' } & UserPublicProfileFragment;
    rejectedBy?: Maybe<
      { __typename?: 'UserPublicProfile' } & UserPublicProfileFragment
    >;
    role: { __typename?: 'Role' } & RoleReferralsColumnFragment;
  };

export type CreateReferralMutationVariables = Exact<{
  input: CreateReferralInput;
}>;

export type CreateReferralMutation = { __typename?: 'Mutation' } & {
  createReferral: { __typename?: 'Referral' } & ReferralFragment;
};

export type AcceptReferralMutationVariables = Exact<{
  referralId: Scalars['ID'];
}>;

export type AcceptReferralMutation = { __typename?: 'Mutation' } & {
  acceptReferral: { __typename?: 'Referral' } & ReferralFragment;
};

export type RejectReferralMutationVariables = Exact<{
  referralId: Scalars['ID'];
  rejectReason?: Maybe<Scalars['String']>;
}>;

export type RejectReferralMutation = { __typename?: 'Mutation' } & {
  rejectReferral: { __typename?: 'Referral' } & ReferralFragment;
};

export type MarkReferralAsHiredMutationVariables = Exact<{
  referralId: Scalars['ID'];
}>;

export type MarkReferralAsHiredMutation = { __typename?: 'Mutation' } & {
  markReferralAsHired: { __typename?: 'Referral' } & ReferralFragment;
};

export type MarkReferralAsHirePendingMutationVariables = Exact<{
  referralId: Scalars['ID'];
}>;

export type MarkReferralAsHirePendingMutation = { __typename?: 'Mutation' } & {
  markReferralAsHirePending: { __typename?: 'Referral' } & ReferralFragment;
};

export type MarkReferralAsHireRejectedMutationVariables = Exact<{
  referralId: Scalars['ID'];
  rejectReason?: Maybe<Scalars['String']>;
}>;

export type MarkReferralAsHireRejectedMutation = { __typename?: 'Mutation' } & {
  markReferralAsHireRejected: { __typename?: 'Referral' } & ReferralFragment;
};

export type CompanyReferralsQueryVariables = Exact<{
  filter: CompanyReferralsFilter;
}>;

export type CompanyReferralsQuery = { __typename?: 'Query' } & {
  companyReferrals: { __typename?: 'ReferralPagination' } & Pick<
    ReferralPagination,
    'count'
  > & { items: Array<{ __typename?: 'Referral' } & ReferralFragment> };
};

export type UserReferralsQueryVariables = Exact<{
  filter: UserReferralsFilter;
}>;

export type UserReferralsQuery = { __typename?: 'Query' } & {
  userReferrals: { __typename?: 'ReferralPagination' } & Pick<
    ReferralPagination,
    'count'
  > & { items: Array<{ __typename?: 'Referral' } & ReferralFragment> };
};

export type ReferralUsersActivitiesQueryVariables = Exact<{
  referralId: Scalars['ID'];
}>;

export type ReferralUsersActivitiesQuery = { __typename?: 'Query' } & {
  referralUsersActivities: Array<
    { __typename?: 'Activity' } & Pick<
      Activity,
      'id' | 'createdAt' | 'type'
    > & {
        actor: { __typename?: 'UserPublicProfile' } & Pick<
          UserPublicProfile,
          'id' | 'name'
        >;
      }
  >;
};

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ForgotPasswordMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'forgotPassword'
>;

export type ResetPasswordByTokenMutationVariables = Exact<{
  password: Scalars['String'];
  resetPwdToken: Scalars['String'];
}>;

export type ResetPasswordByTokenMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'resetPasswordByToken'
>;

export type SetPasswordResetByWorkspaceAdminMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type SetPasswordResetByWorkspaceAdminMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'setPasswordResetByWorkspaceAdmin'>;

export type RoleReferralsColumnFragment = { __typename?: 'Role' } & Pick<
  Role,
  'id' | 'name' | 'referralValue' | 'hireValue'
>;

export type RoleFragment = { __typename?: 'Role' } & Pick<
  Role,
  | 'id'
  | 'name'
  | 'status'
  | 'referralValue'
  | 'hireValue'
  | 'isReferralValueOverridden'
  | 'isHireValueOverridden'
  | 'jobDescriptionLink'
  | 'companyId'
  | 'createdById'
  | 'hiringRepId'
  | 'referralResponsibleId'
  | 'referralsCount'
  | 'createdAt'
  | 'updatedAt'
  | 'ats'
> & {
    createdBy: { __typename?: 'UserPublicProfile' } & UserPublicProfileFragment;
    hiringRep?: Maybe<
      { __typename?: 'UserPublicProfile' } & UserPublicProfileFragment
    >;
    referralResponsible?: Maybe<
      { __typename?: 'UserPublicProfile' } & UserPublicProfileFragment
    >;
    department?: Maybe<{ __typename?: 'Department' } & DepartmentFragment>;
  };

export type CreateRoleMutationVariables = Exact<{
  input: CreateRoleInput;
}>;

export type CreateRoleMutation = { __typename?: 'Mutation' } & {
  createRole: { __typename?: 'Role' } & RoleFragment;
};

export type UpdateRoleMutationVariables = Exact<{
  roleId: Scalars['ID'];
  input: UpdateRoleInput;
}>;

export type UpdateRoleMutation = { __typename?: 'Mutation' } & {
  updateRole: { __typename?: 'Role' } & RoleFragment;
};

export type CompanyRolesQueryVariables = Exact<{
  filter: CompanyRolesFilter;
}>;

export type CompanyRolesQuery = { __typename?: 'Query' } & {
  companyRoles: { __typename?: 'RolesPagination' } & Pick<
    RolesPagination,
    'count'
  > & { items: Array<{ __typename?: 'Role' } & RoleFragment> };
};

export type CompanyActiveRolesQueryVariables = Exact<{
  filter: CompanyActiveRolesFilter;
}>;

export type CompanyActiveRolesQuery = { __typename?: 'Query' } & {
  companyActiveRoles: { __typename?: 'RolesPagination' } & Pick<
    RolesPagination,
    'count'
  > & { items: Array<{ __typename?: 'Role' } & RoleFragment> };
};

export type CompanyActiveRolesCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CompanyActiveRolesCountQuery = { __typename?: 'Query' } & Pick<
  Query,
  'companyActiveRolesCount'
>;

export type ApiVersionQueryVariables = Exact<{ [key: string]: never }>;

export type ApiVersionQuery = { __typename?: 'Query' } & Pick<Query, 'version'>;

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  captchaToken?: Maybe<Scalars['String']>;
}>;

export type LoginMutation = { __typename?: 'Mutation' } & {
  login: { __typename?: 'AuthResponse' } & Pick<
    AuthResponse,
    'accessToken' | 'refreshToken' | 'is2FARequired'
  > & { user: { __typename?: 'User' } & UserFragment };
};

export type LoginWithGoogleMutationVariables = Exact<{
  accessToken: Scalars['String'];
}>;

export type LoginWithGoogleMutation = { __typename?: 'Mutation' } & {
  loginWithGoogle: { __typename?: 'AuthResponse' } & Pick<
    AuthResponse,
    'accessToken' | 'refreshToken'
  > & { user: { __typename?: 'User' } & UserFragment };
};

export type AcceptInviteByTokenMutationVariables = Exact<{
  password: Scalars['String'];
  acceptInviteToken: Scalars['String'];
  captchaToken?: Maybe<Scalars['String']>;
}>;

export type AcceptInviteByTokenMutation = { __typename?: 'Mutation' } & {
  acceptInviteByToken: { __typename?: 'AuthResponse' } & Pick<
    AuthResponse,
    'accessToken' | 'refreshToken'
  > & { user: { __typename?: 'User' } & UserFragment };
};

export type ResendEmailByJwtTokenMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type ResendEmailByJwtTokenMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'resendEmailByJWTToken'
>;

export type SignUpWorkspaceAdminWithPasswordMutationVariables = Exact<{
  input: SignUpWithPasswordInput;
}>;

export type SignUpWorkspaceAdminWithPasswordMutation = {
  __typename?: 'Mutation';
} & {
  signUpWorkspaceAdminWithPassword: { __typename?: 'AuthResponse' } & Pick<
    AuthResponse,
    'accessToken' | 'refreshToken'
  > & { user: { __typename?: 'User' } & UserFragment };
};

export type ResendEmailConfirmationCodeMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ResendEmailConfirmationCodeMutation = {
  __typename?: 'Mutation';
} & Pick<Mutation, 'resendEmailConfirmationCode'>;

export type ConfirmEmailByCodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type ConfirmEmailByCodeMutation = { __typename?: 'Mutation' } & {
  confirmEmailByCode: { __typename?: 'User' } & UserFragment;
};

export type SignUpWorkspaceAdminWithGoogleMutationVariables = Exact<{
  accessToken: Scalars['String'];
}>;

export type SignUpWorkspaceAdminWithGoogleMutation = {
  __typename?: 'Mutation';
} & {
  signUpWorkspaceAdminWithGoogle: { __typename?: 'AuthResponse' } & Pick<
    AuthResponse,
    'accessToken' | 'refreshToken'
  > & { user: { __typename?: 'User' } & UserFragment };
};

export type SignUpUserWithPasswordMutationVariables = Exact<{
  invitationCode: Scalars['String'];
  input: SignUpWithPasswordInput;
}>;

export type SignUpUserWithPasswordMutation = { __typename?: 'Mutation' } & {
  signUpUserWithPassword: { __typename?: 'AuthResponse' } & Pick<
    AuthResponse,
    'accessToken' | 'refreshToken'
  > & { user: { __typename?: 'User' } & UserFragment };
};

export type SignUpUserWithGoogleMutationVariables = Exact<{
  invitationCode: Scalars['String'];
  accessToken: Scalars['String'];
}>;

export type SignUpUserWithGoogleMutation = { __typename?: 'Mutation' } & {
  signUpUserWithGoogle: { __typename?: 'AuthResponse' } & Pick<
    AuthResponse,
    'accessToken' | 'refreshToken'
  > & { user: { __typename?: 'User' } & UserFragment };
};

export type AddressFragment = { __typename?: 'Address' } & Pick<
  Address,
  'country' | 'state' | 'city' | 'street' | 'apartment' | 'zip'
>;

export type UserFragment = { __typename?: 'User' } & Pick<
  User,
  | 'id'
  | 'email'
  | 'name'
  | 'mobilePhone'
  | 'role'
  | 'authService'
  | 'isActive'
  | 'isEmailVerified'
  | 'is2FAEnabled'
  | 'is2FAConfigured'
  | 'email2FA'
  | 'isResetPasswordRequired'
  | 'shippingAddress'
  | 'avatarLink'
  | 'createdAt'
  | 'updatedAt'
> & {
    user2FAMethodItems: Array<
      { __typename?: 'User2FAMethodItem' } & Pick<
        User2FaMethodItem,
        'method' | 'isConfigured' | 'isPreferred'
      >
    >;
    address?: Maybe<{ __typename?: 'Address' } & AddressFragment>;
    company?: Maybe<{ __typename?: 'Company' } & CompanyFragment>;
  };

export type UserPublicProfileFragment = {
  __typename?: 'UserPublicProfile';
} & Pick<
  UserPublicProfile,
  'id' | 'email' | 'name' | 'role' | 'isActive' | 'avatarLink' | 'authService'
>;

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = { __typename?: 'Mutation' } & {
  updateUser: { __typename?: 'User' } & UserFragment;
};

export type SetPreferred2FaMethodMutationVariables = Exact<{
  preferred2FAMethod: User2FaMethod;
}>;

export type SetPreferred2FaMethodMutation = { __typename?: 'Mutation' } & {
  setPreferred2FAMethod: { __typename?: 'User' } & UserFragment;
};

export type Remove2FaMethodMutationVariables = Exact<{
  method: User2FaMethod;
}>;

export type Remove2FaMethodMutation = { __typename?: 'Mutation' } & {
  remove2FAMethod: { __typename?: 'User' } & UserFragment;
};

export type SetUserAvatarMutationVariables = Exact<{
  avatarUpload?: Maybe<Scalars['Upload']>;
}>;

export type SetUserAvatarMutation = { __typename?: 'Mutation' } & {
  setUserAvatar: { __typename?: 'User' } & UserFragment;
};

export type UpdateUserPasswordMutationVariables = Exact<{
  input: SetUserPasswordInput;
}>;

export type UpdateUserPasswordMutation = { __typename?: 'Mutation' } & {
  updateUserPassword: { __typename?: 'User' } & UserFragment;
};

export type CurrentUserQueryVariables = Exact<{ [key: string]: never }>;

export type CurrentUserQuery = { __typename?: 'Query' } & {
  currentUser: { __typename?: 'User' } & UserFragment;
};

export type UsersTableFragmentFragment = {
  __typename?: 'UserPublicProfile';
} & Pick<UserPublicProfile, 'totalPointsRewarded' | 'totalPointsRedeemed'> &
  UserPublicProfileFragment;

export type CreateUserByWorkspaceAdminMutationVariables = Exact<{
  input: CreateUserByWorkspaceAdminInput;
}>;

export type CreateUserByWorkspaceAdminMutation = { __typename?: 'Mutation' } & {
  createUserByWorkspaceAdmin: { __typename?: 'User' } & UserFragment;
};

export type UpdateUserByWorkspaceAdminMutationVariables = Exact<{
  input: UpdateUserByWorkspaceAdminInput;
  userId: Scalars['ID'];
}>;

export type UpdateUserByWorkspaceAdminMutation = { __typename?: 'Mutation' } & {
  updateUserByWorkspaceAdmin: { __typename?: 'User' } & UserFragment;
};

export type CompanyUsersQueryVariables = Exact<{
  filter: UsersFilter;
}>;

export type CompanyUsersQuery = { __typename?: 'Query' } & {
  companyUsers: { __typename?: 'UserPublicProfilesPagination' } & Pick<
    UserPublicProfilesPagination,
    'count'
  > & {
      items: Array<
        { __typename?: 'UserPublicProfile' } & UserPublicProfileFragment
      >;
    };
};

export type CompanyUsersTableQueryVariables = Exact<{
  filter: UsersFilter;
}>;

export type CompanyUsersTableQuery = { __typename?: 'Query' } & {
  companyUsers: { __typename?: 'UserPublicProfilesPagination' } & Pick<
    UserPublicProfilesPagination,
    'count'
  > & {
      items: Array<
        { __typename?: 'UserPublicProfile' } & UsersTableFragmentFragment
      >;
    };
};

export const ReferralsPointsStatsFragmentDoc = gql`
  fragment ReferralsPointsStats on ReferralsPointsStats {
    referralsCount
    pointsRewarded
    balance
  }
`;
export const AddressFragmentDoc = gql`
  fragment Address on Address {
    country
    state
    city
    street
    apartment
    zip
  }
`;
export const BillingAddressFragmentDoc = gql`
  fragment BillingAddress on BillingAddress {
    country
    state
    city
    street
    apartment
    zip
  }
`;
export const CompanyFragmentDoc = gql`
  fragment Company on Company {
    id
    name
    country
    invitationCode
    whitelistedDomains
    address
    authService
    isActive
    is2FARequired
    referralValue
    hireValue
    subStatus
    isSubActive
    stripeCustomerId
    billingPeriodStart
    costPerUserPerMonth
    subStatus
    subCurrentPeriodEndAt
    trialExpirationDate
    pointsConversionValue
    hireRewardWaitPeriod
    createdAt
    updatedAt
    billingAddress {
      ...BillingAddress
    }
  }
  ${BillingAddressFragmentDoc}
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    id
    email
    name
    mobilePhone
    role
    authService
    isActive
    isEmailVerified
    is2FAEnabled
    is2FAConfigured
    email2FA
    user2FAMethodItems {
      method
      isConfigured
      isPreferred
    }
    isResetPasswordRequired
    shippingAddress
    avatarLink
    createdAt
    updatedAt
    address {
      ...Address
    }
    company {
      ...Company
    }
  }
  ${AddressFragmentDoc}
  ${CompanyFragmentDoc}
`;
export const AuthResponseFragmentDoc = gql`
  fragment AuthResponse on AuthResponse {
    accessToken
    refreshToken
    is2FARequired
    user {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export const SendPromotionMsgToFragmentDoc = gql`
  fragment SendPromotionMsgTo on SendPromotionMsgTo {
    email
    slack
  }
`;
export const UserPublicProfileFragmentDoc = gql`
  fragment UserPublicProfile on UserPublicProfile {
    id
    email
    name
    role
    isActive
    avatarLink
    authService
  }
`;
export const CampaignFragmentDoc = gql`
  fragment Campaign on Campaign {
    id
    name
    status
    promotionTemplate
    sendPromotionMsgAt
    promotionMsgLastSentAt
    sendPromotionMsgTo {
      ...SendPromotionMsgTo
    }
    startAt
    type
    companyId
    createdById
    createdBy {
      ...UserPublicProfile
    }
    createdAt
    updatedAt
    endAt
    ... on CampaignRewardBoost {
      referralValue
      hireValue
    }
    roles {
      id
      name
    }
  }
  ${SendPromotionMsgToFragmentDoc}
  ${UserPublicProfileFragmentDoc}
`;
export const CompanyPublicDetailsFragmentDoc = gql`
  fragment CompanyPublicDetails on CompanyPublicDetails {
    id
    name
    invitationCode
    whitelistedDomains
    authService
  }
`;
export const CompanyStripeCustomerFragmentDoc = gql`
  fragment CompanyStripeCustomer on CompanyStripeCustomer {
    id
    creditCard {
      id
      brand
      last4
    }
  }
`;
export const CompanyIntegrationFragmentDoc = gql`
  fragment CompanyIntegration on CompanyIntegration {
    id
    service
    isActive
    companyId
    secretLastSymbols
    onBehalfOfInput
    responsibleUserId
    responsibleUser {
      ...UserPublicProfile
    }
    customFields {
      ... on GreenHouseCompanyIntegrationCustomFields {
        jobBoards
      }
    }
  }
  ${UserPublicProfileFragmentDoc}
`;
export const GiftFragmentDoc = gql`
  fragment Gift on Gift {
    id
    name
    type
    imageUrl
    description
    createdAt
    updatedAt
    prices {
      id
      value
      createdAt
      updatedAt
      valueInPoints
    }
    categories {
      id
      name
    }
  }
`;
export const UserLeaderboardFragmentDoc = gql`
  fragment UserLeaderboard on UserLeaderboard {
    id
    email
    name
    avatarLink
    value
  }
`;
export const PaymentFragmentDoc = gql`
  fragment Payment on Payment {
    id
    value
  }
`;
export const RoleReferralsColumnFragmentDoc = gql`
  fragment RoleReferralsColumn on Role {
    id
    name
    referralValue
    hireValue
  }
`;
export const ReferralFragmentDoc = gql`
  fragment Referral on Referral {
    id
    candidateName
    candidateEmail
    candidateLinkedin
    referralStatus
    reason
    reasonOption
    rejectReason
    referralValue
    hireValue
    rewardedForReferralValue
    rewardedForHireValue
    cvUrl
    hiredAt
    hirePendingEndAt
    createdAt
    updatedAt
    createdById
    rejectedById
    companyId
    roleId
    createdBy {
      ...UserPublicProfile
    }
    rejectedBy {
      ...UserPublicProfile
    }
    role {
      ...RoleReferralsColumn
    }
  }
  ${UserPublicProfileFragmentDoc}
  ${RoleReferralsColumnFragmentDoc}
`;
export const DepartmentFragmentDoc = gql`
  fragment Department on Department {
    id
    name
    status
    ats
  }
`;
export const RoleFragmentDoc = gql`
  fragment Role on Role {
    id
    name
    status
    referralValue
    hireValue
    isReferralValueOverridden
    isHireValueOverridden
    jobDescriptionLink
    companyId
    createdById
    createdBy {
      ...UserPublicProfile
    }
    hiringRepId
    hiringRep {
      ...UserPublicProfile
    }
    referralResponsibleId
    referralResponsible {
      ...UserPublicProfile
    }
    department {
      ...Department
    }
    referralsCount
    createdAt
    updatedAt
    ats
  }
  ${UserPublicProfileFragmentDoc}
  ${DepartmentFragmentDoc}
`;
export const UsersTableFragmentFragmentDoc = gql`
  fragment UsersTableFragment on UserPublicProfile {
    ...UserPublicProfile
    totalPointsRewarded
    totalPointsRedeemed
  }
  ${UserPublicProfileFragmentDoc}
`;
export const UserActivitiesDocument = gql`
  query userActivities($filter: UserActivitiesFilter!) {
    userActivities(filter: $filter) {
      items {
        id
        createdAt
        type
        ... on ReferralActivity {
          referral {
            candidateName
            rejectReason
            hireValue
            hirePendingEndAt
            rejectedBy {
              name
            }
            role {
              name
            }
          }
        }
        ... on RewardActivity {
          balanceSnapshot
          referral {
            candidateName
            hireValue
            role {
              name
            }
          }
          pointsRewarded {
            value
          }
        }
        ... on GiveRewardActivity {
          balanceSnapshot
          pointsRewarded {
            value
          }
          actor {
            name
          }
        }
        ... on RedeemActivity {
          balanceSnapshot
          pointsRedeemed {
            value
            gift {
              ...Gift
            }
            giftPrice {
              value
              valueInPoints
            }
          }
        }
      }
      count
    }
  }
  ${GiftFragmentDoc}
`;

/**
 * __useUserActivitiesQuery__
 *
 * To run a query within a React component, call `useUserActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserActivitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUserActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserActivitiesQuery,
    UserActivitiesQueryVariables
  >
) {
  return Apollo.useQuery<UserActivitiesQuery, UserActivitiesQueryVariables>(
    UserActivitiesDocument,
    baseOptions
  );
}
export function useUserActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserActivitiesQuery,
    UserActivitiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<UserActivitiesQuery, UserActivitiesQueryVariables>(
    UserActivitiesDocument,
    baseOptions
  );
}
export type UserActivitiesQueryHookResult = ReturnType<
  typeof useUserActivitiesQuery
>;
export type UserActivitiesLazyQueryHookResult = ReturnType<
  typeof useUserActivitiesLazyQuery
>;
export type UserActivitiesQueryResult = Apollo.QueryResult<
  UserActivitiesQuery,
  UserActivitiesQueryVariables
>;
export const CompanyUsersActivitiesDocument = gql`
  query companyUsersActivities($filter: CompanyUsersActivitiesFilter!) {
    companyUsersActivities(filter: $filter) {
      items {
        id
        createdAt
        type
        ... on ReferralActivity {
          referral {
            candidateName
            hireValue
            hirePendingEndAt
            createdBy {
              name
            }
            role {
              name
            }
          }
        }
        ... on RewardActivity {
          referral {
            candidateName
            hireValue
            createdBy {
              name
            }
            role {
              name
            }
          }
          pointsRewarded {
            value
          }
        }
        ... on GiveRewardActivity {
          pointsRewarded {
            value
          }
          actor {
            name
          }
        }
        ... on RedeemActivity {
          balanceSnapshot
          pointsRedeemed {
            value
            gift {
              ...Gift
            }
            giftPrice {
              value
              valueInPoints
            }
          }
        }
      }
      count
    }
  }
  ${GiftFragmentDoc}
`;

/**
 * __useCompanyUsersActivitiesQuery__
 *
 * To run a query within a React component, call `useCompanyUsersActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyUsersActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyUsersActivitiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyUsersActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyUsersActivitiesQuery,
    CompanyUsersActivitiesQueryVariables
  >
) {
  return Apollo.useQuery<
    CompanyUsersActivitiesQuery,
    CompanyUsersActivitiesQueryVariables
  >(CompanyUsersActivitiesDocument, baseOptions);
}
export function useCompanyUsersActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyUsersActivitiesQuery,
    CompanyUsersActivitiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyUsersActivitiesQuery,
    CompanyUsersActivitiesQueryVariables
  >(CompanyUsersActivitiesDocument, baseOptions);
}
export type CompanyUsersActivitiesQueryHookResult = ReturnType<
  typeof useCompanyUsersActivitiesQuery
>;
export type CompanyUsersActivitiesLazyQueryHookResult = ReturnType<
  typeof useCompanyUsersActivitiesLazyQuery
>;
export type CompanyUsersActivitiesQueryResult = Apollo.QueryResult<
  CompanyUsersActivitiesQuery,
  CompanyUsersActivitiesQueryVariables
>;
export const UserReferralPointsStatsDocument = gql`
  query userReferralPointsStats {
    userReferralPointsStats {
      ...ReferralsPointsStats
    }
  }
  ${ReferralsPointsStatsFragmentDoc}
`;

/**
 * __useUserReferralPointsStatsQuery__
 *
 * To run a query within a React component, call `useUserReferralPointsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserReferralPointsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserReferralPointsStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserReferralPointsStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserReferralPointsStatsQuery,
    UserReferralPointsStatsQueryVariables
  >
) {
  return Apollo.useQuery<
    UserReferralPointsStatsQuery,
    UserReferralPointsStatsQueryVariables
  >(UserReferralPointsStatsDocument, baseOptions);
}
export function useUserReferralPointsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserReferralPointsStatsQuery,
    UserReferralPointsStatsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UserReferralPointsStatsQuery,
    UserReferralPointsStatsQueryVariables
  >(UserReferralPointsStatsDocument, baseOptions);
}
export type UserReferralPointsStatsQueryHookResult = ReturnType<
  typeof useUserReferralPointsStatsQuery
>;
export type UserReferralPointsStatsLazyQueryHookResult = ReturnType<
  typeof useUserReferralPointsStatsLazyQuery
>;
export type UserReferralPointsStatsQueryResult = Apollo.QueryResult<
  UserReferralPointsStatsQuery,
  UserReferralPointsStatsQueryVariables
>;
export const CompanyReferralPointsStatsDocument = gql`
  query companyReferralPointsStats {
    companyReferralPointsStats {
      ...ReferralsPointsStats
    }
  }
  ${ReferralsPointsStatsFragmentDoc}
`;

/**
 * __useCompanyReferralPointsStatsQuery__
 *
 * To run a query within a React component, call `useCompanyReferralPointsStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyReferralPointsStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyReferralPointsStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyReferralPointsStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyReferralPointsStatsQuery,
    CompanyReferralPointsStatsQueryVariables
  >
) {
  return Apollo.useQuery<
    CompanyReferralPointsStatsQuery,
    CompanyReferralPointsStatsQueryVariables
  >(CompanyReferralPointsStatsDocument, baseOptions);
}
export function useCompanyReferralPointsStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyReferralPointsStatsQuery,
    CompanyReferralPointsStatsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyReferralPointsStatsQuery,
    CompanyReferralPointsStatsQueryVariables
  >(CompanyReferralPointsStatsDocument, baseOptions);
}
export type CompanyReferralPointsStatsQueryHookResult = ReturnType<
  typeof useCompanyReferralPointsStatsQuery
>;
export type CompanyReferralPointsStatsLazyQueryHookResult = ReturnType<
  typeof useCompanyReferralPointsStatsLazyQuery
>;
export type CompanyReferralPointsStatsQueryResult = Apollo.QueryResult<
  CompanyReferralPointsStatsQuery,
  CompanyReferralPointsStatsQueryVariables
>;
export const InitialSetupOtp2FaDocument = gql`
  mutation initialSetupOTP2FA($token: String!) {
    initialSetupOTP2FA(token: $token) {
      ...AuthResponse
    }
  }
  ${AuthResponseFragmentDoc}
`;
export type InitialSetupOtp2FaMutationFn = Apollo.MutationFunction<
  InitialSetupOtp2FaMutation,
  InitialSetupOtp2FaMutationVariables
>;

/**
 * __useInitialSetupOtp2FaMutation__
 *
 * To run a mutation, you first call `useInitialSetupOtp2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitialSetupOtp2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initialSetupOtp2FaMutation, { data, loading, error }] = useInitialSetupOtp2FaMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useInitialSetupOtp2FaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitialSetupOtp2FaMutation,
    InitialSetupOtp2FaMutationVariables
  >
) {
  return Apollo.useMutation<
    InitialSetupOtp2FaMutation,
    InitialSetupOtp2FaMutationVariables
  >(InitialSetupOtp2FaDocument, baseOptions);
}
export type InitialSetupOtp2FaMutationHookResult = ReturnType<
  typeof useInitialSetupOtp2FaMutation
>;
export type InitialSetupOtp2FaMutationResult = Apollo.MutationResult<InitialSetupOtp2FaMutation>;
export type InitialSetupOtp2FaMutationOptions = Apollo.BaseMutationOptions<
  InitialSetupOtp2FaMutation,
  InitialSetupOtp2FaMutationVariables
>;
export const UpdateOtp2FaDocument = gql`
  mutation updateOTP2FA($token: String!) {
    updateOTP2FA(token: $token) {
      ...AuthResponse
    }
  }
  ${AuthResponseFragmentDoc}
`;
export type UpdateOtp2FaMutationFn = Apollo.MutationFunction<
  UpdateOtp2FaMutation,
  UpdateOtp2FaMutationVariables
>;

/**
 * __useUpdateOtp2FaMutation__
 *
 * To run a mutation, you first call `useUpdateOtp2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOtp2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOtp2FaMutation, { data, loading, error }] = useUpdateOtp2FaMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useUpdateOtp2FaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOtp2FaMutation,
    UpdateOtp2FaMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateOtp2FaMutation,
    UpdateOtp2FaMutationVariables
  >(UpdateOtp2FaDocument, baseOptions);
}
export type UpdateOtp2FaMutationHookResult = ReturnType<
  typeof useUpdateOtp2FaMutation
>;
export type UpdateOtp2FaMutationResult = Apollo.MutationResult<UpdateOtp2FaMutation>;
export type UpdateOtp2FaMutationOptions = Apollo.BaseMutationOptions<
  UpdateOtp2FaMutation,
  UpdateOtp2FaMutationVariables
>;
export const VerifyOtp2FaDocument = gql`
  mutation verifyOTP2FA($token: String!) {
    verifyOTP2FA(token: $token) {
      ...AuthResponse
    }
  }
  ${AuthResponseFragmentDoc}
`;
export type VerifyOtp2FaMutationFn = Apollo.MutationFunction<
  VerifyOtp2FaMutation,
  VerifyOtp2FaMutationVariables
>;

/**
 * __useVerifyOtp2FaMutation__
 *
 * To run a mutation, you first call `useVerifyOtp2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtp2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOtp2FaMutation, { data, loading, error }] = useVerifyOtp2FaMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyOtp2FaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyOtp2FaMutation,
    VerifyOtp2FaMutationVariables
  >
) {
  return Apollo.useMutation<
    VerifyOtp2FaMutation,
    VerifyOtp2FaMutationVariables
  >(VerifyOtp2FaDocument, baseOptions);
}
export type VerifyOtp2FaMutationHookResult = ReturnType<
  typeof useVerifyOtp2FaMutation
>;
export type VerifyOtp2FaMutationResult = Apollo.MutationResult<VerifyOtp2FaMutation>;
export type VerifyOtp2FaMutationOptions = Apollo.BaseMutationOptions<
  VerifyOtp2FaMutation,
  VerifyOtp2FaMutationVariables
>;
export const VerifyEmail2FaCodeDocument = gql`
  mutation verifyEmail2FACode($token: String!) {
    verifyEmail2FACode(token: $token) {
      ...AuthResponse
    }
  }
  ${AuthResponseFragmentDoc}
`;
export type VerifyEmail2FaCodeMutationFn = Apollo.MutationFunction<
  VerifyEmail2FaCodeMutation,
  VerifyEmail2FaCodeMutationVariables
>;

/**
 * __useVerifyEmail2FaCodeMutation__
 *
 * To run a mutation, you first call `useVerifyEmail2FaCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmail2FaCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmail2FaCodeMutation, { data, loading, error }] = useVerifyEmail2FaCodeMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyEmail2FaCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyEmail2FaCodeMutation,
    VerifyEmail2FaCodeMutationVariables
  >
) {
  return Apollo.useMutation<
    VerifyEmail2FaCodeMutation,
    VerifyEmail2FaCodeMutationVariables
  >(VerifyEmail2FaCodeDocument, baseOptions);
}
export type VerifyEmail2FaCodeMutationHookResult = ReturnType<
  typeof useVerifyEmail2FaCodeMutation
>;
export type VerifyEmail2FaCodeMutationResult = Apollo.MutationResult<VerifyEmail2FaCodeMutation>;
export type VerifyEmail2FaCodeMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmail2FaCodeMutation,
  VerifyEmail2FaCodeMutationVariables
>;
export const GenerateOtp2FaDocument = gql`
  mutation generateOTP2FA {
    generateOTP2FA {
      secret
      qrCodeDataUrl
    }
  }
`;
export type GenerateOtp2FaMutationFn = Apollo.MutationFunction<
  GenerateOtp2FaMutation,
  GenerateOtp2FaMutationVariables
>;

/**
 * __useGenerateOtp2FaMutation__
 *
 * To run a mutation, you first call `useGenerateOtp2FaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateOtp2FaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateOtp2FaMutation, { data, loading, error }] = useGenerateOtp2FaMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateOtp2FaMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateOtp2FaMutation,
    GenerateOtp2FaMutationVariables
  >
) {
  return Apollo.useMutation<
    GenerateOtp2FaMutation,
    GenerateOtp2FaMutationVariables
  >(GenerateOtp2FaDocument, baseOptions);
}
export type GenerateOtp2FaMutationHookResult = ReturnType<
  typeof useGenerateOtp2FaMutation
>;
export type GenerateOtp2FaMutationResult = Apollo.MutationResult<GenerateOtp2FaMutation>;
export type GenerateOtp2FaMutationOptions = Apollo.BaseMutationOptions<
  GenerateOtp2FaMutation,
  GenerateOtp2FaMutationVariables
>;
export const InitialSet2FaEmailAddressDocument = gql`
  mutation initialSet2FAEmailAddress($email: String!) {
    initialSet2FAEmailAddress(email: $email)
  }
`;
export type InitialSet2FaEmailAddressMutationFn = Apollo.MutationFunction<
  InitialSet2FaEmailAddressMutation,
  InitialSet2FaEmailAddressMutationVariables
>;

/**
 * __useInitialSet2FaEmailAddressMutation__
 *
 * To run a mutation, you first call `useInitialSet2FaEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitialSet2FaEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initialSet2FaEmailAddressMutation, { data, loading, error }] = useInitialSet2FaEmailAddressMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInitialSet2FaEmailAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitialSet2FaEmailAddressMutation,
    InitialSet2FaEmailAddressMutationVariables
  >
) {
  return Apollo.useMutation<
    InitialSet2FaEmailAddressMutation,
    InitialSet2FaEmailAddressMutationVariables
  >(InitialSet2FaEmailAddressDocument, baseOptions);
}
export type InitialSet2FaEmailAddressMutationHookResult = ReturnType<
  typeof useInitialSet2FaEmailAddressMutation
>;
export type InitialSet2FaEmailAddressMutationResult = Apollo.MutationResult<InitialSet2FaEmailAddressMutation>;
export type InitialSet2FaEmailAddressMutationOptions = Apollo.BaseMutationOptions<
  InitialSet2FaEmailAddressMutation,
  InitialSet2FaEmailAddressMutationVariables
>;
export const Set2FaEmailAddressDocument = gql`
  mutation set2FAEmailAddress($email: String!) {
    set2FAEmailAddress(email: $email)
  }
`;
export type Set2FaEmailAddressMutationFn = Apollo.MutationFunction<
  Set2FaEmailAddressMutation,
  Set2FaEmailAddressMutationVariables
>;

/**
 * __useSet2FaEmailAddressMutation__
 *
 * To run a mutation, you first call `useSet2FaEmailAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSet2FaEmailAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [set2FaEmailAddressMutation, { data, loading, error }] = useSet2FaEmailAddressMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSet2FaEmailAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Set2FaEmailAddressMutation,
    Set2FaEmailAddressMutationVariables
  >
) {
  return Apollo.useMutation<
    Set2FaEmailAddressMutation,
    Set2FaEmailAddressMutationVariables
  >(Set2FaEmailAddressDocument, baseOptions);
}
export type Set2FaEmailAddressMutationHookResult = ReturnType<
  typeof useSet2FaEmailAddressMutation
>;
export type Set2FaEmailAddressMutationResult = Apollo.MutationResult<Set2FaEmailAddressMutation>;
export type Set2FaEmailAddressMutationOptions = Apollo.BaseMutationOptions<
  Set2FaEmailAddressMutation,
  Set2FaEmailAddressMutationVariables
>;
export const Send2FaEmailCodeDocument = gql`
  mutation send2FAEmailCode {
    send2FAEmailCode
  }
`;
export type Send2FaEmailCodeMutationFn = Apollo.MutationFunction<
  Send2FaEmailCodeMutation,
  Send2FaEmailCodeMutationVariables
>;

/**
 * __useSend2FaEmailCodeMutation__
 *
 * To run a mutation, you first call `useSend2FaEmailCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSend2FaEmailCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [send2FaEmailCodeMutation, { data, loading, error }] = useSend2FaEmailCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useSend2FaEmailCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Send2FaEmailCodeMutation,
    Send2FaEmailCodeMutationVariables
  >
) {
  return Apollo.useMutation<
    Send2FaEmailCodeMutation,
    Send2FaEmailCodeMutationVariables
  >(Send2FaEmailCodeDocument, baseOptions);
}
export type Send2FaEmailCodeMutationHookResult = ReturnType<
  typeof useSend2FaEmailCodeMutation
>;
export type Send2FaEmailCodeMutationResult = Apollo.MutationResult<Send2FaEmailCodeMutation>;
export type Send2FaEmailCodeMutationOptions = Apollo.BaseMutationOptions<
  Send2FaEmailCodeMutation,
  Send2FaEmailCodeMutationVariables
>;
export const InitialSetup2FaEmailMethodDocument = gql`
  mutation initialSetup2FAEmailMethod($token: String!) {
    initialSetup2FAEmailMethod(token: $token) {
      ...AuthResponse
    }
  }
  ${AuthResponseFragmentDoc}
`;
export type InitialSetup2FaEmailMethodMutationFn = Apollo.MutationFunction<
  InitialSetup2FaEmailMethodMutation,
  InitialSetup2FaEmailMethodMutationVariables
>;

/**
 * __useInitialSetup2FaEmailMethodMutation__
 *
 * To run a mutation, you first call `useInitialSetup2FaEmailMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitialSetup2FaEmailMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initialSetup2FaEmailMethodMutation, { data, loading, error }] = useInitialSetup2FaEmailMethodMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useInitialSetup2FaEmailMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitialSetup2FaEmailMethodMutation,
    InitialSetup2FaEmailMethodMutationVariables
  >
) {
  return Apollo.useMutation<
    InitialSetup2FaEmailMethodMutation,
    InitialSetup2FaEmailMethodMutationVariables
  >(InitialSetup2FaEmailMethodDocument, baseOptions);
}
export type InitialSetup2FaEmailMethodMutationHookResult = ReturnType<
  typeof useInitialSetup2FaEmailMethodMutation
>;
export type InitialSetup2FaEmailMethodMutationResult = Apollo.MutationResult<InitialSetup2FaEmailMethodMutation>;
export type InitialSetup2FaEmailMethodMutationOptions = Apollo.BaseMutationOptions<
  InitialSetup2FaEmailMethodMutation,
  InitialSetup2FaEmailMethodMutationVariables
>;
export const Config2FaEmailMethodDocument = gql`
  mutation config2FAEmailMethod($token: String!) {
    config2FAEmailMethod(token: $token) {
      ...AuthResponse
    }
  }
  ${AuthResponseFragmentDoc}
`;
export type Config2FaEmailMethodMutationFn = Apollo.MutationFunction<
  Config2FaEmailMethodMutation,
  Config2FaEmailMethodMutationVariables
>;

/**
 * __useConfig2FaEmailMethodMutation__
 *
 * To run a mutation, you first call `useConfig2FaEmailMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfig2FaEmailMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [config2FaEmailMethodMutation, { data, loading, error }] = useConfig2FaEmailMethodMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useConfig2FaEmailMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Config2FaEmailMethodMutation,
    Config2FaEmailMethodMutationVariables
  >
) {
  return Apollo.useMutation<
    Config2FaEmailMethodMutation,
    Config2FaEmailMethodMutationVariables
  >(Config2FaEmailMethodDocument, baseOptions);
}
export type Config2FaEmailMethodMutationHookResult = ReturnType<
  typeof useConfig2FaEmailMethodMutation
>;
export type Config2FaEmailMethodMutationResult = Apollo.MutationResult<Config2FaEmailMethodMutation>;
export type Config2FaEmailMethodMutationOptions = Apollo.BaseMutationOptions<
  Config2FaEmailMethodMutation,
  Config2FaEmailMethodMutationVariables
>;
export const CreateCampaignDocument = gql`
  mutation createCampaign($input: CreateCampaignInput!) {
    createCampaign(input: $input) {
      ...Campaign
    }
  }
  ${CampaignFragmentDoc}
`;
export type CreateCampaignMutationFn = Apollo.MutationFunction<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>;

/**
 * __useCreateCampaignMutation__
 *
 * To run a mutation, you first call `useCreateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignMutation, { data, loading, error }] = useCreateCampaignMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateCampaignMutation,
    CreateCampaignMutationVariables
  >(CreateCampaignDocument, baseOptions);
}
export type CreateCampaignMutationHookResult = ReturnType<
  typeof useCreateCampaignMutation
>;
export type CreateCampaignMutationResult = Apollo.MutationResult<CreateCampaignMutation>;
export type CreateCampaignMutationOptions = Apollo.BaseMutationOptions<
  CreateCampaignMutation,
  CreateCampaignMutationVariables
>;
export const UpdateCampaignDocument = gql`
  mutation updateCampaign($campaignId: ID!, $input: UpdateCampaignInput!) {
    updateCampaign(campaignId: $campaignId, input: $input) {
      ...Campaign
    }
  }
  ${CampaignFragmentDoc}
`;
export type UpdateCampaignMutationFn = Apollo.MutationFunction<
  UpdateCampaignMutation,
  UpdateCampaignMutationVariables
>;

/**
 * __useUpdateCampaignMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignMutation, { data, loading, error }] = useUpdateCampaignMutation({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCampaignMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCampaignMutation,
    UpdateCampaignMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateCampaignMutation,
    UpdateCampaignMutationVariables
  >(UpdateCampaignDocument, baseOptions);
}
export type UpdateCampaignMutationHookResult = ReturnType<
  typeof useUpdateCampaignMutation
>;
export type UpdateCampaignMutationResult = Apollo.MutationResult<UpdateCampaignMutation>;
export type UpdateCampaignMutationOptions = Apollo.BaseMutationOptions<
  UpdateCampaignMutation,
  UpdateCampaignMutationVariables
>;
export const CompanyCampaignsDocument = gql`
  query companyCampaigns($filter: CampaignsFilter!) {
    companyCampaigns(filter: $filter) {
      items {
        ...Campaign
      }
      count
    }
  }
  ${CampaignFragmentDoc}
`;

/**
 * __useCompanyCampaignsQuery__
 *
 * To run a query within a React component, call `useCompanyCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyCampaignsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyCampaignsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyCampaignsQuery,
    CompanyCampaignsQueryVariables
  >
) {
  return Apollo.useQuery<CompanyCampaignsQuery, CompanyCampaignsQueryVariables>(
    CompanyCampaignsDocument,
    baseOptions
  );
}
export function useCompanyCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyCampaignsQuery,
    CompanyCampaignsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyCampaignsQuery,
    CompanyCampaignsQueryVariables
  >(CompanyCampaignsDocument, baseOptions);
}
export type CompanyCampaignsQueryHookResult = ReturnType<
  typeof useCompanyCampaignsQuery
>;
export type CompanyCampaignsLazyQueryHookResult = ReturnType<
  typeof useCompanyCampaignsLazyQuery
>;
export type CompanyCampaignsQueryResult = Apollo.QueryResult<
  CompanyCampaignsQuery,
  CompanyCampaignsQueryVariables
>;
export const CompanyRunningCampaignsDocument = gql`
  query companyRunningCampaigns {
    companyRunningCampaigns {
      ...Campaign
    }
  }
  ${CampaignFragmentDoc}
`;

/**
 * __useCompanyRunningCampaignsQuery__
 *
 * To run a query within a React component, call `useCompanyRunningCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRunningCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRunningCampaignsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyRunningCampaignsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyRunningCampaignsQuery,
    CompanyRunningCampaignsQueryVariables
  >
) {
  return Apollo.useQuery<
    CompanyRunningCampaignsQuery,
    CompanyRunningCampaignsQueryVariables
  >(CompanyRunningCampaignsDocument, baseOptions);
}
export function useCompanyRunningCampaignsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyRunningCampaignsQuery,
    CompanyRunningCampaignsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyRunningCampaignsQuery,
    CompanyRunningCampaignsQueryVariables
  >(CompanyRunningCampaignsDocument, baseOptions);
}
export type CompanyRunningCampaignsQueryHookResult = ReturnType<
  typeof useCompanyRunningCampaignsQuery
>;
export type CompanyRunningCampaignsLazyQueryHookResult = ReturnType<
  typeof useCompanyRunningCampaignsLazyQuery
>;
export type CompanyRunningCampaignsQueryResult = Apollo.QueryResult<
  CompanyRunningCampaignsQuery,
  CompanyRunningCampaignsQueryVariables
>;
export const CompanyCampaignDocument = gql`
  query companyCampaign($campaignId: ID!) {
    companyCampaign(campaignId: $campaignId) {
      ...Campaign
    }
  }
  ${CampaignFragmentDoc}
`;

/**
 * __useCompanyCampaignQuery__
 *
 * To run a query within a React component, call `useCompanyCampaignQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyCampaignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyCampaignQuery({
 *   variables: {
 *      campaignId: // value for 'campaignId'
 *   },
 * });
 */
export function useCompanyCampaignQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyCampaignQuery,
    CompanyCampaignQueryVariables
  >
) {
  return Apollo.useQuery<CompanyCampaignQuery, CompanyCampaignQueryVariables>(
    CompanyCampaignDocument,
    baseOptions
  );
}
export function useCompanyCampaignLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyCampaignQuery,
    CompanyCampaignQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyCampaignQuery,
    CompanyCampaignQueryVariables
  >(CompanyCampaignDocument, baseOptions);
}
export type CompanyCampaignQueryHookResult = ReturnType<
  typeof useCompanyCampaignQuery
>;
export type CompanyCampaignLazyQueryHookResult = ReturnType<
  typeof useCompanyCampaignLazyQuery
>;
export type CompanyCampaignQueryResult = Apollo.QueryResult<
  CompanyCampaignQuery,
  CompanyCampaignQueryVariables
>;
export const CreateCompanyDocument = gql`
  mutation createCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      ...Company
    }
  }
  ${CompanyFragmentDoc}
`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateCompanyMutation,
    CreateCompanyMutationVariables
  >(CreateCompanyDocument, baseOptions);
}
export type CreateCompanyMutationHookResult = ReturnType<
  typeof useCreateCompanyMutation
>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyMutation,
  CreateCompanyMutationVariables
>;
export const CreateCompanyUnsupportedCountryDocument = gql`
  mutation createCompanyUnsupportedCountry(
    $input: CreateCompanyUnsupportedCountryInput!
  ) {
    createCompanyUnsupportedCountry(input: $input)
  }
`;
export type CreateCompanyUnsupportedCountryMutationFn = Apollo.MutationFunction<
  CreateCompanyUnsupportedCountryMutation,
  CreateCompanyUnsupportedCountryMutationVariables
>;

/**
 * __useCreateCompanyUnsupportedCountryMutation__
 *
 * To run a mutation, you first call `useCreateCompanyUnsupportedCountryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyUnsupportedCountryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyUnsupportedCountryMutation, { data, loading, error }] = useCreateCompanyUnsupportedCountryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyUnsupportedCountryMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanyUnsupportedCountryMutation,
    CreateCompanyUnsupportedCountryMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateCompanyUnsupportedCountryMutation,
    CreateCompanyUnsupportedCountryMutationVariables
  >(CreateCompanyUnsupportedCountryDocument, baseOptions);
}
export type CreateCompanyUnsupportedCountryMutationHookResult = ReturnType<
  typeof useCreateCompanyUnsupportedCountryMutation
>;
export type CreateCompanyUnsupportedCountryMutationResult = Apollo.MutationResult<CreateCompanyUnsupportedCountryMutation>;
export type CreateCompanyUnsupportedCountryMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanyUnsupportedCountryMutation,
  CreateCompanyUnsupportedCountryMutationVariables
>;
export const UpdateCompanyDocument = gql`
  mutation updateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      ...Company
    }
  }
  ${CompanyFragmentDoc}
`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateCompanyMutation,
    UpdateCompanyMutationVariables
  >(UpdateCompanyDocument, baseOptions);
}
export type UpdateCompanyMutationHookResult = ReturnType<
  typeof useUpdateCompanyMutation
>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyMutation,
  UpdateCompanyMutationVariables
>;
export const UpdateCompanyBillingAddressDocument = gql`
  mutation updateCompanyBillingAddress($input: BillingAddressInput!) {
    updateCompanyBillingAddress(input: $input) {
      ...Company
    }
  }
  ${CompanyFragmentDoc}
`;
export type UpdateCompanyBillingAddressMutationFn = Apollo.MutationFunction<
  UpdateCompanyBillingAddressMutation,
  UpdateCompanyBillingAddressMutationVariables
>;

/**
 * __useUpdateCompanyBillingAddressMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyBillingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyBillingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyBillingAddressMutation, { data, loading, error }] = useUpdateCompanyBillingAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyBillingAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyBillingAddressMutation,
    UpdateCompanyBillingAddressMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateCompanyBillingAddressMutation,
    UpdateCompanyBillingAddressMutationVariables
  >(UpdateCompanyBillingAddressDocument, baseOptions);
}
export type UpdateCompanyBillingAddressMutationHookResult = ReturnType<
  typeof useUpdateCompanyBillingAddressMutation
>;
export type UpdateCompanyBillingAddressMutationResult = Apollo.MutationResult<UpdateCompanyBillingAddressMutation>;
export type UpdateCompanyBillingAddressMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyBillingAddressMutation,
  UpdateCompanyBillingAddressMutationVariables
>;
export const UpdateCompanyPaymentMethodDocument = gql`
  mutation updateCompanyPaymentMethod($paymentMethodId: String!) {
    updateCompanyPaymentMethod(paymentMethodId: $paymentMethodId) {
      ...CompanyStripeCustomer
    }
  }
  ${CompanyStripeCustomerFragmentDoc}
`;
export type UpdateCompanyPaymentMethodMutationFn = Apollo.MutationFunction<
  UpdateCompanyPaymentMethodMutation,
  UpdateCompanyPaymentMethodMutationVariables
>;

/**
 * __useUpdateCompanyPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyPaymentMethodMutation, { data, loading, error }] = useUpdateCompanyPaymentMethodMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *   },
 * });
 */
export function useUpdateCompanyPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCompanyPaymentMethodMutation,
    UpdateCompanyPaymentMethodMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateCompanyPaymentMethodMutation,
    UpdateCompanyPaymentMethodMutationVariables
  >(UpdateCompanyPaymentMethodDocument, baseOptions);
}
export type UpdateCompanyPaymentMethodMutationHookResult = ReturnType<
  typeof useUpdateCompanyPaymentMethodMutation
>;
export type UpdateCompanyPaymentMethodMutationResult = Apollo.MutationResult<UpdateCompanyPaymentMethodMutation>;
export type UpdateCompanyPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  UpdateCompanyPaymentMethodMutation,
  UpdateCompanyPaymentMethodMutationVariables
>;
export const CreateCompanySubscriptionDocument = gql`
  mutation createCompanySubscription {
    createCompanySubscription {
      ...Company
    }
  }
  ${CompanyFragmentDoc}
`;
export type CreateCompanySubscriptionMutationFn = Apollo.MutationFunction<
  CreateCompanySubscriptionMutation,
  CreateCompanySubscriptionMutationVariables
>;

/**
 * __useCreateCompanySubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateCompanySubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanySubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanySubscriptionMutation, { data, loading, error }] = useCreateCompanySubscriptionMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateCompanySubscriptionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCompanySubscriptionMutation,
    CreateCompanySubscriptionMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateCompanySubscriptionMutation,
    CreateCompanySubscriptionMutationVariables
  >(CreateCompanySubscriptionDocument, baseOptions);
}
export type CreateCompanySubscriptionMutationHookResult = ReturnType<
  typeof useCreateCompanySubscriptionMutation
>;
export type CreateCompanySubscriptionMutationResult = Apollo.MutationResult<CreateCompanySubscriptionMutation>;
export type CreateCompanySubscriptionMutationOptions = Apollo.BaseMutationOptions<
  CreateCompanySubscriptionMutation,
  CreateCompanySubscriptionMutationVariables
>;
export const CurrentUserCompanyDocument = gql`
  query currentUserCompany {
    currentUserCompany {
      ...Company
    }
  }
  ${CompanyFragmentDoc}
`;

/**
 * __useCurrentUserCompanyQuery__
 *
 * To run a query within a React component, call `useCurrentUserCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserCompanyQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserCompanyQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserCompanyQuery,
    CurrentUserCompanyQueryVariables
  >
) {
  return Apollo.useQuery<
    CurrentUserCompanyQuery,
    CurrentUserCompanyQueryVariables
  >(CurrentUserCompanyDocument, baseOptions);
}
export function useCurrentUserCompanyLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserCompanyQuery,
    CurrentUserCompanyQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CurrentUserCompanyQuery,
    CurrentUserCompanyQueryVariables
  >(CurrentUserCompanyDocument, baseOptions);
}
export type CurrentUserCompanyQueryHookResult = ReturnType<
  typeof useCurrentUserCompanyQuery
>;
export type CurrentUserCompanyLazyQueryHookResult = ReturnType<
  typeof useCurrentUserCompanyLazyQuery
>;
export type CurrentUserCompanyQueryResult = Apollo.QueryResult<
  CurrentUserCompanyQuery,
  CurrentUserCompanyQueryVariables
>;
export const CompanyPublicDetailsDocument = gql`
  query companyPublicDetails($invitationCode: String!) {
    companyPublicDetails(invitationCode: $invitationCode) {
      ...CompanyPublicDetails
    }
  }
  ${CompanyPublicDetailsFragmentDoc}
`;

/**
 * __useCompanyPublicDetailsQuery__
 *
 * To run a query within a React component, call `useCompanyPublicDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPublicDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyPublicDetailsQuery({
 *   variables: {
 *      invitationCode: // value for 'invitationCode'
 *   },
 * });
 */
export function useCompanyPublicDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyPublicDetailsQuery,
    CompanyPublicDetailsQueryVariables
  >
) {
  return Apollo.useQuery<
    CompanyPublicDetailsQuery,
    CompanyPublicDetailsQueryVariables
  >(CompanyPublicDetailsDocument, baseOptions);
}
export function useCompanyPublicDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyPublicDetailsQuery,
    CompanyPublicDetailsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyPublicDetailsQuery,
    CompanyPublicDetailsQueryVariables
  >(CompanyPublicDetailsDocument, baseOptions);
}
export type CompanyPublicDetailsQueryHookResult = ReturnType<
  typeof useCompanyPublicDetailsQuery
>;
export type CompanyPublicDetailsLazyQueryHookResult = ReturnType<
  typeof useCompanyPublicDetailsLazyQuery
>;
export type CompanyPublicDetailsQueryResult = Apollo.QueryResult<
  CompanyPublicDetailsQuery,
  CompanyPublicDetailsQueryVariables
>;
export const CompanyStripeCustomerDocument = gql`
  query companyStripeCustomer {
    companyStripeCustomer {
      ...CompanyStripeCustomer
    }
  }
  ${CompanyStripeCustomerFragmentDoc}
`;

/**
 * __useCompanyStripeCustomerQuery__
 *
 * To run a query within a React component, call `useCompanyStripeCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyStripeCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyStripeCustomerQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyStripeCustomerQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyStripeCustomerQuery,
    CompanyStripeCustomerQueryVariables
  >
) {
  return Apollo.useQuery<
    CompanyStripeCustomerQuery,
    CompanyStripeCustomerQueryVariables
  >(CompanyStripeCustomerDocument, baseOptions);
}
export function useCompanyStripeCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyStripeCustomerQuery,
    CompanyStripeCustomerQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyStripeCustomerQuery,
    CompanyStripeCustomerQueryVariables
  >(CompanyStripeCustomerDocument, baseOptions);
}
export type CompanyStripeCustomerQueryHookResult = ReturnType<
  typeof useCompanyStripeCustomerQuery
>;
export type CompanyStripeCustomerLazyQueryHookResult = ReturnType<
  typeof useCompanyStripeCustomerLazyQuery
>;
export type CompanyStripeCustomerQueryResult = Apollo.QueryResult<
  CompanyStripeCustomerQuery,
  CompanyStripeCustomerQueryVariables
>;
export const CompanyPaymentsDocument = gql`
  query companyPayments($filter: CompanyPaymentsFilter!) {
    companyPayments(filter: $filter) {
      items {
        id
        value
        fee
        currency
        type
        status
        description
        stripeHostedUrl
        createdAt
        updatedAt
        companyId
        ... on ReferralPayment {
          referral {
            candidateName
            createdBy {
              name
            }
            role {
              name
            }
          }
        }
      }
      count
    }
  }
`;

/**
 * __useCompanyPaymentsQuery__
 *
 * To run a query within a React component, call `useCompanyPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyPaymentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyPaymentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyPaymentsQuery,
    CompanyPaymentsQueryVariables
  >
) {
  return Apollo.useQuery<CompanyPaymentsQuery, CompanyPaymentsQueryVariables>(
    CompanyPaymentsDocument,
    baseOptions
  );
}
export function useCompanyPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyPaymentsQuery,
    CompanyPaymentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyPaymentsQuery,
    CompanyPaymentsQueryVariables
  >(CompanyPaymentsDocument, baseOptions);
}
export type CompanyPaymentsQueryHookResult = ReturnType<
  typeof useCompanyPaymentsQuery
>;
export type CompanyPaymentsLazyQueryHookResult = ReturnType<
  typeof useCompanyPaymentsLazyQuery
>;
export type CompanyPaymentsQueryResult = Apollo.QueryResult<
  CompanyPaymentsQuery,
  CompanyPaymentsQueryVariables
>;
export const CreateGreenhouseCompanyIntegrationDocument = gql`
  mutation createGreenhouseCompanyIntegration(
    $input: CreateGreenhouseCompanyIntegrationInput!
  ) {
    createGreenhouseCompanyIntegration(input: $input) {
      ...CompanyIntegration
    }
  }
  ${CompanyIntegrationFragmentDoc}
`;
export type CreateGreenhouseCompanyIntegrationMutationFn = Apollo.MutationFunction<
  CreateGreenhouseCompanyIntegrationMutation,
  CreateGreenhouseCompanyIntegrationMutationVariables
>;

/**
 * __useCreateGreenhouseCompanyIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateGreenhouseCompanyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGreenhouseCompanyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGreenhouseCompanyIntegrationMutation, { data, loading, error }] = useCreateGreenhouseCompanyIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGreenhouseCompanyIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGreenhouseCompanyIntegrationMutation,
    CreateGreenhouseCompanyIntegrationMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateGreenhouseCompanyIntegrationMutation,
    CreateGreenhouseCompanyIntegrationMutationVariables
  >(CreateGreenhouseCompanyIntegrationDocument, baseOptions);
}
export type CreateGreenhouseCompanyIntegrationMutationHookResult = ReturnType<
  typeof useCreateGreenhouseCompanyIntegrationMutation
>;
export type CreateGreenhouseCompanyIntegrationMutationResult = Apollo.MutationResult<CreateGreenhouseCompanyIntegrationMutation>;
export type CreateGreenhouseCompanyIntegrationMutationOptions = Apollo.BaseMutationOptions<
  CreateGreenhouseCompanyIntegrationMutation,
  CreateGreenhouseCompanyIntegrationMutationVariables
>;
export const UpdateGreenhouseCompanyIntegrationDocument = gql`
  mutation updateGreenhouseCompanyIntegration(
    $input: UpdateGreenhouseCompanyIntegrationInput!
  ) {
    updateGreenhouseCompanyIntegration(input: $input) {
      ...CompanyIntegration
    }
  }
  ${CompanyIntegrationFragmentDoc}
`;
export type UpdateGreenhouseCompanyIntegrationMutationFn = Apollo.MutationFunction<
  UpdateGreenhouseCompanyIntegrationMutation,
  UpdateGreenhouseCompanyIntegrationMutationVariables
>;

/**
 * __useUpdateGreenhouseCompanyIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateGreenhouseCompanyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGreenhouseCompanyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGreenhouseCompanyIntegrationMutation, { data, loading, error }] = useUpdateGreenhouseCompanyIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateGreenhouseCompanyIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGreenhouseCompanyIntegrationMutation,
    UpdateGreenhouseCompanyIntegrationMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateGreenhouseCompanyIntegrationMutation,
    UpdateGreenhouseCompanyIntegrationMutationVariables
  >(UpdateGreenhouseCompanyIntegrationDocument, baseOptions);
}
export type UpdateGreenhouseCompanyIntegrationMutationHookResult = ReturnType<
  typeof useUpdateGreenhouseCompanyIntegrationMutation
>;
export type UpdateGreenhouseCompanyIntegrationMutationResult = Apollo.MutationResult<UpdateGreenhouseCompanyIntegrationMutation>;
export type UpdateGreenhouseCompanyIntegrationMutationOptions = Apollo.BaseMutationOptions<
  UpdateGreenhouseCompanyIntegrationMutation,
  UpdateGreenhouseCompanyIntegrationMutationVariables
>;
export const CreateLeverCompanyIntegrationDocument = gql`
  mutation createLeverCompanyIntegration(
    $input: CreateLeverCompanyIntegrationInput!
  ) {
    createLeverCompanyIntegration(input: $input) {
      ...CompanyIntegration
    }
  }
  ${CompanyIntegrationFragmentDoc}
`;
export type CreateLeverCompanyIntegrationMutationFn = Apollo.MutationFunction<
  CreateLeverCompanyIntegrationMutation,
  CreateLeverCompanyIntegrationMutationVariables
>;

/**
 * __useCreateLeverCompanyIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateLeverCompanyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLeverCompanyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLeverCompanyIntegrationMutation, { data, loading, error }] = useCreateLeverCompanyIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLeverCompanyIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateLeverCompanyIntegrationMutation,
    CreateLeverCompanyIntegrationMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateLeverCompanyIntegrationMutation,
    CreateLeverCompanyIntegrationMutationVariables
  >(CreateLeverCompanyIntegrationDocument, baseOptions);
}
export type CreateLeverCompanyIntegrationMutationHookResult = ReturnType<
  typeof useCreateLeverCompanyIntegrationMutation
>;
export type CreateLeverCompanyIntegrationMutationResult = Apollo.MutationResult<CreateLeverCompanyIntegrationMutation>;
export type CreateLeverCompanyIntegrationMutationOptions = Apollo.BaseMutationOptions<
  CreateLeverCompanyIntegrationMutation,
  CreateLeverCompanyIntegrationMutationVariables
>;
export const UpdateLeverCompanyIntegrationDocument = gql`
  mutation updateLeverCompanyIntegration(
    $input: UpdateLeverCompanyIntegrationInput!
  ) {
    updateLeverCompanyIntegration(input: $input) {
      ...CompanyIntegration
    }
  }
  ${CompanyIntegrationFragmentDoc}
`;
export type UpdateLeverCompanyIntegrationMutationFn = Apollo.MutationFunction<
  UpdateLeverCompanyIntegrationMutation,
  UpdateLeverCompanyIntegrationMutationVariables
>;

/**
 * __useUpdateLeverCompanyIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateLeverCompanyIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLeverCompanyIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLeverCompanyIntegrationMutation, { data, loading, error }] = useUpdateLeverCompanyIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLeverCompanyIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLeverCompanyIntegrationMutation,
    UpdateLeverCompanyIntegrationMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateLeverCompanyIntegrationMutation,
    UpdateLeverCompanyIntegrationMutationVariables
  >(UpdateLeverCompanyIntegrationDocument, baseOptions);
}
export type UpdateLeverCompanyIntegrationMutationHookResult = ReturnType<
  typeof useUpdateLeverCompanyIntegrationMutation
>;
export type UpdateLeverCompanyIntegrationMutationResult = Apollo.MutationResult<UpdateLeverCompanyIntegrationMutation>;
export type UpdateLeverCompanyIntegrationMutationOptions = Apollo.BaseMutationOptions<
  UpdateLeverCompanyIntegrationMutation,
  UpdateLeverCompanyIntegrationMutationVariables
>;
export const CompanyIntegrationsDocument = gql`
  query companyIntegrations {
    companyIntegrations {
      ...CompanyIntegration
    }
  }
  ${CompanyIntegrationFragmentDoc}
`;

/**
 * __useCompanyIntegrationsQuery__
 *
 * To run a query within a React component, call `useCompanyIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyIntegrationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyIntegrationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyIntegrationsQuery,
    CompanyIntegrationsQueryVariables
  >
) {
  return Apollo.useQuery<
    CompanyIntegrationsQuery,
    CompanyIntegrationsQueryVariables
  >(CompanyIntegrationsDocument, baseOptions);
}
export function useCompanyIntegrationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyIntegrationsQuery,
    CompanyIntegrationsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyIntegrationsQuery,
    CompanyIntegrationsQueryVariables
  >(CompanyIntegrationsDocument, baseOptions);
}
export type CompanyIntegrationsQueryHookResult = ReturnType<
  typeof useCompanyIntegrationsQuery
>;
export type CompanyIntegrationsLazyQueryHookResult = ReturnType<
  typeof useCompanyIntegrationsLazyQuery
>;
export type CompanyIntegrationsQueryResult = Apollo.QueryResult<
  CompanyIntegrationsQuery,
  CompanyIntegrationsQueryVariables
>;
export const LeverOAuthUrlDocument = gql`
  query leverOAuthUrl {
    leverOAuthUrl
  }
`;

/**
 * __useLeverOAuthUrlQuery__
 *
 * To run a query within a React component, call `useLeverOAuthUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useLeverOAuthUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLeverOAuthUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useLeverOAuthUrlQuery(
  baseOptions?: Apollo.QueryHookOptions<
    LeverOAuthUrlQuery,
    LeverOAuthUrlQueryVariables
  >
) {
  return Apollo.useQuery<LeverOAuthUrlQuery, LeverOAuthUrlQueryVariables>(
    LeverOAuthUrlDocument,
    baseOptions
  );
}
export function useLeverOAuthUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    LeverOAuthUrlQuery,
    LeverOAuthUrlQueryVariables
  >
) {
  return Apollo.useLazyQuery<LeverOAuthUrlQuery, LeverOAuthUrlQueryVariables>(
    LeverOAuthUrlDocument,
    baseOptions
  );
}
export type LeverOAuthUrlQueryHookResult = ReturnType<
  typeof useLeverOAuthUrlQuery
>;
export type LeverOAuthUrlLazyQueryHookResult = ReturnType<
  typeof useLeverOAuthUrlLazyQuery
>;
export type LeverOAuthUrlQueryResult = Apollo.QueryResult<
  LeverOAuthUrlQuery,
  LeverOAuthUrlQueryVariables
>;
export const CreateDepartmentDocument = gql`
  mutation createDepartment($input: CreateDepartmentInput!) {
    createDepartment(input: $input) {
      ...Department
    }
  }
  ${DepartmentFragmentDoc}
`;
export type CreateDepartmentMutationFn = Apollo.MutationFunction<
  CreateDepartmentMutation,
  CreateDepartmentMutationVariables
>;

/**
 * __useCreateDepartmentMutation__
 *
 * To run a mutation, you first call `useCreateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDepartmentMutation, { data, loading, error }] = useCreateDepartmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateDepartmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateDepartmentMutation,
    CreateDepartmentMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateDepartmentMutation,
    CreateDepartmentMutationVariables
  >(CreateDepartmentDocument, baseOptions);
}
export type CreateDepartmentMutationHookResult = ReturnType<
  typeof useCreateDepartmentMutation
>;
export type CreateDepartmentMutationResult = Apollo.MutationResult<CreateDepartmentMutation>;
export type CreateDepartmentMutationOptions = Apollo.BaseMutationOptions<
  CreateDepartmentMutation,
  CreateDepartmentMutationVariables
>;
export const UpdateDepartmentDocument = gql`
  mutation updateDepartment(
    $departmentId: ID!
    $input: UpdateDepartmentInput!
  ) {
    updateDepartment(departmentId: $departmentId, input: $input) {
      ...Department
    }
  }
  ${DepartmentFragmentDoc}
`;
export type UpdateDepartmentMutationFn = Apollo.MutationFunction<
  UpdateDepartmentMutation,
  UpdateDepartmentMutationVariables
>;

/**
 * __useUpdateDepartmentMutation__
 *
 * To run a mutation, you first call `useUpdateDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDepartmentMutation, { data, loading, error }] = useUpdateDepartmentMutation({
 *   variables: {
 *      departmentId: // value for 'departmentId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateDepartmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDepartmentMutation,
    UpdateDepartmentMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateDepartmentMutation,
    UpdateDepartmentMutationVariables
  >(UpdateDepartmentDocument, baseOptions);
}
export type UpdateDepartmentMutationHookResult = ReturnType<
  typeof useUpdateDepartmentMutation
>;
export type UpdateDepartmentMutationResult = Apollo.MutationResult<UpdateDepartmentMutation>;
export type UpdateDepartmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateDepartmentMutation,
  UpdateDepartmentMutationVariables
>;
export const CompanyDepartmentsDocument = gql`
  query companyDepartments($filter: DepartmentsFilter!) {
    companyDepartments(filter: $filter) {
      items {
        ...Department
      }
      count
    }
  }
  ${DepartmentFragmentDoc}
`;

/**
 * __useCompanyDepartmentsQuery__
 *
 * To run a query within a React component, call `useCompanyDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyDepartmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyDepartmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyDepartmentsQuery,
    CompanyDepartmentsQueryVariables
  >
) {
  return Apollo.useQuery<
    CompanyDepartmentsQuery,
    CompanyDepartmentsQueryVariables
  >(CompanyDepartmentsDocument, baseOptions);
}
export function useCompanyDepartmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyDepartmentsQuery,
    CompanyDepartmentsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyDepartmentsQuery,
    CompanyDepartmentsQueryVariables
  >(CompanyDepartmentsDocument, baseOptions);
}
export type CompanyDepartmentsQueryHookResult = ReturnType<
  typeof useCompanyDepartmentsQuery
>;
export type CompanyDepartmentsLazyQueryHookResult = ReturnType<
  typeof useCompanyDepartmentsLazyQuery
>;
export type CompanyDepartmentsQueryResult = Apollo.QueryResult<
  CompanyDepartmentsQuery,
  CompanyDepartmentsQueryVariables
>;
export const ExchangePointsForGiftDocument = gql`
  mutation exchangePointsForGift($giftPriceId: ID!, $giftId: ID!) {
    exchangePointsForGift(giftPriceId: $giftPriceId, giftId: $giftId) {
      id
      status
    }
  }
`;
export type ExchangePointsForGiftMutationFn = Apollo.MutationFunction<
  ExchangePointsForGiftMutation,
  ExchangePointsForGiftMutationVariables
>;

/**
 * __useExchangePointsForGiftMutation__
 *
 * To run a mutation, you first call `useExchangePointsForGiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExchangePointsForGiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exchangePointsForGiftMutation, { data, loading, error }] = useExchangePointsForGiftMutation({
 *   variables: {
 *      giftPriceId: // value for 'giftPriceId'
 *      giftId: // value for 'giftId'
 *   },
 * });
 */
export function useExchangePointsForGiftMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExchangePointsForGiftMutation,
    ExchangePointsForGiftMutationVariables
  >
) {
  return Apollo.useMutation<
    ExchangePointsForGiftMutation,
    ExchangePointsForGiftMutationVariables
  >(ExchangePointsForGiftDocument, baseOptions);
}
export type ExchangePointsForGiftMutationHookResult = ReturnType<
  typeof useExchangePointsForGiftMutation
>;
export type ExchangePointsForGiftMutationResult = Apollo.MutationResult<ExchangePointsForGiftMutation>;
export type ExchangePointsForGiftMutationOptions = Apollo.BaseMutationOptions<
  ExchangePointsForGiftMutation,
  ExchangePointsForGiftMutationVariables
>;
export const CompanyActiveGiftsDocument = gql`
  query companyActiveGifts($filter: GiftsFilter!) {
    companyActiveGifts(filter: $filter) {
      items {
        ...Gift
      }
      count
    }
  }
  ${GiftFragmentDoc}
`;

/**
 * __useCompanyActiveGiftsQuery__
 *
 * To run a query within a React component, call `useCompanyActiveGiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyActiveGiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyActiveGiftsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyActiveGiftsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyActiveGiftsQuery,
    CompanyActiveGiftsQueryVariables
  >
) {
  return Apollo.useQuery<
    CompanyActiveGiftsQuery,
    CompanyActiveGiftsQueryVariables
  >(CompanyActiveGiftsDocument, baseOptions);
}
export function useCompanyActiveGiftsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyActiveGiftsQuery,
    CompanyActiveGiftsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyActiveGiftsQuery,
    CompanyActiveGiftsQueryVariables
  >(CompanyActiveGiftsDocument, baseOptions);
}
export type CompanyActiveGiftsQueryHookResult = ReturnType<
  typeof useCompanyActiveGiftsQuery
>;
export type CompanyActiveGiftsLazyQueryHookResult = ReturnType<
  typeof useCompanyActiveGiftsLazyQuery
>;
export type CompanyActiveGiftsQueryResult = Apollo.QueryResult<
  CompanyActiveGiftsQuery,
  CompanyActiveGiftsQueryVariables
>;
export const AllGiftCategoriesDocument = gql`
  query allGiftCategories {
    allGiftCategories {
      ... on GiftCategory {
        id
        name
      }
    }
  }
`;

/**
 * __useAllGiftCategoriesQuery__
 *
 * To run a query within a React component, call `useAllGiftCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGiftCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGiftCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllGiftCategoriesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllGiftCategoriesQuery,
    AllGiftCategoriesQueryVariables
  >
) {
  return Apollo.useQuery<
    AllGiftCategoriesQuery,
    AllGiftCategoriesQueryVariables
  >(AllGiftCategoriesDocument, baseOptions);
}
export function useAllGiftCategoriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllGiftCategoriesQuery,
    AllGiftCategoriesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    AllGiftCategoriesQuery,
    AllGiftCategoriesQueryVariables
  >(AllGiftCategoriesDocument, baseOptions);
}
export type AllGiftCategoriesQueryHookResult = ReturnType<
  typeof useAllGiftCategoriesQuery
>;
export type AllGiftCategoriesLazyQueryHookResult = ReturnType<
  typeof useAllGiftCategoriesLazyQuery
>;
export type AllGiftCategoriesQueryResult = Apollo.QueryResult<
  AllGiftCategoriesQuery,
  AllGiftCategoriesQueryVariables
>;
export const AllGiftCategoriesWithCountDocument = gql`
  query allGiftCategoriesWithCount {
    allGiftCategoriesWithCount {
      ... on GiftCategoryWithCount {
        id
        name
        giftsCount
      }
    }
  }
`;

/**
 * __useAllGiftCategoriesWithCountQuery__
 *
 * To run a query within a React component, call `useAllGiftCategoriesWithCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllGiftCategoriesWithCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllGiftCategoriesWithCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllGiftCategoriesWithCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllGiftCategoriesWithCountQuery,
    AllGiftCategoriesWithCountQueryVariables
  >
) {
  return Apollo.useQuery<
    AllGiftCategoriesWithCountQuery,
    AllGiftCategoriesWithCountQueryVariables
  >(AllGiftCategoriesWithCountDocument, baseOptions);
}
export function useAllGiftCategoriesWithCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllGiftCategoriesWithCountQuery,
    AllGiftCategoriesWithCountQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    AllGiftCategoriesWithCountQuery,
    AllGiftCategoriesWithCountQueryVariables
  >(AllGiftCategoriesWithCountDocument, baseOptions);
}
export type AllGiftCategoriesWithCountQueryHookResult = ReturnType<
  typeof useAllGiftCategoriesWithCountQuery
>;
export type AllGiftCategoriesWithCountLazyQueryHookResult = ReturnType<
  typeof useAllGiftCategoriesWithCountLazyQuery
>;
export type AllGiftCategoriesWithCountQueryResult = Apollo.QueryResult<
  AllGiftCategoriesWithCountQuery,
  AllGiftCategoriesWithCountQueryVariables
>;
export const GlobalSettingsDocument = gql`
  query globalSettings {
    globalSettings {
      id
      defaultReferralValue
      defaultHireValue
      minimumReferralValue
      minimumHireValue
    }
  }
`;

/**
 * __useGlobalSettingsQuery__
 *
 * To run a query within a React component, call `useGlobalSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlobalSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GlobalSettingsQuery,
    GlobalSettingsQueryVariables
  >
) {
  return Apollo.useQuery<GlobalSettingsQuery, GlobalSettingsQueryVariables>(
    GlobalSettingsDocument,
    baseOptions
  );
}
export function useGlobalSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GlobalSettingsQuery,
    GlobalSettingsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GlobalSettingsQuery, GlobalSettingsQueryVariables>(
    GlobalSettingsDocument,
    baseOptions
  );
}
export type GlobalSettingsQueryHookResult = ReturnType<
  typeof useGlobalSettingsQuery
>;
export type GlobalSettingsLazyQueryHookResult = ReturnType<
  typeof useGlobalSettingsLazyQuery
>;
export type GlobalSettingsQueryResult = Apollo.QueryResult<
  GlobalSettingsQuery,
  GlobalSettingsQueryVariables
>;
export const CompanyLeaderboardOnRedemptionsCsvContentDocument = gql`
  mutation companyLeaderboardOnRedemptionsCSVContent(
    $input: UsersLeaderboardCSVInput!
  ) {
    companyLeaderboardOnRedemptionsCSVContent(input: $input)
  }
`;
export type CompanyLeaderboardOnRedemptionsCsvContentMutationFn = Apollo.MutationFunction<
  CompanyLeaderboardOnRedemptionsCsvContentMutation,
  CompanyLeaderboardOnRedemptionsCsvContentMutationVariables
>;

/**
 * __useCompanyLeaderboardOnRedemptionsCsvContentMutation__
 *
 * To run a mutation, you first call `useCompanyLeaderboardOnRedemptionsCsvContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyLeaderboardOnRedemptionsCsvContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyLeaderboardOnRedemptionsCsvContentMutation, { data, loading, error }] = useCompanyLeaderboardOnRedemptionsCsvContentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompanyLeaderboardOnRedemptionsCsvContentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CompanyLeaderboardOnRedemptionsCsvContentMutation,
    CompanyLeaderboardOnRedemptionsCsvContentMutationVariables
  >
) {
  return Apollo.useMutation<
    CompanyLeaderboardOnRedemptionsCsvContentMutation,
    CompanyLeaderboardOnRedemptionsCsvContentMutationVariables
  >(CompanyLeaderboardOnRedemptionsCsvContentDocument, baseOptions);
}
export type CompanyLeaderboardOnRedemptionsCsvContentMutationHookResult = ReturnType<
  typeof useCompanyLeaderboardOnRedemptionsCsvContentMutation
>;
export type CompanyLeaderboardOnRedemptionsCsvContentMutationResult = Apollo.MutationResult<CompanyLeaderboardOnRedemptionsCsvContentMutation>;
export type CompanyLeaderboardOnRedemptionsCsvContentMutationOptions = Apollo.BaseMutationOptions<
  CompanyLeaderboardOnRedemptionsCsvContentMutation,
  CompanyLeaderboardOnRedemptionsCsvContentMutationVariables
>;
export const CompanyLeaderboardOnReferralsDocument = gql`
  query companyLeaderboardOnReferrals {
    companyLeaderboardOnReferrals {
      ...UserLeaderboard
    }
  }
  ${UserLeaderboardFragmentDoc}
`;

/**
 * __useCompanyLeaderboardOnReferralsQuery__
 *
 * To run a query within a React component, call `useCompanyLeaderboardOnReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyLeaderboardOnReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyLeaderboardOnReferralsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyLeaderboardOnReferralsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyLeaderboardOnReferralsQuery,
    CompanyLeaderboardOnReferralsQueryVariables
  >
) {
  return Apollo.useQuery<
    CompanyLeaderboardOnReferralsQuery,
    CompanyLeaderboardOnReferralsQueryVariables
  >(CompanyLeaderboardOnReferralsDocument, baseOptions);
}
export function useCompanyLeaderboardOnReferralsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyLeaderboardOnReferralsQuery,
    CompanyLeaderboardOnReferralsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyLeaderboardOnReferralsQuery,
    CompanyLeaderboardOnReferralsQueryVariables
  >(CompanyLeaderboardOnReferralsDocument, baseOptions);
}
export type CompanyLeaderboardOnReferralsQueryHookResult = ReturnType<
  typeof useCompanyLeaderboardOnReferralsQuery
>;
export type CompanyLeaderboardOnReferralsLazyQueryHookResult = ReturnType<
  typeof useCompanyLeaderboardOnReferralsLazyQuery
>;
export type CompanyLeaderboardOnReferralsQueryResult = Apollo.QueryResult<
  CompanyLeaderboardOnReferralsQuery,
  CompanyLeaderboardOnReferralsQueryVariables
>;
export const CompanyLeaderboardOnRedemptionsDocument = gql`
  query companyLeaderboardOnRedemptions($filter: UsersLeaderboardFilter!) {
    companyLeaderboardOnRedemptions(filter: $filter) {
      count
      items {
        ...UserLeaderboard
      }
    }
  }
  ${UserLeaderboardFragmentDoc}
`;

/**
 * __useCompanyLeaderboardOnRedemptionsQuery__
 *
 * To run a query within a React component, call `useCompanyLeaderboardOnRedemptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyLeaderboardOnRedemptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyLeaderboardOnRedemptionsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyLeaderboardOnRedemptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyLeaderboardOnRedemptionsQuery,
    CompanyLeaderboardOnRedemptionsQueryVariables
  >
) {
  return Apollo.useQuery<
    CompanyLeaderboardOnRedemptionsQuery,
    CompanyLeaderboardOnRedemptionsQueryVariables
  >(CompanyLeaderboardOnRedemptionsDocument, baseOptions);
}
export function useCompanyLeaderboardOnRedemptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyLeaderboardOnRedemptionsQuery,
    CompanyLeaderboardOnRedemptionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyLeaderboardOnRedemptionsQuery,
    CompanyLeaderboardOnRedemptionsQueryVariables
  >(CompanyLeaderboardOnRedemptionsDocument, baseOptions);
}
export type CompanyLeaderboardOnRedemptionsQueryHookResult = ReturnType<
  typeof useCompanyLeaderboardOnRedemptionsQuery
>;
export type CompanyLeaderboardOnRedemptionsLazyQueryHookResult = ReturnType<
  typeof useCompanyLeaderboardOnRedemptionsLazyQuery
>;
export type CompanyLeaderboardOnRedemptionsQueryResult = Apollo.QueryResult<
  CompanyLeaderboardOnRedemptionsQuery,
  CompanyLeaderboardOnRedemptionsQueryVariables
>;
export const GiveRewardPointsByWorkspaceAdminDocument = gql`
  mutation giveRewardPointsByWorkspaceAdmin(
    $userId: ID!
    $input: GiveRewardPointsInput!
  ) {
    giveRewardPointsByWorkspaceAdmin(userId: $userId, input: $input) {
      ...Payment
    }
  }
  ${PaymentFragmentDoc}
`;
export type GiveRewardPointsByWorkspaceAdminMutationFn = Apollo.MutationFunction<
  GiveRewardPointsByWorkspaceAdminMutation,
  GiveRewardPointsByWorkspaceAdminMutationVariables
>;

/**
 * __useGiveRewardPointsByWorkspaceAdminMutation__
 *
 * To run a mutation, you first call `useGiveRewardPointsByWorkspaceAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGiveRewardPointsByWorkspaceAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [giveRewardPointsByWorkspaceAdminMutation, { data, loading, error }] = useGiveRewardPointsByWorkspaceAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGiveRewardPointsByWorkspaceAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GiveRewardPointsByWorkspaceAdminMutation,
    GiveRewardPointsByWorkspaceAdminMutationVariables
  >
) {
  return Apollo.useMutation<
    GiveRewardPointsByWorkspaceAdminMutation,
    GiveRewardPointsByWorkspaceAdminMutationVariables
  >(GiveRewardPointsByWorkspaceAdminDocument, baseOptions);
}
export type GiveRewardPointsByWorkspaceAdminMutationHookResult = ReturnType<
  typeof useGiveRewardPointsByWorkspaceAdminMutation
>;
export type GiveRewardPointsByWorkspaceAdminMutationResult = Apollo.MutationResult<GiveRewardPointsByWorkspaceAdminMutation>;
export type GiveRewardPointsByWorkspaceAdminMutationOptions = Apollo.BaseMutationOptions<
  GiveRewardPointsByWorkspaceAdminMutation,
  GiveRewardPointsByWorkspaceAdminMutationVariables
>;
export const UserPointsBalanceDocument = gql`
  query userPointsBalance {
    userPointsBalance
  }
`;

/**
 * __useUserPointsBalanceQuery__
 *
 * To run a query within a React component, call `useUserPointsBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserPointsBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserPointsBalanceQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserPointsBalanceQuery(
  baseOptions?: Apollo.QueryHookOptions<
    UserPointsBalanceQuery,
    UserPointsBalanceQueryVariables
  >
) {
  return Apollo.useQuery<
    UserPointsBalanceQuery,
    UserPointsBalanceQueryVariables
  >(UserPointsBalanceDocument, baseOptions);
}
export function useUserPointsBalanceLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserPointsBalanceQuery,
    UserPointsBalanceQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    UserPointsBalanceQuery,
    UserPointsBalanceQueryVariables
  >(UserPointsBalanceDocument, baseOptions);
}
export type UserPointsBalanceQueryHookResult = ReturnType<
  typeof useUserPointsBalanceQuery
>;
export type UserPointsBalanceLazyQueryHookResult = ReturnType<
  typeof useUserPointsBalanceLazyQuery
>;
export type UserPointsBalanceQueryResult = Apollo.QueryResult<
  UserPointsBalanceQuery,
  UserPointsBalanceQueryVariables
>;
export const CreateReferralDocument = gql`
  mutation createReferral($input: CreateReferralInput!) {
    createReferral(input: $input) {
      ...Referral
    }
  }
  ${ReferralFragmentDoc}
`;
export type CreateReferralMutationFn = Apollo.MutationFunction<
  CreateReferralMutation,
  CreateReferralMutationVariables
>;

/**
 * __useCreateReferralMutation__
 *
 * To run a mutation, you first call `useCreateReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReferralMutation, { data, loading, error }] = useCreateReferralMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReferralMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateReferralMutation,
    CreateReferralMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateReferralMutation,
    CreateReferralMutationVariables
  >(CreateReferralDocument, baseOptions);
}
export type CreateReferralMutationHookResult = ReturnType<
  typeof useCreateReferralMutation
>;
export type CreateReferralMutationResult = Apollo.MutationResult<CreateReferralMutation>;
export type CreateReferralMutationOptions = Apollo.BaseMutationOptions<
  CreateReferralMutation,
  CreateReferralMutationVariables
>;
export const AcceptReferralDocument = gql`
  mutation acceptReferral($referralId: ID!) {
    acceptReferral(referralId: $referralId) {
      ...Referral
    }
  }
  ${ReferralFragmentDoc}
`;
export type AcceptReferralMutationFn = Apollo.MutationFunction<
  AcceptReferralMutation,
  AcceptReferralMutationVariables
>;

/**
 * __useAcceptReferralMutation__
 *
 * To run a mutation, you first call `useAcceptReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptReferralMutation, { data, loading, error }] = useAcceptReferralMutation({
 *   variables: {
 *      referralId: // value for 'referralId'
 *   },
 * });
 */
export function useAcceptReferralMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptReferralMutation,
    AcceptReferralMutationVariables
  >
) {
  return Apollo.useMutation<
    AcceptReferralMutation,
    AcceptReferralMutationVariables
  >(AcceptReferralDocument, baseOptions);
}
export type AcceptReferralMutationHookResult = ReturnType<
  typeof useAcceptReferralMutation
>;
export type AcceptReferralMutationResult = Apollo.MutationResult<AcceptReferralMutation>;
export type AcceptReferralMutationOptions = Apollo.BaseMutationOptions<
  AcceptReferralMutation,
  AcceptReferralMutationVariables
>;
export const RejectReferralDocument = gql`
  mutation rejectReferral($referralId: ID!, $rejectReason: String) {
    rejectReferral(referralId: $referralId, rejectReason: $rejectReason) {
      ...Referral
    }
  }
  ${ReferralFragmentDoc}
`;
export type RejectReferralMutationFn = Apollo.MutationFunction<
  RejectReferralMutation,
  RejectReferralMutationVariables
>;

/**
 * __useRejectReferralMutation__
 *
 * To run a mutation, you first call `useRejectReferralMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectReferralMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectReferralMutation, { data, loading, error }] = useRejectReferralMutation({
 *   variables: {
 *      referralId: // value for 'referralId'
 *      rejectReason: // value for 'rejectReason'
 *   },
 * });
 */
export function useRejectReferralMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RejectReferralMutation,
    RejectReferralMutationVariables
  >
) {
  return Apollo.useMutation<
    RejectReferralMutation,
    RejectReferralMutationVariables
  >(RejectReferralDocument, baseOptions);
}
export type RejectReferralMutationHookResult = ReturnType<
  typeof useRejectReferralMutation
>;
export type RejectReferralMutationResult = Apollo.MutationResult<RejectReferralMutation>;
export type RejectReferralMutationOptions = Apollo.BaseMutationOptions<
  RejectReferralMutation,
  RejectReferralMutationVariables
>;
export const MarkReferralAsHiredDocument = gql`
  mutation markReferralAsHired($referralId: ID!) {
    markReferralAsHired(referralId: $referralId) {
      ...Referral
    }
  }
  ${ReferralFragmentDoc}
`;
export type MarkReferralAsHiredMutationFn = Apollo.MutationFunction<
  MarkReferralAsHiredMutation,
  MarkReferralAsHiredMutationVariables
>;

/**
 * __useMarkReferralAsHiredMutation__
 *
 * To run a mutation, you first call `useMarkReferralAsHiredMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkReferralAsHiredMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markReferralAsHiredMutation, { data, loading, error }] = useMarkReferralAsHiredMutation({
 *   variables: {
 *      referralId: // value for 'referralId'
 *   },
 * });
 */
export function useMarkReferralAsHiredMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkReferralAsHiredMutation,
    MarkReferralAsHiredMutationVariables
  >
) {
  return Apollo.useMutation<
    MarkReferralAsHiredMutation,
    MarkReferralAsHiredMutationVariables
  >(MarkReferralAsHiredDocument, baseOptions);
}
export type MarkReferralAsHiredMutationHookResult = ReturnType<
  typeof useMarkReferralAsHiredMutation
>;
export type MarkReferralAsHiredMutationResult = Apollo.MutationResult<MarkReferralAsHiredMutation>;
export type MarkReferralAsHiredMutationOptions = Apollo.BaseMutationOptions<
  MarkReferralAsHiredMutation,
  MarkReferralAsHiredMutationVariables
>;
export const MarkReferralAsHirePendingDocument = gql`
  mutation markReferralAsHirePending($referralId: ID!) {
    markReferralAsHirePending(referralId: $referralId) {
      ...Referral
    }
  }
  ${ReferralFragmentDoc}
`;
export type MarkReferralAsHirePendingMutationFn = Apollo.MutationFunction<
  MarkReferralAsHirePendingMutation,
  MarkReferralAsHirePendingMutationVariables
>;

/**
 * __useMarkReferralAsHirePendingMutation__
 *
 * To run a mutation, you first call `useMarkReferralAsHirePendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkReferralAsHirePendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markReferralAsHirePendingMutation, { data, loading, error }] = useMarkReferralAsHirePendingMutation({
 *   variables: {
 *      referralId: // value for 'referralId'
 *   },
 * });
 */
export function useMarkReferralAsHirePendingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkReferralAsHirePendingMutation,
    MarkReferralAsHirePendingMutationVariables
  >
) {
  return Apollo.useMutation<
    MarkReferralAsHirePendingMutation,
    MarkReferralAsHirePendingMutationVariables
  >(MarkReferralAsHirePendingDocument, baseOptions);
}
export type MarkReferralAsHirePendingMutationHookResult = ReturnType<
  typeof useMarkReferralAsHirePendingMutation
>;
export type MarkReferralAsHirePendingMutationResult = Apollo.MutationResult<MarkReferralAsHirePendingMutation>;
export type MarkReferralAsHirePendingMutationOptions = Apollo.BaseMutationOptions<
  MarkReferralAsHirePendingMutation,
  MarkReferralAsHirePendingMutationVariables
>;
export const MarkReferralAsHireRejectedDocument = gql`
  mutation markReferralAsHireRejected($referralId: ID!, $rejectReason: String) {
    markReferralAsHireRejected(
      referralId: $referralId
      rejectReason: $rejectReason
    ) {
      ...Referral
    }
  }
  ${ReferralFragmentDoc}
`;
export type MarkReferralAsHireRejectedMutationFn = Apollo.MutationFunction<
  MarkReferralAsHireRejectedMutation,
  MarkReferralAsHireRejectedMutationVariables
>;

/**
 * __useMarkReferralAsHireRejectedMutation__
 *
 * To run a mutation, you first call `useMarkReferralAsHireRejectedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkReferralAsHireRejectedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markReferralAsHireRejectedMutation, { data, loading, error }] = useMarkReferralAsHireRejectedMutation({
 *   variables: {
 *      referralId: // value for 'referralId'
 *      rejectReason: // value for 'rejectReason'
 *   },
 * });
 */
export function useMarkReferralAsHireRejectedMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkReferralAsHireRejectedMutation,
    MarkReferralAsHireRejectedMutationVariables
  >
) {
  return Apollo.useMutation<
    MarkReferralAsHireRejectedMutation,
    MarkReferralAsHireRejectedMutationVariables
  >(MarkReferralAsHireRejectedDocument, baseOptions);
}
export type MarkReferralAsHireRejectedMutationHookResult = ReturnType<
  typeof useMarkReferralAsHireRejectedMutation
>;
export type MarkReferralAsHireRejectedMutationResult = Apollo.MutationResult<MarkReferralAsHireRejectedMutation>;
export type MarkReferralAsHireRejectedMutationOptions = Apollo.BaseMutationOptions<
  MarkReferralAsHireRejectedMutation,
  MarkReferralAsHireRejectedMutationVariables
>;
export const CompanyReferralsDocument = gql`
  query companyReferrals($filter: CompanyReferralsFilter!) {
    companyReferrals(filter: $filter) {
      items {
        ...Referral
      }
      count
    }
  }
  ${ReferralFragmentDoc}
`;

/**
 * __useCompanyReferralsQuery__
 *
 * To run a query within a React component, call `useCompanyReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyReferralsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyReferralsQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyReferralsQuery,
    CompanyReferralsQueryVariables
  >
) {
  return Apollo.useQuery<CompanyReferralsQuery, CompanyReferralsQueryVariables>(
    CompanyReferralsDocument,
    baseOptions
  );
}
export function useCompanyReferralsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyReferralsQuery,
    CompanyReferralsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyReferralsQuery,
    CompanyReferralsQueryVariables
  >(CompanyReferralsDocument, baseOptions);
}
export type CompanyReferralsQueryHookResult = ReturnType<
  typeof useCompanyReferralsQuery
>;
export type CompanyReferralsLazyQueryHookResult = ReturnType<
  typeof useCompanyReferralsLazyQuery
>;
export type CompanyReferralsQueryResult = Apollo.QueryResult<
  CompanyReferralsQuery,
  CompanyReferralsQueryVariables
>;
export const UserReferralsDocument = gql`
  query userReferrals($filter: UserReferralsFilter!) {
    userReferrals(filter: $filter) {
      items {
        ...Referral
      }
      count
    }
  }
  ${ReferralFragmentDoc}
`;

/**
 * __useUserReferralsQuery__
 *
 * To run a query within a React component, call `useUserReferralsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserReferralsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserReferralsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useUserReferralsQuery(
  baseOptions: Apollo.QueryHookOptions<
    UserReferralsQuery,
    UserReferralsQueryVariables
  >
) {
  return Apollo.useQuery<UserReferralsQuery, UserReferralsQueryVariables>(
    UserReferralsDocument,
    baseOptions
  );
}
export function useUserReferralsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserReferralsQuery,
    UserReferralsQueryVariables
  >
) {
  return Apollo.useLazyQuery<UserReferralsQuery, UserReferralsQueryVariables>(
    UserReferralsDocument,
    baseOptions
  );
}
export type UserReferralsQueryHookResult = ReturnType<
  typeof useUserReferralsQuery
>;
export type UserReferralsLazyQueryHookResult = ReturnType<
  typeof useUserReferralsLazyQuery
>;
export type UserReferralsQueryResult = Apollo.QueryResult<
  UserReferralsQuery,
  UserReferralsQueryVariables
>;
export const ReferralUsersActivitiesDocument = gql`
  query referralUsersActivities($referralId: ID!) {
    referralUsersActivities(referralId: $referralId) {
      id
      createdAt
      type
      actor {
        id
        name
      }
    }
  }
`;

/**
 * __useReferralUsersActivitiesQuery__
 *
 * To run a query within a React component, call `useReferralUsersActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReferralUsersActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReferralUsersActivitiesQuery({
 *   variables: {
 *      referralId: // value for 'referralId'
 *   },
 * });
 */
export function useReferralUsersActivitiesQuery(
  baseOptions: Apollo.QueryHookOptions<
    ReferralUsersActivitiesQuery,
    ReferralUsersActivitiesQueryVariables
  >
) {
  return Apollo.useQuery<
    ReferralUsersActivitiesQuery,
    ReferralUsersActivitiesQueryVariables
  >(ReferralUsersActivitiesDocument, baseOptions);
}
export function useReferralUsersActivitiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ReferralUsersActivitiesQuery,
    ReferralUsersActivitiesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ReferralUsersActivitiesQuery,
    ReferralUsersActivitiesQueryVariables
  >(ReferralUsersActivitiesDocument, baseOptions);
}
export type ReferralUsersActivitiesQueryHookResult = ReturnType<
  typeof useReferralUsersActivitiesQuery
>;
export type ReferralUsersActivitiesLazyQueryHookResult = ReturnType<
  typeof useReferralUsersActivitiesLazyQuery
>;
export type ReferralUsersActivitiesQueryResult = Apollo.QueryResult<
  ReferralUsersActivitiesQuery,
  ReferralUsersActivitiesQueryVariables
>;
export const ForgotPasswordDocument = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;
export type ForgotPasswordMutationFn = Apollo.MutationFunction<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >
) {
  return Apollo.useMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(ForgotPasswordDocument, baseOptions);
}
export type ForgotPasswordMutationHookResult = ReturnType<
  typeof useForgotPasswordMutation
>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
>;
export const ResetPasswordByTokenDocument = gql`
  mutation resetPasswordByToken($password: String!, $resetPwdToken: String!) {
    resetPasswordByToken(password: $password, resetPwdToken: $resetPwdToken)
  }
`;
export type ResetPasswordByTokenMutationFn = Apollo.MutationFunction<
  ResetPasswordByTokenMutation,
  ResetPasswordByTokenMutationVariables
>;

/**
 * __useResetPasswordByTokenMutation__
 *
 * To run a mutation, you first call `useResetPasswordByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordByTokenMutation, { data, loading, error }] = useResetPasswordByTokenMutation({
 *   variables: {
 *      password: // value for 'password'
 *      resetPwdToken: // value for 'resetPwdToken'
 *   },
 * });
 */
export function useResetPasswordByTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordByTokenMutation,
    ResetPasswordByTokenMutationVariables
  >
) {
  return Apollo.useMutation<
    ResetPasswordByTokenMutation,
    ResetPasswordByTokenMutationVariables
  >(ResetPasswordByTokenDocument, baseOptions);
}
export type ResetPasswordByTokenMutationHookResult = ReturnType<
  typeof useResetPasswordByTokenMutation
>;
export type ResetPasswordByTokenMutationResult = Apollo.MutationResult<ResetPasswordByTokenMutation>;
export type ResetPasswordByTokenMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordByTokenMutation,
  ResetPasswordByTokenMutationVariables
>;
export const SetPasswordResetByWorkspaceAdminDocument = gql`
  mutation setPasswordResetByWorkspaceAdmin($userId: ID!) {
    setPasswordResetByWorkspaceAdmin(userId: $userId)
  }
`;
export type SetPasswordResetByWorkspaceAdminMutationFn = Apollo.MutationFunction<
  SetPasswordResetByWorkspaceAdminMutation,
  SetPasswordResetByWorkspaceAdminMutationVariables
>;

/**
 * __useSetPasswordResetByWorkspaceAdminMutation__
 *
 * To run a mutation, you first call `useSetPasswordResetByWorkspaceAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPasswordResetByWorkspaceAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPasswordResetByWorkspaceAdminMutation, { data, loading, error }] = useSetPasswordResetByWorkspaceAdminMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetPasswordResetByWorkspaceAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPasswordResetByWorkspaceAdminMutation,
    SetPasswordResetByWorkspaceAdminMutationVariables
  >
) {
  return Apollo.useMutation<
    SetPasswordResetByWorkspaceAdminMutation,
    SetPasswordResetByWorkspaceAdminMutationVariables
  >(SetPasswordResetByWorkspaceAdminDocument, baseOptions);
}
export type SetPasswordResetByWorkspaceAdminMutationHookResult = ReturnType<
  typeof useSetPasswordResetByWorkspaceAdminMutation
>;
export type SetPasswordResetByWorkspaceAdminMutationResult = Apollo.MutationResult<SetPasswordResetByWorkspaceAdminMutation>;
export type SetPasswordResetByWorkspaceAdminMutationOptions = Apollo.BaseMutationOptions<
  SetPasswordResetByWorkspaceAdminMutation,
  SetPasswordResetByWorkspaceAdminMutationVariables
>;
export const CreateRoleDocument = gql`
  mutation createRole($input: CreateRoleInput!) {
    createRole(input: $input) {
      ...Role
    }
  }
  ${RoleFragmentDoc}
`;
export type CreateRoleMutationFn = Apollo.MutationFunction<
  CreateRoleMutation,
  CreateRoleMutationVariables
>;

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateRoleMutation,
    CreateRoleMutationVariables
  >
) {
  return Apollo.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(
    CreateRoleDocument,
    baseOptions
  );
}
export type CreateRoleMutationHookResult = ReturnType<
  typeof useCreateRoleMutation
>;
export type CreateRoleMutationResult = Apollo.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = Apollo.BaseMutationOptions<
  CreateRoleMutation,
  CreateRoleMutationVariables
>;
export const UpdateRoleDocument = gql`
  mutation updateRole($roleId: ID!, $input: UpdateRoleInput!) {
    updateRole(roleId: $roleId, input: $input) {
      ...Role
    }
  }
  ${RoleFragmentDoc}
`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >
) {
  return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(
    UpdateRoleDocument,
    baseOptions
  );
}
export type UpdateRoleMutationHookResult = ReturnType<
  typeof useUpdateRoleMutation
>;
export type UpdateRoleMutationResult = Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;
export const CompanyRolesDocument = gql`
  query companyRoles($filter: CompanyRolesFilter!) {
    companyRoles(filter: $filter) {
      items {
        ...Role
      }
      count
    }
  }
  ${RoleFragmentDoc}
`;

/**
 * __useCompanyRolesQuery__
 *
 * To run a query within a React component, call `useCompanyRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRolesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyRolesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyRolesQuery,
    CompanyRolesQueryVariables
  >
) {
  return Apollo.useQuery<CompanyRolesQuery, CompanyRolesQueryVariables>(
    CompanyRolesDocument,
    baseOptions
  );
}
export function useCompanyRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyRolesQuery,
    CompanyRolesQueryVariables
  >
) {
  return Apollo.useLazyQuery<CompanyRolesQuery, CompanyRolesQueryVariables>(
    CompanyRolesDocument,
    baseOptions
  );
}
export type CompanyRolesQueryHookResult = ReturnType<
  typeof useCompanyRolesQuery
>;
export type CompanyRolesLazyQueryHookResult = ReturnType<
  typeof useCompanyRolesLazyQuery
>;
export type CompanyRolesQueryResult = Apollo.QueryResult<
  CompanyRolesQuery,
  CompanyRolesQueryVariables
>;
export const CompanyActiveRolesDocument = gql`
  query companyActiveRoles($filter: CompanyActiveRolesFilter!) {
    companyActiveRoles(filter: $filter) {
      items {
        ...Role
      }
      count
    }
  }
  ${RoleFragmentDoc}
`;

/**
 * __useCompanyActiveRolesQuery__
 *
 * To run a query within a React component, call `useCompanyActiveRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyActiveRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyActiveRolesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyActiveRolesQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyActiveRolesQuery,
    CompanyActiveRolesQueryVariables
  >
) {
  return Apollo.useQuery<
    CompanyActiveRolesQuery,
    CompanyActiveRolesQueryVariables
  >(CompanyActiveRolesDocument, baseOptions);
}
export function useCompanyActiveRolesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyActiveRolesQuery,
    CompanyActiveRolesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyActiveRolesQuery,
    CompanyActiveRolesQueryVariables
  >(CompanyActiveRolesDocument, baseOptions);
}
export type CompanyActiveRolesQueryHookResult = ReturnType<
  typeof useCompanyActiveRolesQuery
>;
export type CompanyActiveRolesLazyQueryHookResult = ReturnType<
  typeof useCompanyActiveRolesLazyQuery
>;
export type CompanyActiveRolesQueryResult = Apollo.QueryResult<
  CompanyActiveRolesQuery,
  CompanyActiveRolesQueryVariables
>;
export const CompanyActiveRolesCountDocument = gql`
  query companyActiveRolesCount {
    companyActiveRolesCount
  }
`;

/**
 * __useCompanyActiveRolesCountQuery__
 *
 * To run a query within a React component, call `useCompanyActiveRolesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyActiveRolesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyActiveRolesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompanyActiveRolesCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CompanyActiveRolesCountQuery,
    CompanyActiveRolesCountQueryVariables
  >
) {
  return Apollo.useQuery<
    CompanyActiveRolesCountQuery,
    CompanyActiveRolesCountQueryVariables
  >(CompanyActiveRolesCountDocument, baseOptions);
}
export function useCompanyActiveRolesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyActiveRolesCountQuery,
    CompanyActiveRolesCountQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyActiveRolesCountQuery,
    CompanyActiveRolesCountQueryVariables
  >(CompanyActiveRolesCountDocument, baseOptions);
}
export type CompanyActiveRolesCountQueryHookResult = ReturnType<
  typeof useCompanyActiveRolesCountQuery
>;
export type CompanyActiveRolesCountLazyQueryHookResult = ReturnType<
  typeof useCompanyActiveRolesCountLazyQuery
>;
export type CompanyActiveRolesCountQueryResult = Apollo.QueryResult<
  CompanyActiveRolesCountQuery,
  CompanyActiveRolesCountQueryVariables
>;
export const ApiVersionDocument = gql`
  query apiVersion {
    version
  }
`;

/**
 * __useApiVersionQuery__
 *
 * To run a query within a React component, call `useApiVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiVersionQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiVersionQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ApiVersionQuery,
    ApiVersionQueryVariables
  >
) {
  return Apollo.useQuery<ApiVersionQuery, ApiVersionQueryVariables>(
    ApiVersionDocument,
    baseOptions
  );
}
export function useApiVersionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ApiVersionQuery,
    ApiVersionQueryVariables
  >
) {
  return Apollo.useLazyQuery<ApiVersionQuery, ApiVersionQueryVariables>(
    ApiVersionDocument,
    baseOptions
  );
}
export type ApiVersionQueryHookResult = ReturnType<typeof useApiVersionQuery>;
export type ApiVersionLazyQueryHookResult = ReturnType<
  typeof useApiVersionLazyQuery
>;
export type ApiVersionQueryResult = Apollo.QueryResult<
  ApiVersionQuery,
  ApiVersionQueryVariables
>;
export const LoginDocument = gql`
  mutation login($email: String!, $password: String!, $captchaToken: String) {
    login(email: $email, password: $password, captchaToken: $captchaToken) {
      accessToken
      refreshToken
      is2FARequired
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      captchaToken: // value for 'captchaToken'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    baseOptions
  );
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
export const LoginWithGoogleDocument = gql`
  mutation loginWithGoogle($accessToken: String!) {
    loginWithGoogle(accessToken: $accessToken) {
      accessToken
      refreshToken
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type LoginWithGoogleMutationFn = Apollo.MutationFunction<
  LoginWithGoogleMutation,
  LoginWithGoogleMutationVariables
>;

/**
 * __useLoginWithGoogleMutation__
 *
 * To run a mutation, you first call `useLoginWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginWithGoogleMutation, { data, loading, error }] = useLoginWithGoogleMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useLoginWithGoogleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginWithGoogleMutation,
    LoginWithGoogleMutationVariables
  >
) {
  return Apollo.useMutation<
    LoginWithGoogleMutation,
    LoginWithGoogleMutationVariables
  >(LoginWithGoogleDocument, baseOptions);
}
export type LoginWithGoogleMutationHookResult = ReturnType<
  typeof useLoginWithGoogleMutation
>;
export type LoginWithGoogleMutationResult = Apollo.MutationResult<LoginWithGoogleMutation>;
export type LoginWithGoogleMutationOptions = Apollo.BaseMutationOptions<
  LoginWithGoogleMutation,
  LoginWithGoogleMutationVariables
>;
export const AcceptInviteByTokenDocument = gql`
  mutation acceptInviteByToken(
    $password: String!
    $acceptInviteToken: String!
    $captchaToken: String
  ) {
    acceptInviteByToken(
      password: $password
      acceptInviteToken: $acceptInviteToken
      captchaToken: $captchaToken
    ) {
      accessToken
      refreshToken
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type AcceptInviteByTokenMutationFn = Apollo.MutationFunction<
  AcceptInviteByTokenMutation,
  AcceptInviteByTokenMutationVariables
>;

/**
 * __useAcceptInviteByTokenMutation__
 *
 * To run a mutation, you first call `useAcceptInviteByTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteByTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteByTokenMutation, { data, loading, error }] = useAcceptInviteByTokenMutation({
 *   variables: {
 *      password: // value for 'password'
 *      acceptInviteToken: // value for 'acceptInviteToken'
 *      captchaToken: // value for 'captchaToken'
 *   },
 * });
 */
export function useAcceptInviteByTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptInviteByTokenMutation,
    AcceptInviteByTokenMutationVariables
  >
) {
  return Apollo.useMutation<
    AcceptInviteByTokenMutation,
    AcceptInviteByTokenMutationVariables
  >(AcceptInviteByTokenDocument, baseOptions);
}
export type AcceptInviteByTokenMutationHookResult = ReturnType<
  typeof useAcceptInviteByTokenMutation
>;
export type AcceptInviteByTokenMutationResult = Apollo.MutationResult<AcceptInviteByTokenMutation>;
export type AcceptInviteByTokenMutationOptions = Apollo.BaseMutationOptions<
  AcceptInviteByTokenMutation,
  AcceptInviteByTokenMutationVariables
>;
export const ResendEmailByJwtTokenDocument = gql`
  mutation resendEmailByJWTToken($token: String!) {
    resendEmailByJWTToken(token: $token)
  }
`;
export type ResendEmailByJwtTokenMutationFn = Apollo.MutationFunction<
  ResendEmailByJwtTokenMutation,
  ResendEmailByJwtTokenMutationVariables
>;

/**
 * __useResendEmailByJwtTokenMutation__
 *
 * To run a mutation, you first call `useResendEmailByJwtTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailByJwtTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailByJwtTokenMutation, { data, loading, error }] = useResendEmailByJwtTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResendEmailByJwtTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendEmailByJwtTokenMutation,
    ResendEmailByJwtTokenMutationVariables
  >
) {
  return Apollo.useMutation<
    ResendEmailByJwtTokenMutation,
    ResendEmailByJwtTokenMutationVariables
  >(ResendEmailByJwtTokenDocument, baseOptions);
}
export type ResendEmailByJwtTokenMutationHookResult = ReturnType<
  typeof useResendEmailByJwtTokenMutation
>;
export type ResendEmailByJwtTokenMutationResult = Apollo.MutationResult<ResendEmailByJwtTokenMutation>;
export type ResendEmailByJwtTokenMutationOptions = Apollo.BaseMutationOptions<
  ResendEmailByJwtTokenMutation,
  ResendEmailByJwtTokenMutationVariables
>;
export const SignUpWorkspaceAdminWithPasswordDocument = gql`
  mutation signUpWorkspaceAdminWithPassword($input: SignUpWithPasswordInput!) {
    signUpWorkspaceAdminWithPassword(input: $input) {
      accessToken
      refreshToken
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type SignUpWorkspaceAdminWithPasswordMutationFn = Apollo.MutationFunction<
  SignUpWorkspaceAdminWithPasswordMutation,
  SignUpWorkspaceAdminWithPasswordMutationVariables
>;

/**
 * __useSignUpWorkspaceAdminWithPasswordMutation__
 *
 * To run a mutation, you first call `useSignUpWorkspaceAdminWithPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpWorkspaceAdminWithPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpWorkspaceAdminWithPasswordMutation, { data, loading, error }] = useSignUpWorkspaceAdminWithPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpWorkspaceAdminWithPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpWorkspaceAdminWithPasswordMutation,
    SignUpWorkspaceAdminWithPasswordMutationVariables
  >
) {
  return Apollo.useMutation<
    SignUpWorkspaceAdminWithPasswordMutation,
    SignUpWorkspaceAdminWithPasswordMutationVariables
  >(SignUpWorkspaceAdminWithPasswordDocument, baseOptions);
}
export type SignUpWorkspaceAdminWithPasswordMutationHookResult = ReturnType<
  typeof useSignUpWorkspaceAdminWithPasswordMutation
>;
export type SignUpWorkspaceAdminWithPasswordMutationResult = Apollo.MutationResult<SignUpWorkspaceAdminWithPasswordMutation>;
export type SignUpWorkspaceAdminWithPasswordMutationOptions = Apollo.BaseMutationOptions<
  SignUpWorkspaceAdminWithPasswordMutation,
  SignUpWorkspaceAdminWithPasswordMutationVariables
>;
export const ResendEmailConfirmationCodeDocument = gql`
  mutation resendEmailConfirmationCode {
    resendEmailConfirmationCode
  }
`;
export type ResendEmailConfirmationCodeMutationFn = Apollo.MutationFunction<
  ResendEmailConfirmationCodeMutation,
  ResendEmailConfirmationCodeMutationVariables
>;

/**
 * __useResendEmailConfirmationCodeMutation__
 *
 * To run a mutation, you first call `useResendEmailConfirmationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailConfirmationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailConfirmationCodeMutation, { data, loading, error }] = useResendEmailConfirmationCodeMutation({
 *   variables: {
 *   },
 * });
 */
export function useResendEmailConfirmationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendEmailConfirmationCodeMutation,
    ResendEmailConfirmationCodeMutationVariables
  >
) {
  return Apollo.useMutation<
    ResendEmailConfirmationCodeMutation,
    ResendEmailConfirmationCodeMutationVariables
  >(ResendEmailConfirmationCodeDocument, baseOptions);
}
export type ResendEmailConfirmationCodeMutationHookResult = ReturnType<
  typeof useResendEmailConfirmationCodeMutation
>;
export type ResendEmailConfirmationCodeMutationResult = Apollo.MutationResult<ResendEmailConfirmationCodeMutation>;
export type ResendEmailConfirmationCodeMutationOptions = Apollo.BaseMutationOptions<
  ResendEmailConfirmationCodeMutation,
  ResendEmailConfirmationCodeMutationVariables
>;
export const ConfirmEmailByCodeDocument = gql`
  mutation confirmEmailByCode($code: String!) {
    confirmEmailByCode(code: $code) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type ConfirmEmailByCodeMutationFn = Apollo.MutationFunction<
  ConfirmEmailByCodeMutation,
  ConfirmEmailByCodeMutationVariables
>;

/**
 * __useConfirmEmailByCodeMutation__
 *
 * To run a mutation, you first call `useConfirmEmailByCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmEmailByCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmEmailByCodeMutation, { data, loading, error }] = useConfirmEmailByCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useConfirmEmailByCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmEmailByCodeMutation,
    ConfirmEmailByCodeMutationVariables
  >
) {
  return Apollo.useMutation<
    ConfirmEmailByCodeMutation,
    ConfirmEmailByCodeMutationVariables
  >(ConfirmEmailByCodeDocument, baseOptions);
}
export type ConfirmEmailByCodeMutationHookResult = ReturnType<
  typeof useConfirmEmailByCodeMutation
>;
export type ConfirmEmailByCodeMutationResult = Apollo.MutationResult<ConfirmEmailByCodeMutation>;
export type ConfirmEmailByCodeMutationOptions = Apollo.BaseMutationOptions<
  ConfirmEmailByCodeMutation,
  ConfirmEmailByCodeMutationVariables
>;
export const SignUpWorkspaceAdminWithGoogleDocument = gql`
  mutation signUpWorkspaceAdminWithGoogle($accessToken: String!) {
    signUpWorkspaceAdminWithGoogle(accessToken: $accessToken) {
      accessToken
      refreshToken
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type SignUpWorkspaceAdminWithGoogleMutationFn = Apollo.MutationFunction<
  SignUpWorkspaceAdminWithGoogleMutation,
  SignUpWorkspaceAdminWithGoogleMutationVariables
>;

/**
 * __useSignUpWorkspaceAdminWithGoogleMutation__
 *
 * To run a mutation, you first call `useSignUpWorkspaceAdminWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpWorkspaceAdminWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpWorkspaceAdminWithGoogleMutation, { data, loading, error }] = useSignUpWorkspaceAdminWithGoogleMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useSignUpWorkspaceAdminWithGoogleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpWorkspaceAdminWithGoogleMutation,
    SignUpWorkspaceAdminWithGoogleMutationVariables
  >
) {
  return Apollo.useMutation<
    SignUpWorkspaceAdminWithGoogleMutation,
    SignUpWorkspaceAdminWithGoogleMutationVariables
  >(SignUpWorkspaceAdminWithGoogleDocument, baseOptions);
}
export type SignUpWorkspaceAdminWithGoogleMutationHookResult = ReturnType<
  typeof useSignUpWorkspaceAdminWithGoogleMutation
>;
export type SignUpWorkspaceAdminWithGoogleMutationResult = Apollo.MutationResult<SignUpWorkspaceAdminWithGoogleMutation>;
export type SignUpWorkspaceAdminWithGoogleMutationOptions = Apollo.BaseMutationOptions<
  SignUpWorkspaceAdminWithGoogleMutation,
  SignUpWorkspaceAdminWithGoogleMutationVariables
>;
export const SignUpUserWithPasswordDocument = gql`
  mutation signUpUserWithPassword(
    $invitationCode: String!
    $input: SignUpWithPasswordInput!
  ) {
    signUpUserWithPassword(invitationCode: $invitationCode, input: $input) {
      accessToken
      refreshToken
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type SignUpUserWithPasswordMutationFn = Apollo.MutationFunction<
  SignUpUserWithPasswordMutation,
  SignUpUserWithPasswordMutationVariables
>;

/**
 * __useSignUpUserWithPasswordMutation__
 *
 * To run a mutation, you first call `useSignUpUserWithPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpUserWithPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpUserWithPasswordMutation, { data, loading, error }] = useSignUpUserWithPasswordMutation({
 *   variables: {
 *      invitationCode: // value for 'invitationCode'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpUserWithPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpUserWithPasswordMutation,
    SignUpUserWithPasswordMutationVariables
  >
) {
  return Apollo.useMutation<
    SignUpUserWithPasswordMutation,
    SignUpUserWithPasswordMutationVariables
  >(SignUpUserWithPasswordDocument, baseOptions);
}
export type SignUpUserWithPasswordMutationHookResult = ReturnType<
  typeof useSignUpUserWithPasswordMutation
>;
export type SignUpUserWithPasswordMutationResult = Apollo.MutationResult<SignUpUserWithPasswordMutation>;
export type SignUpUserWithPasswordMutationOptions = Apollo.BaseMutationOptions<
  SignUpUserWithPasswordMutation,
  SignUpUserWithPasswordMutationVariables
>;
export const SignUpUserWithGoogleDocument = gql`
  mutation signUpUserWithGoogle(
    $invitationCode: String!
    $accessToken: String!
  ) {
    signUpUserWithGoogle(
      invitationCode: $invitationCode
      accessToken: $accessToken
    ) {
      accessToken
      refreshToken
      user {
        ...User
      }
    }
  }
  ${UserFragmentDoc}
`;
export type SignUpUserWithGoogleMutationFn = Apollo.MutationFunction<
  SignUpUserWithGoogleMutation,
  SignUpUserWithGoogleMutationVariables
>;

/**
 * __useSignUpUserWithGoogleMutation__
 *
 * To run a mutation, you first call `useSignUpUserWithGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpUserWithGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpUserWithGoogleMutation, { data, loading, error }] = useSignUpUserWithGoogleMutation({
 *   variables: {
 *      invitationCode: // value for 'invitationCode'
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useSignUpUserWithGoogleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SignUpUserWithGoogleMutation,
    SignUpUserWithGoogleMutationVariables
  >
) {
  return Apollo.useMutation<
    SignUpUserWithGoogleMutation,
    SignUpUserWithGoogleMutationVariables
  >(SignUpUserWithGoogleDocument, baseOptions);
}
export type SignUpUserWithGoogleMutationHookResult = ReturnType<
  typeof useSignUpUserWithGoogleMutation
>;
export type SignUpUserWithGoogleMutationResult = Apollo.MutationResult<SignUpUserWithGoogleMutation>;
export type SignUpUserWithGoogleMutationOptions = Apollo.BaseMutationOptions<
  SignUpUserWithGoogleMutation,
  SignUpUserWithGoogleMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >
) {
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    baseOptions
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const SetPreferred2FaMethodDocument = gql`
  mutation setPreferred2FAMethod($preferred2FAMethod: User2FAMethod!) {
    setPreferred2FAMethod(preferred2FAMethod: $preferred2FAMethod) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type SetPreferred2FaMethodMutationFn = Apollo.MutationFunction<
  SetPreferred2FaMethodMutation,
  SetPreferred2FaMethodMutationVariables
>;

/**
 * __useSetPreferred2FaMethodMutation__
 *
 * To run a mutation, you first call `useSetPreferred2FaMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPreferred2FaMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPreferred2FaMethodMutation, { data, loading, error }] = useSetPreferred2FaMethodMutation({
 *   variables: {
 *      preferred2FAMethod: // value for 'preferred2FAMethod'
 *   },
 * });
 */
export function useSetPreferred2FaMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetPreferred2FaMethodMutation,
    SetPreferred2FaMethodMutationVariables
  >
) {
  return Apollo.useMutation<
    SetPreferred2FaMethodMutation,
    SetPreferred2FaMethodMutationVariables
  >(SetPreferred2FaMethodDocument, baseOptions);
}
export type SetPreferred2FaMethodMutationHookResult = ReturnType<
  typeof useSetPreferred2FaMethodMutation
>;
export type SetPreferred2FaMethodMutationResult = Apollo.MutationResult<SetPreferred2FaMethodMutation>;
export type SetPreferred2FaMethodMutationOptions = Apollo.BaseMutationOptions<
  SetPreferred2FaMethodMutation,
  SetPreferred2FaMethodMutationVariables
>;
export const Remove2FaMethodDocument = gql`
  mutation remove2FAMethod($method: User2FAMethod!) {
    remove2FAMethod(method: $method) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type Remove2FaMethodMutationFn = Apollo.MutationFunction<
  Remove2FaMethodMutation,
  Remove2FaMethodMutationVariables
>;

/**
 * __useRemove2FaMethodMutation__
 *
 * To run a mutation, you first call `useRemove2FaMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemove2FaMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [remove2FaMethodMutation, { data, loading, error }] = useRemove2FaMethodMutation({
 *   variables: {
 *      method: // value for 'method'
 *   },
 * });
 */
export function useRemove2FaMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Remove2FaMethodMutation,
    Remove2FaMethodMutationVariables
  >
) {
  return Apollo.useMutation<
    Remove2FaMethodMutation,
    Remove2FaMethodMutationVariables
  >(Remove2FaMethodDocument, baseOptions);
}
export type Remove2FaMethodMutationHookResult = ReturnType<
  typeof useRemove2FaMethodMutation
>;
export type Remove2FaMethodMutationResult = Apollo.MutationResult<Remove2FaMethodMutation>;
export type Remove2FaMethodMutationOptions = Apollo.BaseMutationOptions<
  Remove2FaMethodMutation,
  Remove2FaMethodMutationVariables
>;
export const SetUserAvatarDocument = gql`
  mutation setUserAvatar($avatarUpload: Upload) {
    setUserAvatar(avatarUpload: $avatarUpload) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type SetUserAvatarMutationFn = Apollo.MutationFunction<
  SetUserAvatarMutation,
  SetUserAvatarMutationVariables
>;

/**
 * __useSetUserAvatarMutation__
 *
 * To run a mutation, you first call `useSetUserAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserAvatarMutation, { data, loading, error }] = useSetUserAvatarMutation({
 *   variables: {
 *      avatarUpload: // value for 'avatarUpload'
 *   },
 * });
 */
export function useSetUserAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetUserAvatarMutation,
    SetUserAvatarMutationVariables
  >
) {
  return Apollo.useMutation<
    SetUserAvatarMutation,
    SetUserAvatarMutationVariables
  >(SetUserAvatarDocument, baseOptions);
}
export type SetUserAvatarMutationHookResult = ReturnType<
  typeof useSetUserAvatarMutation
>;
export type SetUserAvatarMutationResult = Apollo.MutationResult<SetUserAvatarMutation>;
export type SetUserAvatarMutationOptions = Apollo.BaseMutationOptions<
  SetUserAvatarMutation,
  SetUserAvatarMutationVariables
>;
export const UpdateUserPasswordDocument = gql`
  mutation updateUserPassword($input: SetUserPasswordInput!) {
    updateUserPassword(input: $input) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type UpdateUserPasswordMutationFn = Apollo.MutationFunction<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
>;

/**
 * __useUpdateUserPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPasswordMutation, { data, loading, error }] = useUpdateUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserPasswordMutation,
    UpdateUserPasswordMutationVariables
  >(UpdateUserPasswordDocument, baseOptions);
}
export type UpdateUserPasswordMutationHookResult = ReturnType<
  typeof useUpdateUserPasswordMutation
>;
export type UpdateUserPasswordMutationResult = Apollo.MutationResult<UpdateUserPasswordMutation>;
export type UpdateUserPasswordMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPasswordMutation,
  UpdateUserPasswordMutationVariables
>;
export const CurrentUserDocument = gql`
  query currentUser {
    currentUser {
      ...User
    }
  }
  ${UserFragmentDoc}
`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    baseOptions
  );
}
export function useCurrentUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrentUserQuery,
    CurrentUserQueryVariables
  >
) {
  return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(
    CurrentUserDocument,
    baseOptions
  );
}
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<
  typeof useCurrentUserLazyQuery
>;
export type CurrentUserQueryResult = Apollo.QueryResult<
  CurrentUserQuery,
  CurrentUserQueryVariables
>;
export const CreateUserByWorkspaceAdminDocument = gql`
  mutation createUserByWorkspaceAdmin(
    $input: CreateUserByWorkspaceAdminInput!
  ) {
    createUserByWorkspaceAdmin(input: $input) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type CreateUserByWorkspaceAdminMutationFn = Apollo.MutationFunction<
  CreateUserByWorkspaceAdminMutation,
  CreateUserByWorkspaceAdminMutationVariables
>;

/**
 * __useCreateUserByWorkspaceAdminMutation__
 *
 * To run a mutation, you first call `useCreateUserByWorkspaceAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserByWorkspaceAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserByWorkspaceAdminMutation, { data, loading, error }] = useCreateUserByWorkspaceAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserByWorkspaceAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserByWorkspaceAdminMutation,
    CreateUserByWorkspaceAdminMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateUserByWorkspaceAdminMutation,
    CreateUserByWorkspaceAdminMutationVariables
  >(CreateUserByWorkspaceAdminDocument, baseOptions);
}
export type CreateUserByWorkspaceAdminMutationHookResult = ReturnType<
  typeof useCreateUserByWorkspaceAdminMutation
>;
export type CreateUserByWorkspaceAdminMutationResult = Apollo.MutationResult<CreateUserByWorkspaceAdminMutation>;
export type CreateUserByWorkspaceAdminMutationOptions = Apollo.BaseMutationOptions<
  CreateUserByWorkspaceAdminMutation,
  CreateUserByWorkspaceAdminMutationVariables
>;
export const UpdateUserByWorkspaceAdminDocument = gql`
  mutation updateUserByWorkspaceAdmin(
    $input: UpdateUserByWorkspaceAdminInput!
    $userId: ID!
  ) {
    updateUserByWorkspaceAdmin(input: $input, userId: $userId) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type UpdateUserByWorkspaceAdminMutationFn = Apollo.MutationFunction<
  UpdateUserByWorkspaceAdminMutation,
  UpdateUserByWorkspaceAdminMutationVariables
>;

/**
 * __useUpdateUserByWorkspaceAdminMutation__
 *
 * To run a mutation, you first call `useUpdateUserByWorkspaceAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserByWorkspaceAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserByWorkspaceAdminMutation, { data, loading, error }] = useUpdateUserByWorkspaceAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateUserByWorkspaceAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserByWorkspaceAdminMutation,
    UpdateUserByWorkspaceAdminMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserByWorkspaceAdminMutation,
    UpdateUserByWorkspaceAdminMutationVariables
  >(UpdateUserByWorkspaceAdminDocument, baseOptions);
}
export type UpdateUserByWorkspaceAdminMutationHookResult = ReturnType<
  typeof useUpdateUserByWorkspaceAdminMutation
>;
export type UpdateUserByWorkspaceAdminMutationResult = Apollo.MutationResult<UpdateUserByWorkspaceAdminMutation>;
export type UpdateUserByWorkspaceAdminMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserByWorkspaceAdminMutation,
  UpdateUserByWorkspaceAdminMutationVariables
>;
export const CompanyUsersDocument = gql`
  query companyUsers($filter: UsersFilter!) {
    companyUsers(filter: $filter) {
      items {
        ...UserPublicProfile
      }
      count
    }
  }
  ${UserPublicProfileFragmentDoc}
`;

/**
 * __useCompanyUsersQuery__
 *
 * To run a query within a React component, call `useCompanyUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyUsersQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyUsersQuery,
    CompanyUsersQueryVariables
  >
) {
  return Apollo.useQuery<CompanyUsersQuery, CompanyUsersQueryVariables>(
    CompanyUsersDocument,
    baseOptions
  );
}
export function useCompanyUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyUsersQuery,
    CompanyUsersQueryVariables
  >
) {
  return Apollo.useLazyQuery<CompanyUsersQuery, CompanyUsersQueryVariables>(
    CompanyUsersDocument,
    baseOptions
  );
}
export type CompanyUsersQueryHookResult = ReturnType<
  typeof useCompanyUsersQuery
>;
export type CompanyUsersLazyQueryHookResult = ReturnType<
  typeof useCompanyUsersLazyQuery
>;
export type CompanyUsersQueryResult = Apollo.QueryResult<
  CompanyUsersQuery,
  CompanyUsersQueryVariables
>;
export const CompanyUsersTableDocument = gql`
  query companyUsersTable($filter: UsersFilter!) {
    companyUsers(filter: $filter) {
      items {
        ...UsersTableFragment
      }
      count
    }
  }
  ${UsersTableFragmentFragmentDoc}
`;

/**
 * __useCompanyUsersTableQuery__
 *
 * To run a query within a React component, call `useCompanyUsersTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyUsersTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyUsersTableQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCompanyUsersTableQuery(
  baseOptions: Apollo.QueryHookOptions<
    CompanyUsersTableQuery,
    CompanyUsersTableQueryVariables
  >
) {
  return Apollo.useQuery<
    CompanyUsersTableQuery,
    CompanyUsersTableQueryVariables
  >(CompanyUsersTableDocument, baseOptions);
}
export function useCompanyUsersTableLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CompanyUsersTableQuery,
    CompanyUsersTableQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    CompanyUsersTableQuery,
    CompanyUsersTableQueryVariables
  >(CompanyUsersTableDocument, baseOptions);
}
export type CompanyUsersTableQueryHookResult = ReturnType<
  typeof useCompanyUsersTableQuery
>;
export type CompanyUsersTableLazyQueryHookResult = ReturnType<
  typeof useCompanyUsersTableLazyQuery
>;
export type CompanyUsersTableQueryResult = Apollo.QueryResult<
  CompanyUsersTableQuery,
  CompanyUsersTableQueryVariables
>;
