enum CampaignTypeNames {
  REWARD_BOOST = 'Reward Boost',
}

enum CampaignStatusNames {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
}
enum SendPromotionMsgToNames {
  email = 'Email',
  slack = 'Slack',
}

const SentPromotionMessageToOptions = Object.entries(
  SendPromotionMsgToNames
).map(([key, value]) => ({ label: value, value: key }));

const CampaignStatusOptions = Object.entries(
  CampaignStatusNames
).map(([key, value]) => ({ label: value, value: key }));

const CampaignTypeOptions = Object.entries(
  CampaignTypeNames
).map(([key, value]) => ({ label: value, value: key }));

export {
  CampaignStatusNames,
  CampaignStatusOptions,
  CampaignTypeNames,
  CampaignTypeOptions,
  SendPromotionMsgToNames,
  SentPromotionMessageToOptions,
};
