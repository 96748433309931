import { FormInstance } from 'antd';
import { GraphQLError } from 'graphql';
import { getValidationErrors, ValidationError } from 'utils';

export type InputValidationMap = {
  name?: string;
  constrains?: Record<string, string>;
};

export type FormValidationMap = {
  [key: string]: InputValidationMap;
};

type FormError = {
  name: string;
  errors: string[];
};

const getFormValidationErrors = (
  validationErrors: ValidationError[],
  formValidationMap?: FormValidationMap
): FormError[] =>
  validationErrors.reduce((acc: FormError[], ve: ValidationError) => {
    const inputValidationMap = formValidationMap?.[ve.property];
    const constrainsMap = {
      ...ve.constraints,
      ...inputValidationMap?.constrains,
    };

    const formError = {
      name: inputValidationMap?.name || ve.property,
      errors: Object.values(constrainsMap),
    };

    // Get child errors if exists
    let childErrors: FormError[] = [];
    if (ve.children?.length) {
      childErrors = getFormValidationErrors(ve.children, formValidationMap);
    }

    return [...acc, formError, ...childErrors];
  }, []);

/**
 * Set antd form field errors based on graphql BAD_USER_INPUT errors
 * Take an optional FormValidationMap to pass custom error messages
 * & set correct form input name
 */
const setFormValidationErrors = (
  form: FormInstance,
  error: GraphQLError,
  formValidationMap?: FormValidationMap
) => {
  // Get validation errors by graphql error
  const validationErrors = getValidationErrors(error);

  const formErrors = getFormValidationErrors(
    validationErrors,
    formValidationMap
  );

  form.setFields(formErrors);
};

export { setFormValidationErrors };
