import { useState } from 'react';
import { useDebounce } from 'hooks';

type onScrollArgsT = {
  isEnd: boolean;
  loading?: boolean;
  event: { target: any };
};

type useAutocompleteArgsT = {
  defaultPageSize?: number;
  initialSearch?: string | null;
  defaultPage?: number;
};

const useAutocomplete = ({
  defaultPageSize = 20,
  initialSearch = '',
}: useAutocompleteArgsT = {}) => {
  const [search, setSearch] = useState<string | null>(initialSearch);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  // Debounce search value
  const searchValue = useDebounce(search);

  // Increase page size when scroll to bottom
  const onScroll = ({ event, loading, isEnd }: onScrollArgsT) => {
    const { target } = event;

    // If loading or all items loaded do nothing
    if (loading || isEnd) {
      return;
    }

    // If scroll to bottom increase page size to load new items
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPageSize((prev) => prev + defaultPageSize);
    }
  };

  // Reset page size and set search value
  const onSearch = (value: string) => {
    setPageSize(defaultPageSize);
    setSearch(value);
  };

  // Reset page size and search value
  const reset = () => {
    setPageSize(defaultPageSize);
    setSearch('');
  };

  return { onePage: 1, pageSize, searchValue, onScroll, onSearch, reset };
};
const useAutocompletePerPage = ({
  defaultPageSize = 20,
  initialSearch = '',
  defaultPage = 1,
}: useAutocompleteArgsT = {}) => {
  const [search, setSearch] = useState<string | null>(initialSearch);
  const [page, setPage] = useState(defaultPage);

  // Debounce search value
  const searchValue = useDebounce(search);

  // Increase page size when scroll to bottom
  const onScroll = ({ event, loading, isEnd }: onScrollArgsT) => {
    const { target } = event;

    // If loading or all items loaded do nothing
    if (loading || isEnd) {
      return;
    }

    // If scroll to bottom increase page size to load new items
    if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
      setPage((prev) => prev + 1);
    }
  };

  // Reset page size and set search value
  const onSearch = (value: string) => {
    setPage(defaultPage);
    setSearch(value);
  };

  // Reset page size and search value
  const reset = () => {
    setPage(defaultPage);
    setSearch('');
  };

  return {
    onePage: page,
    pageSize: defaultPageSize,
    searchValue,
    onScroll,
    onSearch,
    reset,
  };
};

export { useAutocomplete, useAutocompletePerPage };
