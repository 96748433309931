import React from 'react';
import { Tag, Tooltip } from 'antd';

import './atsLabel.less';

type PropsType = {
  title: string;
  children?: any;
  className?: string;
};

const AtsLabel = ({ title, className, children }: PropsType) => (
  <Tooltip overlayInnerStyle={{ textAlign: 'center' }} title={title}>
    <Tag
      className={`ats-label ${className}`}
      color="cyan"
      style={{ textTransform: 'capitalize' }}
    >
      {children}
    </Tag>
  </Tooltip>
);

export default AtsLabel;
