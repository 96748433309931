import { ReactNode } from 'react';
import classNames from 'classnames';

import './bold.less';

type PropsType = {
  className?: string;
  children: ReactNode;
};

const Bold = ({ className, children }: PropsType) => (
  <span className={classNames('bold', className)}>{children}</span>
);

export default Bold;
