import { ReactNode } from 'react';
import {
  createApolloErrorProvider,
  createApolloMockedProvider,
  createApolloLoadingProvider,
} from 'apollo-mocked-provider';
import { printSchema, buildClientSchema } from 'graphql';

import schema from 'api/graphql.schema.json';

const schemaSDL = printSchema(
  buildClientSchema({ __schema: schema.__schema as any })
);

const ApolloMockedProvider = createApolloMockedProvider(schemaSDL);
const ApolloErrorProvider = createApolloErrorProvider();
const ApolloLoadingProvider = createApolloLoadingProvider();

const AutoMockedProvider = ({
  children,
  customResolvers,
}: {
  customResolvers?: any;
  children: ReactNode;
}) => (
  <ApolloMockedProvider
    customResolvers={{
      DateTime: () => jest.fn(() => new Date()),
      ...customResolvers,
    }}
  >
    {children}
  </ApolloMockedProvider>
);

export {
  AutoMockedProvider,
  ApolloMockedProvider,
  ApolloLoadingProvider,
  ApolloErrorProvider,
};
