import { useState } from 'react';

const useFilters = (resetPagination?: () => void, defaultFilters?: any) => {
  const [filterBy, changeFilterBy] = useState<any>(defaultFilters);

  const reset = () => (resetPagination ? resetPagination() : null);

  // Change filter and reset pagination to initial
  const setFilterBy = (filteredBy: any) => {
    changeFilterBy(filteredBy);
    reset();
  };

  return [filterBy, setFilterBy];
};

export { useFilters };
