import { Avatar, Dropdown } from 'antd';
import { UserMenu } from 'components';
import { DownIcon } from 'assets/icons';
import { useCurrentUserContext } from 'hooks';
import { RoleNames, userInitials } from 'utils';

import './userInfoRight.less';

type PropsType = {
  onClick?: () => void;
};

const UserInfoRight = ({ onClick }: PropsType) => {
  const { user } = useCurrentUserContext();

  const userName = user?.name;
  const userRole = user?.role;
  const userAvatar = user?.avatarLink;

  return (
    <Dropdown overlay={<UserMenu onClick={onClick} />} trigger={['click']}>
      <div className="user-info-right">
        <Avatar className="user-info-right__avatar" src={userAvatar} size={48}>
          {userInitials(userName)}
        </Avatar>
        <div className="user-info-right__text">
          <span className="user-info-right__name">{userName}</span>
          <span className="user-info-right__role">
            {userRole && RoleNames[userRole]}
          </span>
        </div>
        <DownIcon className="user-info-right__down" />
      </div>
    </Dropdown>
  );
};

export default UserInfoRight;
