import classNames from 'classnames';
import { SearchOutlined } from '@ant-design/icons';

import './tableFilterIcon.less';

type PropTypes = {
  isActive?: boolean;
};

const TableFilterIcon = ({ isActive = false }: PropTypes) => (
  <SearchOutlined className={classNames({ 'filter-icon-active': isActive })} />
);

export default TableFilterIcon;
