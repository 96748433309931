import { Avatar } from 'antd';
import { userInitials } from 'utils';

import './user_avatar.less';

type PropTypes = {
  name: string;
  src?: string;
};

const UserAvatar = ({ name, src }: PropTypes) => (
  <Avatar className="user_avatar" src={src} size={24} gap={5}>
    {userInitials(name)}
  </Avatar>
);

export default UserAvatar;
