import { RoleFragment } from 'api';
import { Modal, ReferCandidateForm } from 'components';

type PropsType = {
  visible?: boolean;
  refetch?: () => void;
  initialRole?: RoleFragment;
  onClose: () => void;
};

const ReferCandidateModal = ({
  visible,
  initialRole,
  refetch,
  onClose,
}: PropsType) => (
  <Modal title="Refer Candidate" visible={visible} onCancel={onClose}>
    <ReferCandidateForm
      onClose={onClose}
      initialRole={initialRole}
      refetch={refetch}
    />
  </Modal>
);

export default ReferCandidateModal;
