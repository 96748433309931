import classNames from 'classnames';
import { Button, ButtonProps } from 'antd';

import './dangerButton.less';

const DangerButton = (props: ButtonProps) => {
  const { className } = props;

  return (
    <Button
      {...props}
      className={classNames('danger-btn', className)}
      danger
      block
    />
  );
};

export default DangerButton;
