import React from 'react';

import './protectedByReCaptcha.less';

const ProtectedByReCaptcha = () => (
  <p className="protected-by-recaptcha">
    This site is protected by reCAPTCHA and the Google{' '}
    <a className="google_link" href="https://policies.google.com/privacy">
      Privacy Policy
    </a>{' '}
    and{' '}
    <a className="google_link" href="https://policies.google.com/terms">
      Terms of Service
    </a>{' '}
    apply.
  </p>
);

export default ProtectedByReCaptcha;
