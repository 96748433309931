const uniqArrOfObjects = <T extends Record<any, any>>(
  array: T[],
  key = 'id'
): T[] => {
  const map = new Map<string, T>();

  array.forEach((item) => {
    if (item && item[key]) {
      map.set(item[key], item);
    }
  });

  return Array.from(map.values());
};

const uniqSelectOptions = (
  array: any[] = [],
  { value = 'id', label = 'name' } = { value: 'id', label: 'name' }
): { value: string; label: string }[] => {
  const map = new Map<string, { value: string; label: string }>();

  array.forEach((item) => {
    if (item && item[value]) {
      map.set(item[value], { value: item[value], label: item[label] });
    }
  });

  return Array.from(map.values());
};

export { uniqArrOfObjects, uniqSelectOptions };
