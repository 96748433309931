import { Moment } from 'moment';
import { useState } from 'react';
import { RangeValue } from 'rc-picker/lib/interface';

type RangeFilterProps = {
  dateFrom?: Moment | null;
  dateTo?: Moment | null;
};

const useRangeFilter = (resetPagination?: () => void) => {
  const [rangeFilter, setRangeFilter] = useState<RangeFilterProps>({});

  // Reset function
  const reset = () => (resetPagination ? resetPagination() : null);

  // Set range dates
  const setRangeDates = (dates: RangeValue<Moment> | null) => {
    // If no dates set range filter to default and reset pagination
    if (!dates) {
      setRangeFilter({});
      reset();
      return;
    }

    const [startDate, endDate] = dates;

    // If no one of dates do nothing;
    if (!startDate || !endDate) {
      return;
    }

    // Reset pagination
    reset();

    // Set range filter dates
    setRangeFilter({
      dateFrom: startDate?.startOf('day'),
      dateTo: endDate?.endOf('day'),
    });
  };

  return { rangeFilter, setRangeDates };
};

export { useRangeFilter };
