enum Routes {
  // Root routes
  SignUp = '/sign-up',
  SignIn = '/sign-in',
  SignUpByInvitationCode = '/joinus/:invitationCode',
  EmailVerification = '/email-verification',
  CreateCompany = '/create-company',
  CompanyLink = '/company-link',
  ResetPassword = '/reset-password',
  UserBlocked = '/user-blocked',
  CompanyBlocked = '/company-blocked',
  PasswordRecovery = '/password-recovery',
  SignInByInvitationCode = '/get-started',
  MaintenancePage = '/maintenance',
  TwoFactorAuthentication = '/two-factor-authentication',
  Setup2FA = '/setup-two-factor-authentication',

  // Company general routes
  Dashboard = '/company/dashboard',
  ProfileSettings = '/company/profile-settings',

  // Company User settings routs
  ProfileSettingsShippingAddress = '/company/profile-settings/shippingAddress',
  ProfileSettingsChangePassword = '/company/profile-settings/changePassword',
  ProfileSettingsTwoFactorAuth = '/company/profile-settings/twoFactorAuth',

  // Company User routes
  MyPoints = '/company/user/points',
  MyPointsCategory = '/company/user/points/:category',
  MyReferrals = '/company/user/referrals',
  MyActivity = '/company/user/my-activity',
  CompanyOpenRoles = '/company/user/open-roles',

  // Company WorkspaceAdmin/HR routes
  CompanyRoles = '/company/admin/roles',
  CompanyReferrals = '/company/admin/referrals',
  CompanyGifts = '/company/admin/gifts',
  CompanyGiftsCategory = '/company/admin/gifts/:category',
  CompanyUsers = '/company/admin/users',
  CompanyCampaigns = '/company/admin/campaigns',
  CompanyCreateCampaign = '/company/admin/campaigns/create',
  CompanyEditCampaign = '/company/admin/campaigns/edit/:campaignId',
  CompanySettings = '/company/admin/settings',
  CompanyIntegrations = '/company/admin/settings/integrations',
  CompanyDepartments = '/company/admin/settings/departments',
  CompanyBilling = '/company/admin/billing',
  CompanyTaxReport = '/company/admin/reports/tax',
  OAuthCallback = '/ouath-callback/:service',
}

export { Routes };
