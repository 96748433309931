import { Image } from 'antd';
import { useTranslation } from 'react-i18next';

import './greenhouseInstructionContent.less';

const GreenhouseInstructionContent = () => {
  const { t } = useTranslation();
  return (
    <div className="popover-content__wrapper">
      <span>
        {t('PopoverContentGH.paragraph1')}{' '}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://app4.greenhouse.io/jobboard"
        >
          {t('PopoverContentGH.linkText')}
        </a>{' '}
        {t('PopoverContentGH.paragraph2')}{' '}
        <i> {t('PopoverContentGH.italicText1')}</i> <br />
        <br />
        <Image
          className="popover-content__img-board"
          src="/assets/images/GH-job-board-instruction-img-2.png"
          alt="Greenhouse app screenshot of job board"
        />
        {t('PopoverContentGH.paragraph3-1')}
        <i> {t('PopoverContentGH.italicText2')}</i>{' '}
        {t('PopoverContentGH.paragraph3-2')}
        <i> {t('PopoverContentGH.italicText3')}</i>{' '}
        {t('PopoverContentGH.paragraph3-3')}
        <Image
          className="popover-content__img-token"
          src="/assets/images/GH-job-board-instruction-img-1.png"
          alt="Greenhouse app screenshot of job board token"
        />
        {t('PopoverContentGH.paragraph4')}
      </span>
    </div>
  );
};

export default GreenhouseInstructionContent;
