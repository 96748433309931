import { ReactNode } from 'react';
import { defaultDateFormat } from 'utils';
import { ReferralAddIcon } from 'assets/icons';

import './activityItem.less';

type PropsType = {
  children: ReactNode;
  date?: Date;
};

const ActivityItem = ({ children, date }: PropsType) => (
  <div className="activity-item">
    <ReferralAddIcon className="activity-item__icon" />
    <div className="activity-item__text">
      {children}
      {date && (
        <div className="activity-item__date">{defaultDateFormat(date)}</div>
      )}
    </div>
  </div>
);

export default ActivityItem;
