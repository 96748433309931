import { useMemo } from 'react';
import classNames from 'classnames';
import { transformNumber } from 'utils';
import { GiftFragment, UserRole } from 'api';
import { useCurrentUserContext, useUserPointsContext } from 'hooks';

import './giftItem.less';

type PropsType = {
  gift: GiftFragment;
  onClick: (gift: GiftFragment) => void;
};

const GiftItem = ({ gift, onClick }: PropsType) => {
  const { name, imageUrl } = gift;

  const { user } = useCurrentUserContext();
  const { userPoints } = useUserPointsContext();

  const userRole = user?.role;
  const giftLowestPrice = gift.prices[0].valueInPoints;

  const canRedeem = useMemo(() => {
    if (!userRole) {
      return false;
    }

    if (userRole !== UserRole.User) {
      return true;
    }

    return userPoints >= giftLowestPrice;
  }, [giftLowestPrice, userPoints, userRole]);

  const handleClick = () => {
    if (canRedeem) {
      onClick(gift);
    }
  };

  return (
    <div className="gift-item">
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={classNames('gift-item__card', {
          'gift-item__card--hover': canRedeem,
        })}
        onClick={handleClick}
      >
        {/* Gift image */}
        <img className="gift-item__img" src={imageUrl} alt={name} />

        {/* Gift overlay */}
        <div className="gift-item__overlay">
          {/* Overlay for user on hover */}
          {userRole === UserRole.User && (
            <div className="gift-item__text">
              {userPoints >= giftLowestPrice
                ? 'REDEEM NOW'
                : `YOU NEED ${transformNumber(
                    giftLowestPrice
                  )} HIREACTION POINTS TO
              UNLOCK`}
            </div>
          )}

          {/* Overlay for HR/WA on hover */}
          {userRole !== UserRole.User && (
            <div className="gift-item__text">GIFT INFORMATION</div>
          )}
        </div>
      </div>

      {/* Gift item */}
      <div className="gift-item__name">{name}</div>
    </div>
  );
};

export default GiftItem;
