import classNames from 'classnames';
import { ReactNode } from 'react';

import './content.less';

type PropsType = {
  children: ReactNode;
  className?: string;
};

const Content = ({ children, className }: PropsType) => (
  <div className={classNames('content', className)}>{children}</div>
);

export default Content;
