// Return one || two first letters of user name
const userInitials = (name?: string | null) => {
  if (!name) {
    return name;
  }

  return name
    .split(' ')
    .map((n: string, i: number) =>
      n && (i === 0 || i === 1) ? n[0].toUpperCase() : null
    )
    .join('');
};

export { userInitials };
