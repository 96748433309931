enum ReferralStatusNames {
  PENDING = 'Referral submitted (pending)',
  ACCEPTED = 'Referral accepted (rewarded)',
  REJECTED = 'Referral rejected (no reward)',
  ACCEPTED_NOT_HIRED = 'Referral accepted - not hired',
  HIRED = 'Candidate hired (rewarded)',
  HIRE_PENDING = 'Candidate hired (pending)',
  HIRE_REJECTED = 'Not eligible for hire reward',
}

export { ReferralStatusNames };
