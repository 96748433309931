import { useCallback, useState } from 'react';
import { NetworkStatus } from '@apollo/client';
import { EditButton, Modal, Preloader } from 'components';
import {
  CompanyIntegrationFragment,
  IntegrationService,
  useCompanyIntegrationsQuery,
} from 'api';
import UpsertIntegrationForm from './IntegrationForms/UpsertIntegrationForm';
import OAuthCallbackModal from './OAuthCallbackModal';

import './integrations.less';

type IntegrationListItem = {
  id: IntegrationService;
  name: string;
  logoSrc: string;
  description?: string;
  integration?: CompanyIntegrationFragment;
};

const Integrations = () => {
  const [
    editIntegration,
    setEditIntegration,
  ] = useState<IntegrationService | null>(null);

  const {
    data,
    networkStatus,
    refetch: refetchCIs,
  } = useCompanyIntegrationsQuery();

  const initialLoading = networkStatus === NetworkStatus.loading;

  const handleUpsertCompanyIntegration = useCallback(() => {
    refetchCIs();
    setEditIntegration(null);
  }, [refetchCIs]);

  const ciIntegrationMap: Record<
    IntegrationService,
    CompanyIntegrationFragment
  > = data?.companyIntegrations?.length
    ? data.companyIntegrations.reduce(
        (acc, int) => ({ ...acc, [int.service]: int }),
        {}
      )
    : ({} as any);

  const integrationsMap: Record<string, IntegrationListItem> = {
    [IntegrationService.Greenhouse]: {
      id: IntegrationService.Greenhouse,
      name: 'Greenhouse',
      logoSrc:
        'https://s3.amazonaws.com/production.public.files/assets/gh-logo.png',
      description: 'Applicant tracking system & recruiting software',
      integration: ciIntegrationMap[IntegrationService.Greenhouse],
    },
    [IntegrationService.Lever]: {
      id: IntegrationService.Lever,
      name: 'Lever',
      logoSrc:
        'https://s3.amazonaws.com/production.public.files/assets/lever-logo.png',
      description: 'Applicant tracking system & recruiting software',
      integration: ciIntegrationMap[IntegrationService.Lever],
    },
  };

  const editIntegrationObj = editIntegration
    ? integrationsMap[editIntegration]
    : null;

  return (
    <div className="integrations">
      {/* disable for production */}
      <div className="integrations-list">
        {initialLoading ? (
          <Preloader show={initialLoading} />
        ) : (
          Object.values(integrationsMap).map((integration) => (
            <div key={integration.id} className="integration-item">
              <div>
                <img
                  className="integration-item__logo"
                  src={integration.logoSrc}
                  alt={`${integration.name} logo`}
                />
              </div>
              <span className="integration-item__title">
                {integration.name}
              </span>
              <span className="integration-item__description">
                {integration.description}
              </span>
              <div className="integration-item__footer">
                <EditButton onClick={() => setEditIntegration(integration.id)}>
                  {integration.integration ? 'Edit' : 'Install'}
                </EditButton>
              </div>
            </div>
          ))
        )}
      </div>
      <Modal
        visible={!!editIntegration}
        onCancel={() => setEditIntegration(null)}
        title={`${editIntegrationObj?.integration ? 'Edit' : 'Install'} ${
          editIntegrationObj?.name
        } integration`}
        className="integration-edit-modal"
      >
        <UpsertIntegrationForm
          service={editIntegration}
          onSubmit={handleUpsertCompanyIntegration}
          onCancel={() => setEditIntegration(null)}
          integration={editIntegrationObj?.integration}
        />
      </Modal>
      <OAuthCallbackModal refetchCIs={refetchCIs} />
    </div>
  );
};

export default Integrations;
