import {
  ApolloLink,
  ApolloClient,
  InMemoryCache,
  DefaultOptions,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { createUploadLink } from 'apollo-upload-client';
import {
  TokensPair,
  ErrorMessages,
  setTokensPair,
  getAccessToken,
  getRefreshToken,
  isAccessTokenExpired,
  showErrorNotification,
  isForbiddenError,
  getForbiddenRedirectUrlByForbiddenCode,
  history,
  Routes,
} from 'utils';
import { refreshTokenPairRawRequest } from './refreshToken';
import { CurrentUserDocument } from './generated.graphql';

const GRAPHQL_URI = process.env.REACT_APP_GRAPHQL as string;

// Link to your graphQL api
const httpLink = createUploadLink({
  uri: GRAPHQL_URI,
});

const authLink = setContext((_, { headers }) => {
  // Get the access token from local storage if it exists
  const accessToken = getAccessToken();

  // Return the headers to the context so batchHttpLink can read them
  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
  };
});

const refreshTokenLink = new TokenRefreshLink<TokensPair>({
  accessTokenField: 'tokensPair',
  isTokenValidOrUndefined: () => {
    const accessToken = getAccessToken();
    return !isAccessTokenExpired(accessToken);
  },
  fetchAccessToken: (): Promise<any> => {
    const refreshToken = getRefreshToken();
    return refreshTokenPairRawRequest(GRAPHQL_URI, refreshToken);
  },
  handleFetch: (tokensPair) => {
    setTokensPair(tokensPair);
  },
  handleResponse: () => (tokensPair: TokensPair) => ({ tokensPair }),
  handleError: (error: any) => {
    if (error?.message !== 'Failed to fetch') {
      // Clean up tokens data
      setTokensPair({ accessToken: '', refreshToken: '' });
    }
  },
});

// Default options for fetch and error policies
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
};

const client = new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: {
      Campaign: ['CampaignRewardBoost'],
      BaseActivity: [
        'Activity',
        'RedeemActivity',
        'ReferralActivity',
        'RewardActivity',
      ],
    },
  }),
  link: ApolloLink.from([
    refreshTokenLink,
    onError(errorHandler),
    authLink,
    httpLink,
  ]),
  connectToDevTools: process.env.REACT_APP_ENV !== 'production',
  defaultOptions,
});

function errorHandler({ networkError, graphQLErrors }: any) {
  if (networkError) {
    if (networkError.statusCode === 502) {
      history.push(Routes.MaintenancePage);
    } else {
      showErrorNotification({
        content: ErrorMessages.DefaultErrorMessage,
      });
    }
  }

  if (graphQLErrors?.length) {
    let forbiddenRedirectUrl;
    const user = client.readQuery({ query: CurrentUserDocument });

    graphQLErrors.forEach((graphQLError: any) => {
      if (isForbiddenError(graphQLError)) {
        forbiddenRedirectUrl = getForbiddenRedirectUrlByForbiddenCode(
          graphQLError,
          user
        );
      }
    });

    if (forbiddenRedirectUrl) {
      showErrorNotification({ content: ErrorMessages.AccessDenied });

      // Redirect user to the relevant page on forbidden error
      if (window.location.pathname !== forbiddenRedirectUrl) {
        history.push(forbiddenRedirectUrl);
      }
    }
  }
}

export { client };
