import classNames from 'classnames';
import { TableProps, Table } from 'antd';
import { Pagination } from 'components';

import './table.less';

type PropsType = TableProps<any> & {
  total?: number;
  pageSize?: number;
  className?: string;
  currentPage?: number;
  changePage?: (page: number) => void;
  changePageSize?: (size: number) => void;
};

const TableWrapper = (props: PropsType) => {
  const {
    total,
    pageSize,
    className,
    currentPage,
    changePage,
    changePageSize,
  } = props;

  return (
    <div className={classNames('table', className)}>
      {/* Table */}
      <Table
        {...props}
        className="table__page"
        pagination={false}
        showSorterTooltip={false}
        scroll={{ x: true }}
      />

      <Pagination
        total={total}
        pageSize={pageSize}
        currentPage={currentPage}
        changePage={changePage}
        changePageSize={changePageSize}
      />
    </div>
  );
};

export default TableWrapper;
