import classNames from 'classnames';
import { Pagination, TableProps } from 'antd';
import { Select } from 'components/index';

import './pagination.less';

const defaultPageSizeOptions = [
  { value: 10, label: '10 / page' },
  { value: 25, label: '25 / page' },
  { value: 50, label: '50 / page' },
];

type PropsType = TableProps<any> & {
  total?: number;
  pageSize?: number;
  className?: string;
  currentPage?: number;
  changePage?: (page: number) => void;
  changePageSize?: (size: number) => void;
  pageSizeOptions?: { value: number; label: string }[];
};

const PaginationWrapper = ({
  total,
  pageSize,
  className,
  currentPage,
  changePage,
  changePageSize,
  pageSizeOptions = defaultPageSizeOptions,
}: PropsType) => (
  <div className={classNames('pagination', className)}>
    {/* Page size selector */}
    <Select
      className="pagination__page-size"
      options={pageSizeOptions}
      value={pageSize}
      onChange={changePageSize}
      size="small"
    />

    {/* Pagination pages */}
    <Pagination
      className="pagination__pages"
      current={currentPage}
      total={total}
      onChange={changePage}
      hideOnSinglePage
      pageSize={pageSize}
      showSizeChanger={false}
      responsive
    />
  </div>
);

export default PaginationWrapper;
