import { useCallback, useState } from 'react';
import { Drawer, Form, Input, Select, Switch } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  useAutocomplete,
  useBreakpoint,
  useCurrentUserContext,
  useForm,
} from 'hooks';
import {
  useCompanyUsersQuery,
  useCreateGreenhouseCompanyIntegrationMutation,
  UserCompanyRole,
} from 'api';
import {
  FormItem,
  GreenhouseInstructionContent,
  ModalActions,
  PasswordInput,
  SelectAutocomplete,
} from 'components';
import {
  ErrorMessages,
  findBadUserInputProp,
  isAtsError,
  isAtsUnauthorizedError,
  isAtsUserNotFoundError,
  isBadUserInputError,
  isUniqueViolationError,
  showErrorNotification,
  showSuccessNotification,
  SuccessMessages,
  uniqSelectOptions,
} from 'utils';

import './createGreenhouseIntegrationForm.less';

type PropTypes = {
  onCancel: () => any;
  onSubmit: (res?: any) => any;
};

type FormTypes = {
  secret: string;
  isActive: boolean;
  onBehalfOfInput: string;
  responsibleUserId?: string;
  jobBoards: string[];
};

const CreateGreenhouseIntegrationForm = ({ onCancel, onSubmit }: PropTypes) => {
  const [form] = useForm<FormTypes>();
  const { t } = useTranslation();
  const { md } = useBreakpoint();

  const { user } = useCurrentUserContext();

  // Get autocomplete info and methods
  const responsibleUserAutocomplete = useAutocomplete();
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = useCallback(() => {
    setVisible(false);
  }, []);

  const {
    data: companyWAData,
    loading: companyWALoading,
  } = useCompanyUsersQuery({
    variables: {
      filter: {
        page: responsibleUserAutocomplete.onePage,
        pageSize: responsibleUserAutocomplete.pageSize,
        search: responsibleUserAutocomplete.searchValue,
        roles: [UserCompanyRole.WorkspaceAdmin, UserCompanyRole.Hr],
      },
    },
    fetchPolicy: 'no-cache',
  });

  const waTotal = companyWAData?.companyUsers?.count;

  const refResponsibleOptions = uniqSelectOptions(
    [user, ...(companyWAData?.companyUsers?.items || [])],
    { value: 'id', label: 'name' }
  );

  const [
    createGreenhouseCompanyIntegration,
    { loading },
  ] = useCreateGreenhouseCompanyIntegrationMutation({
    onCompleted(res) {
      showSuccessNotification({
        content: SuccessMessages.CompanyIntegrationConfigured,
      });

      onSubmit(res.createGreenhouseCompanyIntegration);
    },
    onError({ graphQLErrors }) {
      graphQLErrors.forEach((error) => {
        let handledErrors = 0;
        if (isUniqueViolationError(error)) {
          return showErrorNotification({
            content: ErrorMessages.IntegrationInstalled,
          });
        }

        if (isAtsUnauthorizedError(error)) {
          return form.setFields([
            {
              name: 'secret',
              errors: [ErrorMessages.GHInvalidSecret],
            },
          ]);
        }

        if (isAtsUserNotFoundError(error)) {
          return form.setFields([
            {
              name: 'onBehalfOfInput',
              errors: [ErrorMessages.GHInvalidOnBehalfOf],
            },
          ]);
        }

        if (isBadUserInputError(error)) {
          const invalidArgJobBoard = findBadUserInputProp(error, 'jobBoards');

          if (invalidArgJobBoard) {
            form.setFields([
              {
                name: 'jobBoards',
                errors: [
                  t('EditATSIntegrationForms.jobBoardTokensNotFound', {
                    jobBoardsList: invalidArgJobBoard.value.join(', '),
                  }),
                ],
              },
            ]);
            handledErrors += 1;
          }

          if (handledErrors < error.extensions?.invalidArgs.length) {
            showErrorNotification({ content: 'Unhandled errors' });
          }
          // eslint-disable-next-line consistent-return
          return;
        }

        if (isAtsError(error)) {
          return showErrorNotification({
            content: ErrorMessages.AtsError,
          });
        }

        return showErrorNotification();
      });
    },
  });

  const handleSubmitForm = async (data: FormTypes): Promise<any> => {
    const input = {
      secret: data.secret,
      isActive: data.isActive,
      onBehalfOfInput: data.onBehalfOfInput,
      ...(data.responsibleUserId && {
        responsibleUserId: data.responsibleUserId,
      }),
      jobBoards: data.jobBoards,
    };

    return createGreenhouseCompanyIntegration({
      variables: { input },
    });
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmitForm}
      initialValues={{ isActive: true }}
    >
      <FormItem
        name="secret"
        rules={[
          {
            required: true,
            whitespace: true,
            message: ErrorMessages.GHEmptySecret,
          },
          {
            max: 500,
            message: ErrorMessages.ValueIsTooLong,
          },
        ]}
      >
        <PasswordInput placeholder="Harvest API key" type="password" />
      </FormItem>

      <FormItem
        name="onBehalfOfInput"
        validateFirst
        rules={[
          {
            required: true,
            whitespace: true,
            message: ErrorMessages.GHEmptyOnBehalfOf,
          },
          {
            max: 256,
            message: ErrorMessages.ValueIsTooLong,
          },
        ]}
      >
        <Input placeholder="System user id or email" />
      </FormItem>
      <div className="green-house-form__select-wrapper">
        <FormItem
          name="jobBoards"
          rules={[
            {
              required: true,
              message: ErrorMessages.GHEmptyJBToken,
            },
          ]}
        >
          <Select
            className="green-house-form__board-tokens"
            size="middle"
            mode="tags"
            tokenSeparators={[',']}
            placeholder="List of job board tokens"
            dropdownClassName="green-house-form__dropdown"
          />
        </FormItem>
        <InfoCircleOutlined
          onClick={showDrawer}
          className="green-house-form__info-icon"
        />
        <Drawer
          title={t('EditATSIntegrationForms.drawerTitle')}
          placement="right"
          onClose={onClose}
          visible={visible}
          width={md ? 576 : 320}
        >
          <GreenhouseInstructionContent />
        </Drawer>
      </div>

      {/* Responsible user  */}
      <FormItem name="responsibleUserId">
        <SelectAutocomplete
          placeholder={t('EditATSIntegrationForms.selectPlaceholder')}
          items={refResponsibleOptions}
          total={waTotal}
          loading={companyWALoading}
          onClear={responsibleUserAutocomplete.reset}
          onScroll={responsibleUserAutocomplete.onScroll}
          onSearch={responsibleUserAutocomplete.onSearch}
        />
      </FormItem>

      <div className="switch-form-item">
        <FormItem noStyle shouldUpdate>
          {({ getFieldValue }) => {
            const isActive = getFieldValue('isActive');
            const label = isActive ? 'Active' : 'Inactive';
            return <span className="switch-form-item__label">{label}</span>;
          }}
        </FormItem>
        <FormItem name="isActive" valuePropName="checked">
          <Switch />
        </FormItem>
      </div>

      {/* Action buttons */}
      <FormItem noStyle shouldUpdate>
        {({ getFieldsError }) => (
          <ModalActions
            onCancel={onCancel}
            saveBtnText="Install"
            saveBtnLoading={loading}
            saveBtnDisabled={
              !!getFieldsError().filter(({ errors }) => errors.length).length
            }
          />
        )}
      </FormItem>
    </Form>
  );
};

export default CreateGreenhouseIntegrationForm;
