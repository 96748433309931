import { Button } from 'antd';
import { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilters, usePagination, useSort } from 'hooks';
import {
  DepartmentFragment,
  DepartmentsStatus,
  useCompanyDepartmentsQuery,
} from 'api';
import {
  EditButton,
  Table,
  ActionHeader,
  TableTitle,
  Content,
  Select,
  AtsLabel,
} from 'components';
import AddDepartmentModal from './AddDepartmentModal';
import EditDepartmentModal from './EditDepartmentModal';

import './departmentsList.less';

enum DepartmentStatusText {
  ACTIVE = 'Active',
  CLOSED = 'Inactive',
}

const departmentStatusOptions = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: DepartmentsStatus.Active,
    label: DepartmentStatusText[DepartmentsStatus.Active],
  },
  {
    value: DepartmentsStatus.Closed,
    label: DepartmentStatusText[DepartmentsStatus.Closed],
  },
];

const DepartmentsList = () => {
  const { t } = useTranslation();
  const [addDepartmentModalVisible, setAddDepartmentModalVisible] = useState(
    false
  );
  const [
    editDepartmentInfo,
    setEditDepartmentInfo,
  ] = useState<DepartmentFragment | null>(null);

  const {
    pageSize,
    currentPage,
    changePage,
    changePageSize,
    resetPagination,
  } = usePagination();

  const { sortBy, setSort } = useSort();
  const [filterByStatus, setFilterByStatus] = useFilters(
    resetPagination,
    DepartmentsStatus.Active
  );

  const handleCancelAddDepartmentModal = useCallback(
    () => setAddDepartmentModalVisible(false),
    []
  );

  const handleCancelEditDepartmentModal = useCallback(
    () => setEditDepartmentInfo(null),
    []
  );

  const {
    data: departmentsData,
    previousData: prevDepartmentsData,
    loading: departmentsLoading,
    refetch: departmentsRefetch,
  } = useCompanyDepartmentsQuery({
    variables: {
      filter: {
        page: currentPage,
        pageSize,
        statuses: filterByStatus === 'ALL' ? [] : filterByStatus,
        sortBy,
      },
    },
  });

  const departments = departmentsData?.companyDepartments?.items || [];
  const departmentsTotal = departmentsData?.companyDepartments?.count;
  const previousDepartments =
    prevDepartmentsData?.companyDepartments?.items || [];

  // Display previous data while loading new items to prevent needless ui jumps
  const currentDepartments = departmentsLoading
    ? previousDepartments
    : departments;

  const columns = useMemo(
    () => [
      {
        key: 'edit',
        render: (department: DepartmentFragment) => (
          <EditButton onClick={() => setEditDepartmentInfo(department)} />
        ),
        width: 40,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        className: 'no-wrap',
        sorter: true,
        width: 215,
        render: (_: any, { name, ats }: any) => (
          <p className="departments__name">
            {name}
            {ats && (
              <AtsLabel title={t('Departments.atsLabelTollTip', { ats })}>
                {ats}
              </AtsLabel>
            )}
          </p>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'center' as 'center',
        width: 160,
        render: (status: DepartmentsStatus) => DepartmentStatusText[status],
      },
      // Empty column to prevent auto grows of the last column
      {
        title: '',
        dataIndex: '',
      },
    ],
    [t]
  );

  return (
    <div className="departments">
      <ActionHeader
        className="departments__header"
        title={<TableTitle title="Departments" />}
        left={
          <Select
            className="departments__filter"
            placeholder="All departments"
            size="middle"
            value={filterByStatus}
            options={departmentStatusOptions}
            onChange={setFilterByStatus}
            allowClear
          />
        }
        right={
          <Button
            className="add-department-btn"
            type="primary"
            onClick={() => setAddDepartmentModalVisible(true)}
          >
            Add
          </Button>
        }
      />
      <Content className="departments-list">
        <Table
          rowKey="id"
          columns={columns}
          dataSource={currentDepartments}
          total={departmentsTotal}
          pageSize={pageSize}
          currentPage={currentPage}
          loading={departmentsLoading && !departmentsData}
          onChange={setSort}
          changePage={changePage}
          changePageSize={changePageSize}
        />
        <AddDepartmentModal
          visible={addDepartmentModalVisible}
          onCancel={handleCancelAddDepartmentModal}
          refetch={departmentsRefetch}
        />
        <EditDepartmentModal
          visible={!!editDepartmentInfo}
          editDepartmentInfo={editDepartmentInfo}
          onCancel={handleCancelEditDepartmentModal}
          refetch={departmentsRefetch}
        />
      </Content>
    </div>
  );
};

export default DepartmentsList;
