import { Input } from 'antd';
import { ChangeEvent } from 'react';
import classNames from 'classnames';
import { SearchOutlined } from '@ant-design/icons';

import './search.less';

type PropsType = {
  className?: string;
  placeholder?: string;
  onSearch: (value: string) => void;
};

const Search = ({ className, placeholder, onSearch }: PropsType) => {
  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    onSearch(e.target.value);
  };

  return (
    <Input
      onChange={handleSearch}
      placeholder={placeholder}
      className={classNames('search', className)}
      suffix={<SearchOutlined className="search__icon" />}
    />
  );
};

export default Search;
