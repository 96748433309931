import { SubscriptionStatusNames } from 'utils';
import { CompanyFragment, SubscriptionStatus } from 'api';

const getSubscriptionStatus = (company: CompanyFragment) => {
  const { isSubActive, subStatus } = company;

  if (isSubActive && subStatus === SubscriptionStatus.Trialing) {
    return SubscriptionStatusNames.Trial;
  }

  if (isSubActive && subStatus === SubscriptionStatus.Active) {
    return SubscriptionStatusNames.Active;
  }

  return SubscriptionStatusNames.Inactive;
};

export { getSubscriptionStatus };
