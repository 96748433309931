import { Layout } from 'antd';
import { HomeHeader, UpperHeader } from 'components';

type Props = {
  children: any;
};

const HomeLayout = ({ children }: Props) => (
  <Layout className="home">
    <div className="home__header">
      <UpperHeader />
      <HomeHeader />
    </div>
    <div className="home__content">{children}</div>
  </Layout>
);

export default HomeLayout;
